<template>
    <div>
        <div v-if="replies.documents && replies.documents.length>0">
            <div style="display:flex;">
                <div v-for="(doc, i) in replies.documents" :key="i" style="margin-right: 7px;">
                    <div v-if="doc && replies.isReplyDocumentsAvailable" class="c-img">
                        <div class="c-img-wrapper">
                            <!-- show pdf files -->
                            <div class="imageLoop" v-if="checkFileExtention(doc, fileExtentionsObj.pdf)">
                                <img  src="@/assets/images/icons/pdf.png" class="uploading-image" />
                            </div>
                            <!-- show doc files -->
                            <div class="imageLoop" v-if="checkFileExtention(doc, fileExtentionsObj.doc)">
                                <img  src="@/assets/images/icons/file.png" class="uploading-image" />
                            </div>
                            <!-- show excel files -->
                            <div class="imageLoop" v-if="checkFileExtention(doc, fileExtentionsObj.xlsx)">
                                <img  src="@/assets/images/icons/excel.png" class="uploading-image" />
                            </div>
                            <!-- show images -->
                            <div class="imageLoop" v-if="checkFileExtention(doc, fileExtentionsObj.png)">
                            <img  :src="fileUrl+doc" class="uploading-image" />
                            </div>
                            <!-- for other files -->
                            <div class="imageLoop" v-if="checkFileExtention(doc, fileExtentionsObj.all)">
                                <img  src="@/assets/images/icons/file.png" class="uploading-image" onError="src='@/assets/images/icons/no-image.jpg'"/>
                            </div>
                            <div class="overlay-img-wrapper">
                                <div class="overlay-img">
                                    <v-btn style="height: 36px !important;min-width: 36px !important;padding: 0 0px !important;" @click="downloadFile(doc)"
                                            >
                                    <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="replies.documents && replies.documents.length>0 && replies.isReplyDocumentsAvailable" style="font-size: 12px;margin-bottom: 3px;"><i>{{ "file Availability" | translate("60357.LongText") }}</i></div>
            <div v-if="replies.documents && replies.documents.length>0 && !replies.isReplyDocumentsAvailable" style="font-size: 12px;margin-bottom: 3px;"><i>{{ "This file is no longer available" | translate("60356.LongText") }}</i>
            </div>
        </div>
    </div>
    
</template>
<script>
  export default {
    name: "DisplayFiles",
    props: [
      "replies"
    ],
    data() {
      return {
        fileUrl:null,
        fileExtentionsObj: {
          pdf: ["pdf"],
          xlsx: ["xlsx","xls","csv","ods","xla","xlam","xlsb","xlsm","xml"],
          doc: ["doc", "docx", "txt","html","htm","xps","mp4","mkv","mp3","ppt","pptx","ppsx","tif","mht","mhtml","rtf"],
          png: ["png", "webp", "jfif", "jpg", "jpeg", "JPEG"],
          all: ["pdf","xls","xlsx","csv","doc","docx","jpg","jpeg","png","txt","html","htm","ods","xla","xlam","xlsb","xlsm","xps","mp4","mkv","mp3","ppt","pptx","ppsx","tif","xml","mht","mhtml","rtf","jfif","all"],
        },
      }
    },
    computed: {
      
    },
    created() {
      // set base url from appconfig
       this.fileUrl=JSON.parse(localStorage.getItem("AppConfig")).UrlToGetFiles
    },
    mounted(){
    },
    methods: {
     //download file
    downloadFile(file) {
     let files=this.fileUrl+file
     window.open(files);
    },
    //check file extention
    checkFileExtention(item, file_ext) {
      if (!file_ext.includes("all")) {
        return file_ext.includes((item.split(".").pop()).toLowerCase()) ? true : false;
      } else {
        return file_ext.includes((item.split(".").pop()).toLowerCase()) ? false : true;
      }
    },
    },
  };
</script>
  
<style scoped>
 .c-img-wrapper:hover .overlay-img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000069;
}
.c-img-wrapper:hover .overlay-img {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 24px;
}

.overlay-img {
  font-size: 0px;
}
.c-img-wrapper:hover .overlay-img v-icon {
  margin: 0 10px;
}
.uploading-image {
  width:65px;
  height: 60px;
}
.c-img-wrapper {
  position: relative;
}



.imageLoop {
  width: 65px;
  /* height: 65px; */
  margin-bottom: 10px;
  position: relative;
}
.c-img {
  display: flex;
  flex-wrap: wrap;
}
.imageLoopData {
  display: inline-block;
  height: 100%;
  position: relative;
  border: 2px dashed #dee2e6 !important;
  cursor: pointer;
}
.c-img-wrapper:hover .overlay-img-wrapper {
  display: block;
}
.overlay-img-wrapper {
  display: none;
}
.overlay-img-close-wrapper {
  display: none;
}
</style>