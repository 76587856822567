<template>
  <div style="display: flex" v-if="detailsData" @drop="notOpenOrDownloadFile($event)">
    <div class="language-select-wrapper">
      <v-select
        :items="setLanguageDropDown"
        v-model="translateLanguageCode"
        :label="'Language for translation' | translate('60333.LongText')"
        item-value="translateLanguageCode"
        item-text="translateLanguageName"
        @change="
          setTranslationLanuguage(selectedRowData['_id'], translateLanguageCode)
        "
        return-object
      >
      </v-select>
    </div>
    <div class="details">
      <div class="col-lg-4 col-md-4">
        <div class="custom-between-wrapper">
          <div>
            <div class="holder holder-main-box">
          <div class="holder-inner-scroll space-left">
            <div
              v-if="
                detailsData.collaborationEntry.fileUrl != 'undefined' &&
                detailsData.collaborationEntry.fileUrl != undefined &&
                detailsData.collaborationEntry.fileUrl != null &&
                detailsData.collaborationEntry.fileUrl != ''
              "
            >
              <b>{{ "Object number" | translate("60360.ShortText") }} : </b>
              <a
                :href="detailsData.collaborationEntry.fileUrl"
                target="_blank"
                >{{
                  detailsData.collaborationEntry &&
                  detailsData.collaborationEntry.fileId
                    ? detailsData.collaborationEntry.fileId
                    : ""
                }}</a
              >
            </div>
            <div v-else>
              <b>{{ "Object number" | translate("60360.ShortText") }} : </b>
              {{
                detailsData.collaborationEntry &&
                detailsData.collaborationEntry.fileId
                  ? detailsData.collaborationEntry.fileId
                  : ""
              }}
            </div>
            <div v-if="collaborationConsigmentDetails.length > 0">
              <div v-for="(item, i) in collaborationConsigmentDetails" :key="i">
                <div v-if="i > 0">
                  <b>{{ "Object number" | translate("60360.ShortText") }} : </b>
                </div>
                <b> {{ "Object type" | translate("60359.ShortText") }} :</b>
                {{ item.fileType }}
                {{ collaborationConsigmentDetails.length == 1 ? "" : i + 1 }}
                <br />
                {{ "from" | translate("60361.ShortText") }}
                {{ "Branch" | translate("60367.ShortText") }} :
                {{ item.fromBranch }}
                <span v-if="fromBranchRefrence != '' && i == 0"
                  >{{ ", " }}
                  {{ "reference number" | translate("60370.ShortText") }}
                  {{ fromBranchRefrence }}</span
                >
                <br />
                {{ "to" | translate("60362.ShortText") }}
                {{ "Branch" | translate("60367.ShortText") }} :
                {{ item.toBranch }}
                <span v-if="toBanchRefrence != '' && i == 0">
                  {{ ", " }}
                  {{ "reference number" | translate("60370.ShortText") }}
                  {{ toBanchRefrence }}</span
                >
                <br />
                <br />
              </div>
            </div>
            <div>
              <b>{{ "Ticket created by" | translate("60363.ShortText") }} : </b>
            </div>
            <div>
              {{ "Branch" | translate("60367.ShortText") }} :
              {{
                  detailsData.createdBranch && (detailsData.createdBranch.branchId != null
                  ? detailsData.createdBranch.branchId
                  : "") + 
                  ( detailsData.createdBranch.country != null ? " " + detailsData.createdBranch.country : "" ) + 
                  ( detailsData.createdBranch.city != null ? " - " + detailsData.createdBranch.city : "" )
              }},
              {{
                detailsData.collaborationEntry &&
                detailsData.collaborationEntry.createdDepartment &&
                detailsData.collaborationEntry.createdDepartment.longText
                  ? detailsData.collaborationEntry.createdDepartment.longText
                  : ""
              }}
            </div>
            <div>
              {{ "User" | translate("60364.ShortText") }} :
              {{ detailsData.objectInfo.dbCreatedUser }}
            </div>
            <div>
              {{ "Date of creation" | translate("60365.ShortText") }} :
              {{
                detailsData.collaborationEntry &&
                detailsData.collaborationEntry.createdDtTmLocalFormat
                  ? detailsData.collaborationEntry.createdDtTmLocalFormat
                  : ""
              }}
            </div>
            <br />
            <div v-if="detailsData.collaborationEntry.assignedBranch != null">
              <b>{{ "Ticket assigned to" | translate("60366.ShortText") }} :</b>
            </div>
            <div v-if="detailsData.collaborationEntry.assignedBranch != null">
              {{ "Branch" | translate("60367.ShortText") }} :
              {{
                detailsData.collaborationEntry &&
                detailsData.collaborationEntry.assignedBranch && (
                detailsData.collaborationEntry.assignedBranch.branchId != null
                  ? detailsData.collaborationEntry.assignedBranch.branchId
                  : "") + 
                  ( detailsData.collaborationEntry.assignedBranch.country != null
                  ? " " + detailsData.collaborationEntry.assignedBranch.country
                  : "" ) +
                  ( detailsData.collaborationEntry.assignedBranch.city != null
                  ? " - " + detailsData.collaborationEntry.assignedBranch.city
                  : "")
              }},
              {{
                detailsData.collaborationEntry &&
                detailsData.collaborationEntry.assignedDepartment &&
                detailsData.collaborationEntry.assignedDepartment.longText
                  ? detailsData.collaborationEntry.assignedDepartment.longText
                  : ""
              }}
            </div>
            <div>
              {{ "Date of the last action" | translate("60368.ShortText") }} :
              {{
                detailsData.collaborationEntry &&
                detailsData.collaborationEntry.modifiedDtTmLocalFormat
                  ? detailsData.collaborationEntry.modifiedDtTmLocalFormat
                  : ""
              }}
            </div>
            <div>
              {{ "Status" | translate("60303.ShortText") }} :
              {{
                detailsData.collaborationEntry &&
                detailsData.collaborationEntry.entryStatus &&
                detailsData.collaborationEntry.entryStatus.longText
                  ? detailsData.collaborationEntry.entryStatus.longText
                  : ""
              }}
            </div>
            <br />
          </div>
            </div>
            <br />
            <div class="close-btn">
              <v-btn
            v-if="isbranchDept && !isClose"
            rounded
            class="setclose-btn mr-2 mb-2"
            text
            @click="setStatusClose()"
          >
            {{ "Set to closed" | translate("60326.LongText") }}
              </v-btn>
              <div
            class="holder"
            v-if="
              detailsData.collaborationEntry.entryStatus.sysValueId == 'CLOSED'
            "
          >
            <b style="font-size: large">{{ "The ticket is CLOSED." }}</b>
              </div>
            </div>
          </div>
        <div>
        
        <div v-if="userTyping && userTyping.length !== 0" v-bind:class="[(isbranchDept && !isClose)|| detailsData.collaborationEntry.entryStatus.sysValueId == 'CLOSED' ? 'typing-note-close-btn' :'typing-note' ]">
          <b>{{'Note:' | translate("60397.LongText") }}</b>
          <div style="max-height: 80%;overflow: scroll;"> 
            <div v-for=" user in userTyping" :key="user.userId">
              <span> {{user}} {{'is currently working on a ticket.' | translate("60398.LongText") }} </span>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
      <div
        ref="reply_div"
        class="col-lg-8 col-md-8 shadow-box"
        style="margin-top: 14px"
      >
        <!-- replies section start -->
        <div
          v-bind:class="{
            chat_pop: !showRichText,
            chat_pop_message: showRichText,
          }"
        >
          <div class="chat_pop">
            <div class="header">
              <!-- refresh button -->
              <!-- <v-icon style="position: absolute;right: 80px;" @click="getLatestData()">
                mdi-refresh
              </v-icon> -->
              <div class="toggle-switch">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-switch v-model="toogle" color="#ffa200"></v-switch>
                    </span>
                  </template>
                  <span v-if="toogle">{{
                    "Toggle Switch to enter detailed view."
                  }}</span>
                  <span v-if="!toogle">{{
                    "Toggle Switch to enter normal view."
                  }}</span>
                </v-tooltip>
                <span class="toggle-switch"></span>
              </div>
            </div>
            <!-- -->
            <div
              id="chat_pop_toggle_false"
              class="thread-wrapper c-thread-height"
              v-chat-scroll="{ always: false }"
              v-if="!toogle"
              @click="closemessage"
            >
              <div
                v-for="(item, i) in replies"
                :key="i"
                class="replies-wrapper"
              >
                <div class="msg-container">
                  <div class="msg-title">
                    <div class="msg_left_pane">
                      <div class="row">
                        <div class="col-lg-12" style="padding: 2px">
                          <div
                            class="d-flex justify-content-between align-items-center flex-wrap"
                          >
                            <div class="user_name">
                              <b> {{ item.objectInfo.dbCreatedUser }}</b>
                            </div>
                            <div class="mr-10 time_stamp">
                              <b>{{ item.createdDtTmLocalFormatted }}</b>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-1"
                          style="padding: 1px; text-align: center"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon
                                  v-if="!item.createdvalue"
                                  v-model="item.createdvalue"
                                  @click="
                                    subscribeUnsubscribeBranchDepartment(
                                      false,
                                      item
                                    )
                                  "
                                  >mdi-email-minus</v-icon
                                >
                                <v-icon
                                  v-if="item.createdvalue"
                                  v-model="item.createdvalue"
                                  @click="
                                    subscribeUnsubscribeBranchDepartment(
                                      true,
                                      item
                                    )
                                  "
                                  >mdi-email-plus</v-icon
                                >
                              </span>
                            </template>
                            <span>{{
                              item.createdsubscribeUnsubscribeValue
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div class="col-lg-4" style="padding: 1px">
                          <div
                            class="mr-10 text_data"
                            v-if="
                              item.createdBranch &&
                              item.createdBranch.branchId != 0
                            "
                          >
                            <b
                              >{{ "Created" | translate("60300.LongText") }} :
                            </b>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                  style="cursor: default"
                                  >{{
                                    item.createdBranch && (item.createdBranch.branchId != null ? item.createdBranch.branchId : "") + 
                                    ( item.createdBranch.country != null ? " " + item.createdBranch.country : "" ) + 
                                    ( item.createdBranch.city != null ? " - " + item.createdBranch.city : "" )
                                  }}</span
                                >
                              </template>
                              <span>{{
                                selectedRowData[`createdBranch.nametooltip`]
                              }}</span
                              ><br />
                              <span>{{
                                selectedRowData[`createdBranch.countrytooltip`]
                              }}</span
                              ><br />
                            </v-tooltip>
                          </div>
                        </div>
                        <div class="col-lg-1" style="padding: 1px"></div>
                        <div class="col-lg-6" style="padding: 1px">
                          <div class="mr-10 text_data">
                            <b
                              >{{
                                "Created Department"
                                  | translate("60394.LongText")
                              }}
                              :
                            </b>
                            {{ item.createdDepartment.longText }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-lg-1"
                          style="padding: 1px; text-align: center"
                          v-if="item.assignedBranch != null"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon
                                  v-if="!item.assignedvalue"
                                  v-model="item.assignedvalue"
                                  @click="
                                    subscribeUnsubscribeAssignedBranchDepartment(
                                      false,
                                      item
                                    )
                                  "
                                  >mdi-email-minus</v-icon
                                >
                                <v-icon
                                  v-if="item.assignedvalue"
                                  v-model="item.assignedvalue"
                                  @click="
                                    subscribeUnsubscribeAssignedBranchDepartment(
                                      true,
                                      item
                                    )
                                  "
                                  >mdi-email-plus</v-icon
                                >
                              </span>
                            </template>
                            <span>{{
                              item.assignedsubscribeUnsubscribeValue
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div class="col-lg-4" style="padding: 1px">
                          <div
                            class="mr-10 text_data"
                            v-if="item.assignedBranch != null"
                          >
                            <b
                              >{{
                                "Assigned to" | translate("60305.LongText")
                              }}
                              :</b
                            >
                            {{ 
                            (item.assignedBranch.branchId != null ? item.assignedBranch.branchId : "") + 
                            ( item.assignedBranch.country != null ? " " + item.assignedBranch.country : "" ) + 
                            ( item.assignedBranch.city != null ? " - " + item.assignedBranch.city : "" ) }}
                          </div>
                        </div>
                        <div class="col-lg-1" style="padding: 1px"></div>
                        <div class="col-lg-6" style="padding: 1px">
                          <div
                            class="mr-10 text_data"
                            v-if="item.assignedDepartment != null"
                          >
                            <b
                              >{{
                                "Department" | translate("60160.LongText")
                              }}
                              :</b
                            >
                            {{ item.assignedDepartment.longText }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="msg_right_pane"></div>
                  </div>
                  <div class="msg-wrapper-list">
                    <v-tooltip bottom content-class="c-tooltip-right">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" style="cursor: default">
                          <DisplayFiles :replies="item"></DisplayFiles>
                          <span
                            v-html="item.comment"
                            class="image-inner-wrapper"
                            @click="
                              redirectToHomePageWithSearchTag(item.tags, $event)
                            "
                          />
                        </span>
                      </template>
                      <span
                        v-if="
                          !!item.assignedBranch &&
                          item.entryStatus.sysValueNo == '12047'
                        "
                      >
                        {{ "REASSIGN" | translate("60319.ShortText") }}
                      </span>
                      <span v-else>
                        {{ item.entryStatus.shortText }}
                      </span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>

            <!-- -->
            <div
              id="chat_pop_toggle_true"
              v-bind:class="{
                'thread-wrapper-simple top-space': !showRichText,
                'thread-wrapper-simple_message top-space': showRichText,
              }"
              v-chat-scroll="{ always: false }"
              v-if="toogle"
              @click="closemessage"
            >
              <div
                v-for="(item, i) in replies"
                :key="i"
                class="replies-wrapper"
              >
                <div
                  class="msg-container"
                  v-if="logedUserEmailId != item.objectInfo.dbCreatedUser"
                >
                  <div class="msg-wrapper-list msg-wrapper-list-left">
                    <div class="py-1 user_name">
                      <div
                        class="text-center d-flex align-center justify-space-around"
                      >
                        <v-tooltip bottom content-class="c-tooltip-left">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <b> {{ item.objectInfo.dbCreatedUser }}</b></span
                            >
                          </template>
                          <div style="width: 400px">
                            <div>
                              {{ "Created" | translate("60300.LongText") }} :
                              {{ item.createdBranch && (item.createdBranch.branchId != null ? item.createdBranch.branchId : "") + 
                                    ( item.createdBranch.country != null ? " " + item.createdBranch.country : "" ) + 
                                    ( item.createdBranch.city != null ? " - " + item.createdBranch.city : "" ) }}
                              {{
                                "Created Department"
                                  | translate("60394.LongText")
                              }}
                              :
                              {{ item.createdDepartment.longText }}
                            </div>
                            <div>
                              <span v-if="item.assignedBranch != null">
                                {{
                                  "Assigned to" | translate("60305.LongText")
                                }}
                                : {{ (item.assignedBranch.branchId != null ? item.assignedBranch.branchId : "") + 
                            ( item.assignedBranch.country != null ? " " + item.assignedBranch.country : "" ) + 
                            ( item.assignedBranch.city != null ? " - " + item.assignedBranch.city : "" ) }}
                              </span>
                              <span v-if="item.assignedDepartment != null">
                                {{ "Department" | translate("60160.LongText") }}
                                :
                                {{ item.assignedDepartment.longText }}
                              </span>
                            </div>
                          </div>
                        </v-tooltip>
                      </div>
                      <div class="time_stamp">
                        <b>{{ item.createdDtTmLocalFormatted }}</b>
                      </div>
                    </div>
                    <DisplayFiles :replies="item"></DisplayFiles>
                    <span
                      v-html="item.comment"
                      class="image-inner-wrapper"
                      @click="
                        redirectToHomePageWithSearchTag(item.tags, $event)
                      "
                    />
                  </div>
                </div>
                <div class="msg-container" v-else>
                  <div class="msg-wrapper-list-me msg-wrapper-list-right">
                    <div class="py-1 user_name">
                      <div
                        class="text-center d-flex align-center justify-space-around"
                      >
                        <v-tooltip
                          bottom
                          style="width: 200px"
                          content-class="c-tooltip-right"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <b> {{ item.objectInfo.dbCreatedUser }}</b></span
                            >
                          </template>
                          <div style="width: 400px">
                            <div>
                              {{ "Created" | translate("60300.LongText") }} :
                              {{ item.createdBranch && (item.createdBranch.branchId != null ? item.createdBranch.branchId : "") + 
                                    ( item.createdBranch.country != null ? " " + item.createdBranch.country : "" ) + 
                                    ( item.createdBranch.city != null ? " - " + item.createdBranch.city : "" ) }}
                              {{
                                "Created Department"
                                  | translate("60394.LongText")
                              }}
                              :
                              {{ item.createdDepartment.longText }}
                            </div>
                            <div>
                              <span v-if="item.assignedBranch != null">
                                {{
                                  "Assigned to" | translate("60305.LongText")
                                }}
                                : {{ (item.assignedBranch.branchId != null ? item.assignedBranch.branchId : "") + 
                            ( item.assignedBranch.country != null ? " " + item.assignedBranch.country : "" ) + 
                            ( item.assignedBranch.city != null ? " - " + item.assignedBranch.city : "" ) }}
                              </span>
                              <span v-if="item.assignedDepartment != null">
                                {{ "Department" | translate("60160.LongText") }}
                                :
                                {{ item.assignedDepartment.longText }}
                              </span>
                            </div>
                          </div>
                        </v-tooltip>
                      </div>
                      <div class="time_stamp">
                        <b>{{ item.createdDtTmLocalFormatted }}</b>
                      </div>
                    </div>
                    <DisplayFiles :replies="item"></DisplayFiles>
                    <span
                      v-html="item.comment"
                      class="image-inner-wrapper"
                      @click="
                        redirectToHomePageWithSearchTag(item.tags, $event)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="overlay-card" style="border: dashed;border-color: black;" v-if="displayOverlay" @drop="dropFile($event)">
      <div style="color: white;display: flex; justify-content: center; flex-direction: column; text-align: center;">
      <v-icon color="white" size="50"> mdi-tray-arrow-up</v-icon>
        <h5> {{ "Upload File" | translate("60395.LongText") }}</h5>
        <h6> {{ "Drag and drop your files here" | translate("60396.LongText") }}</h6>
      </div>
      </div>
        <v-form ref="replyForm" v-model="validReply" lazy-validation>
          <div class="write_message" ref="write_message">
            <div ref="commentSection">
              <div class="reply" v-if="!isClose" @click="showRichTextfn(true)">
                <v-container>
                  <v-row>
                    <!-- ck-editor start-->
                    <v-col cols="12" sm="12" class="pb-0">
                      <div v-bind:class="[showRichText ? 'msg-wrapper mb-2' : '']">
                        <div style="display: flex">
                          <div
                            class="c-img-close-wrapper"
                            style="margin-left: 5px"
                            v-for="(item, i) in fileList"
                            :key="i"
                          >
                            <!-- show pdf files -->
                            <div>
                              <img
                                v-if="
                                  checkFileExtention(
                                    item,
                                    fileExtentionsObj.pdf
                                  )
                                "
                                src="@/assets/images/icons/pdf.png"
                                style="
                                  height: 85px;
                                  width: 85px;
                                  margin-bottom: 10px;
                                "
                                class="img-fluid c-img-wrapper"
                              />
                            </div>
                            <!-- show doc files -->
                            <div>
                              <img
                                v-if="
                                  checkFileExtention(
                                    item,
                                    fileExtentionsObj.doc
                                  )
                                "
                                src="@/assets/images/icons/file.png"
                                style="
                                  height: 85px;
                                  width: 85px;
                                  margin-bottom: 10px;
                                "
                                class="img-fluid c-img-wrapper"
                              />
                            </div>
                            <!-- show excel files -->
                            <div>
                              <img
                                v-if="
                                  checkFileExtention(
                                    item,
                                    fileExtentionsObj.xlsx
                                  )
                                "
                                src="@/assets/images/icons/excel.png"
                                style="
                                  height: 85px;
                                  width: 85px;
                                  margin-bottom: 10px;
                                "
                                class="img-fluid c-img-wrapper"
                              />
                            </div>
                            <!-- show images -->
                            <div>
                              <img
                                v-if="
                                  checkFileExtention(
                                    item,
                                    fileExtentionsObj.png
                                  )
                                "
                                :src="item.previewImage"
                                style="
                                  height: 85px;
                                  width: 85px;
                                  margin-bottom: 10px;
                                "
                                onError="src='@/assets/images/icons/nofile-image.jpg'"
                                class="img-fluid c-img-wrapper"
                              />
                            </div>
                            <!-- show other files -->
                            <div
                              v-if="
                                checkFileExtention(item, fileExtentionsObj.all)
                              "
                            >
                              <img
                                src="@/assets/images/icons/file.png"
                                style="
                                  height: 85px;
                                  width: 85px;
                                  margin-bottom: 10px;
                                "
                                onError="src='@/assets/images/icons/no-image.jpg'"
                                class="img-fluid c-img-wrapper"
                              />
                            </div>
                            <div class="overlay-img-close-wrapper">
                              <div class="overlay-img-close">
                                <v-btn
                                  style="
                                    height: 36px !important;
                                    min-width: 36px !important;
                                    padding: 0 0px !important;
                                  "
                                  @click="removeImage(item)"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                        <vue-editor
                          ref="editor"
                          :editorToolbar="customToolbar"
                          :editorOptions="d_editorOptions"
                          class="write-input-send"
                          v-if="showRichText"
                          id="replyMessage"
                          v-model="replyForm.replyMessage"
                          :counter="100"
                          :placeholder="
                            'Start working on a ticket' | translate('60321.LongText')
                          "
                          autofocus="true"
                          @keyup.esc="showRichTextfn(true)"
                          @text-change="onChangeText"
                        >
                        </vue-editor>
                        <div class="editor-option" v-if="showRichText">
                          <!-- reply radio dropdown start -->
                          <v-col
                            v-show="replyForm.replyMessage && showRichText"
                          >
                            <v-card-text class="c-card-radio">
                              <v-radio-group
                                class="new-radio"
                                v-model="replyForm.replyMsgStatus"
                                column
                                id="reply-message-radio"
                                :rules="replyFormRules.replyMsgStatusRules"
                              >
                                <v-tooltip
                                  bottom
                                  v-for="(options, index) in repliesOptions"
                                  :key="index"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-radio
                                      type="radio"
                                      class="radio"
                                      :label="options.value"
                                      :value="options.assignvalue"
                                      v-on:click="replyStatusChange"
                                      v-bind="attrs"
                                      v-on="on"
                                      @mouseover="setHover()"
                                      @mouseout="removeHover()"
                                    ></v-radio>
                                  </template>
                                  {{ options.tooltip }}
                                </v-tooltip>
                              </v-radio-group>
                            </v-card-text>
                            <div ref="reassignOption">
                              <v-list class="dropdown-list-wrapper" top>
                                <v-col
                                  cols="12"
                                  sm="12"
                                  class="pt-0 pb-0 mt-10"
                                  v-show="showAssignedOptions"
                                >
                                  <div class="replies-input-select">
                                    <v-select
                                      :items="functionValues"
                                      v-model="replyForm.assignedFunction"
                                      :label="
                                        'Assigned Function'
                                          | translate('60071.LongText')
                                      "
                                      :rules="
                                        replyFormRules.assignedFunctionRules
                                      "
                                      auto
                                      v-if="
                                        functionValues &&
                                        functionValues.length > 0
                                      "
                                      @change="
                                        setAssignBranch(
                                          replyForm.assignedFunction
                                        )
                                      "
                                      return-object
                                      @click="unbindClickOutSide"
                                      @blur="bindClickOutSide"
                                    ></v-select>
                                    <v-select
                                      :items="assignBranchCopy"
                                      v-model="replyForm.assignedBranch"
                                      :label="
                                        'Assigned Branch'
                                          | translate('60305.LongText')
                                      "
                                      :rules="
                                        replyFormRules.assignedBranchRules
                                      "
                                      auto
                                      return-object
                                      @click="unbindClickOutSide"
                                      @blur="bindClickOutSide"
                                    ></v-select>

                                    <v-select
                                      :items="departments"
                                      v-model="replyForm.assignedDepartment"
                                      :label="
                                        'Assigned Department'
                                          | translate('60160.LongText')
                                      "
                                      :rules="
                                        replyFormRules.assignedDepartmentRules
                                      "
                                      auto
                                      v-if="
                                        departmentValues &&
                                        departmentValues.length > 0
                                      "
                                      return-object
                                      @click="unbindClickOutSide"
                                      @blur="bindClickOutSide"
                                    ></v-select>
                                  </div>
                                  <!-- replay-popup-close icon -->
                                  <v-btn
                                    class="close-icon cc-close-icon"
                                    id="close"
                                    @click="closePopup()"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-list>
                            </div>
                          </v-col>
                          <!-- reply radio dropdown end -->
                          <div class="editor-btn-group" v-if="showRichText">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <div class="image-upload">
                                  <span v-bind="attrs" v-on="on">
                                    <label for="file-input">
                                      <img
                                        src="@/assets/images/icons/attachment.png"
                                        style="
                                          height: 36px;
                                          border-radius: 30px;
                                        "
                                      />
                                    </label>
                                  </span>
                                  <input
                                    id="file-input"
                                    type="file"
                                    multiple
                                    @change="handleFileInput"
                                    :accept="extentions"
                                  />
                                </div>
                              </template>
                              <span>{{
                                "Attach file" | translate("60350.LongText")
                              }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  @click="closemessageAndRemoveData($event)"
                                  class="close-icon"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </template>
                              {{ "Cancel" | translate("60390.LongText") }}
                            </v-tooltip>
                            <div v-if="fileList.length == 0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    :disabled="disableClickButton"
                                    @click="userSubscription"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="send-icon"
                                  >
                                    <v-icon :disabled="disableClickButton"
                                      >mdi-send</v-icon
                                    >
                                  </v-btn>
                                </template>
                                <span>
                                  {{ "Send" | translate("60391.LongText") }}
                                </span>
                              </v-tooltip>
                            </div>
                            <div v-if="fileList.length > 0">
                              <div
                                v-if="
                                  replyForm.replyMsgStatus == null &&
                                  replyForm.replyMessage == null
                                "
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      @click="userSubscription"
                                      class="send-icon"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>mdi-send</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    {{ "Send" | translate("60391.LongText") }}
                                  </span>
                                </v-tooltip>
                              </div>
                              <div v-else>
                                <div
                                  v-if="
                                    (replyForm.replyMsgStatus == null &&
                                      replyForm.replyMessage != '') ||
                                    (replyForm.replyMsgStatus !== null &&
                                      replyForm.replyMessage == '')
                                  "
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        :disabled="true"
                                        @click="userSubscription"
                                        class="send-icon"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon :disabled="true"
                                          >mdi-send</v-icon
                                        >
                                      </v-btn>
                                    </template>
                                    <span>
                                      {{ "Send" | translate("60391.LongText") }}
                                    </span>
                                  </v-tooltip>
                                </div>
                                <div
                                  v-else-if="
                                    replyForm.replyMsgStatus === 'REASSIGN' &&
                                    replyForm.replyMessage != ''
                                  "
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        :disabled="
                                          replyForm.assignedBranch == null ||
                                          replyForm.assignedDepartment == null
                                        "
                                        @click="userSubscription"
                                        class="send-icon"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon
                                          :disabled="
                                            replyForm.assignedBranch == null ||
                                            replyForm.assignedDepartment == null
                                          "
                                          >mdi-send</v-icon
                                        >
                                      </v-btn>
                                    </template>
                                    <span>
                                      {{ "Send" | translate("60391.LongText") }}
                                    </span>
                                  </v-tooltip>
                                </div>
                                <div v-else>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        @click="userSubscription"
                                        class="send-icon"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon>mdi-send</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      {{ "Send" | translate("60391.LongText") }}
                                    </span>
                                  </v-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <v-text-field
                      class="removepadding"
                        v-if="!showRichText"
                        data-cy="showRichText"
                        v-on:click="showRichTextfn(true)"
                        :placeholder="
                          'Start working on a ticket' | translate('60321.LongText')
                        "
                        v-model="writeMessage"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <!-- ck-editor end-->
                  </v-row>
                </v-container>
              </div>
            </div>
          </div>
        </v-form>
        <!-- replies section end -->
      </div>
    </div>
    <v-dialog v-model="alertPopup" max-width="570">
      <v-card>
        <v-card-title class="text-h5">
          {{ "Important" | translate("60346.LongText") }}
        </v-card-title>
        <v-card-text>
          <div v-html="alertmessage"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            rounded
            class="close-alert-btn mr-2 mb-2"
            text
            @click="closeAlertPopup"
          >
            {{ "Close" | translate("60199.LongText") }}
          </v-btn>
          <v-btn
            rounded
            class="save-btn mr-2 mb-2"
            text
            @click="sendReplyMessage"
            data-cy="submit"
          >
            {{ "CreateAnyway" | translate("60347.LongText") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  languageTranslationService,
  loaderService,
  refreshCollaborationDetail,
  pageChangeService,
  subscribeService,
  headerSubscribeService,
} from "@/services/observables";
import {
  loadCollaborationById,
  sendReplyMessage,
  getBranchAndDepartment,
  setBranchAndDepartment,
  getUserSubscriptionList,
  loadTranslatorLanguage,
  subscribeUnsubscribeBranchAndDepartment,
  getDataFromFileType,
  tagsSuggestions,
  getConsignmentMenifestNumber,
  getPickUpOrderMenifestNumber,
  checkSubscribeStatus,
} from "@/services/collaboration.service";
import DisplayFiles from "./Display-files";
import Vue from "vue";
import { addTrace } from "@/services/logging";
import moment from "moment";
import "quill-mention";
import { HubConnectionBuilder } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
import VueSanitize from "vue-sanitize";
Vue.use(VueSanitize);

import vClickOutside from "v-click-outside";
const { bind, unbind } = vClickOutside.directive;
const notificationUrl = JSON.parse(localStorage.getItem("AppConfig")).UrlNotification
const notificationConnection = new HubConnectionBuilder()
  .withUrl(notificationUrl + '/notify', {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  })
  .withAutomaticReconnect()
  .build();

export default {
  name: "CollaborationDetail",
  components: {
    DisplayFiles,
  },
  props: [
    "selectedRowData",
    "translationCode",
    "assignedBranches",
    "createdBranches",
    "arrEntryStatus",
    "arrCollaborationEntryBranch",
    "functions",
    "departments",
    "onChange",
  ],
  data() {
    return {
      alertPopup: false,
      alertmessage: "",
      repliesOptions: [],
      replies: [],
      sub: "",
      functionValues: this.functions,
      departmentValues: this.departments,
      detailsData: null,
      validReply: true,
      translateLanguageCode: "",
      showAssignedOptions: false,
      selectAssignedOptionsValue: false,
      replyForm: {
        assignedBranch: { value: null },
        assignedFunction: { value: null },
        assignedDepartment: { value: null },
        replyMsgStatus: null,
        replyMessage: null,
      },
      replyFormRules: {
        replyMessageRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60313, "LongText") +
              " " +
              this.getTranslatedLanguage(80110, "LongText"),
        ],
        assignedBranchRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60305, "LongText") +
              " " +
              this.getTranslatedLanguage(80110, "LongText"),
        ],
        assignedFunctionRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60071, "LongText") +
              " " +
              this.getTranslatedLanguage(80110, "LongText"),
        ],
        assignedDepartmentRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60160, "LongText") +
              " " +
              this.getTranslatedLanguage(80110, "LongText"),
        ],
        replyMsgStatusRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60303, "LongText") +
              " " +
              this.getTranslatedLanguage(80110, "LongText"),
        ],
      },
      showRichText: false,
      isClose: false,
      isbranchDept: false,
      createdsubscribeUnsubscribeValue: "",
      assignedsubscribeUnsubscribeValue: "",
      createdvalue: false,
      assignedvalue: false,
      toogle: true,
      setLanguageDropDown: [],
      setLanguageDropDownValue: {
        translateLanguageCode: "",
        translateLanguageName: this.getTranslatedLanguage(60332, "ShortText"),
        translateLanguageNativeName: this.getTranslatedLanguage(
          60332,
          "ShortText"
        ),
      },
      headerDept: "",
      headerBranch: "",
      createdDept: "",
      suggesstionText: [],
      extentions: [],
      fileList: [],
      fileExtentionsObj: {
        pdf: ["pdf"],
        xlsx: [
          "xlsx",
          "xls",
          "csv",
          "ods",
          "xla",
          "xlam",
          "xlsb",
          "xlsm",
          "xml",
        ],
        doc: [
          "doc",
          "docx",
          "txt",
          "html",
          "htm",
          "xps",
          "mp4",
          "mkv",
          "mp3",
          "ppt",
          "pptx",
          "ppsx",
          "tif",
          "mht",
          "mhtml",
          "rtf",
        ],
        png: ["png", "webp", "jfif", "jpg", "jpeg", "JPEG"],
        all: [
          "pdf",
          "xls",
          "xlsx",
          "csv",
          "doc",
          "docx",
          "jpg",
          "jpeg",
          "png",
          "txt",
          "html",
          "htm",
          "ods",
          "xla",
          "xlam",
          "xlsb",
          "xlsm",
          "xps",
          "mp4",
          "mkv",
          "mp3",
          "ppt",
          "pptx",
          "ppsx",
          "tif",
          "xml",
          "mht",
          "mhtml",
          "rtf",
          "jfif",
          "all",
        ],
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
      ],
      collaborationFileType: 0,
      collaborationConsigmentDetails: [],
      consignmentCount: 0,
      toBanchRefrence: "",
      fromBranchRefrence: "",
      disableClickButton: true,
      writeMessage: "",
      assignBranchCopy: [],
      assignDept: "",
      displayOverlay: false ,
      userTyping:[],
    };
  },

  // on created hook
  async created() {
    // upload document on paste event and invoke service
    addEventListener('paste',async (event)=>{
      let self = this;
      const items = Array.from(event.clipboardData.items);
      const filelist = [];
      items.forEach((item)=>{
        const file = item.getAsFile();
        if(file != null){
          filelist.push(file);
        }
      });
      if(filelist.length > 0 && (filelist.length + self.fileList.length) <= 5){
          event.stopPropagation();
          this.handleFileInputOnDropAndPaste(filelist);
          loaderService.setLoader(false);
          this.showRichText = true;
          this.invokeBroadcastActiveUserDataService(true);
        }
        else if(filelist.length == 0 ){
          console.log("only file allowed");
        }
      else{
        Vue.notify({
            group: "foo",
            type: "error",
            title: self.translationCode[self.$i18n.locale][60351]?.LongText,
            duration: 5000,
          });
          this.invokeBroadcastActiveUserDataService(false);
      }
    });

    // open richtext and display overlay div and invoke service
    addEventListener('dragover',(event)=>{
      window.focus()
      if(event.dataTransfer.types[0] != "text/plain"){
        this.showRichText = true
        this.invokeBroadcastActiveUserDataService(true);
      }else{
        this.showRichText = false;
        this.invokeBroadcastActiveUserDataService(false);
      }
      if(event.dataTransfer.types[0] == 'Files'){
        this.displayOverlay = true;
        this.invokeBroadcastActiveUserDataService(true);
      }
      event.preventDefault(); 
      event.stopPropagation();
    });

    // remove display overlay on drag leave event and invoke service
    addEventListener('dragleave',()=>{
      this.invokeBroadcastActiveUserDataService(false);
    });

    // remove display overlay on blur event and invoke service 
    addEventListener('blur',()=>{
      this.invokeBroadcastActiveUserDataService(false);
      this.displayOverlay = false;
    });

    // invoke service on window close
    window.addEventListener('beforeunload', ()=>{
      this.invokeBroadcastActiveUserDataService(false);
      notificationConnection.stop()
      this.displayOverlay = false;
    });

    // invoke service ontab close
    addEventListener('beforeunload', ()=>{
    this.displayOverlay = false;
    this.invokeBroadcastActiveUserDataService(false);
    notificationConnection.stop()
    });

    // invoke service and hide overlay on window focus
    window.onfocus = () => {
      if(this.displayOverlay)
      {
        this.invokeBroadcastActiveUserDataService(false);
        this.displayOverlay = false;
      }
      if((this.showRichText && this.replyForm.replyMessage)){
        this.invokeBroadcastActiveUserDataService(true);
      }
    };

    // set dynamic hight to div according to window size update
    if (document.getElementsByClassName("collaboration-card").length < 0) {
      window.addEventListener("resize", () => {
        let write_message_height = this.$refs.write_message.clientHeight;
        let reply_div_height = this.$refs.reply_div.clientHeight;
        this.toogle == true
          ? (document.getElementById("chat_pop_toggle_true").style.height =
              reply_div_height - write_message_height - 55 + "px")
          : (document.getElementById("chat_pop_toggle_false").style.height =
              reply_div_height - write_message_height - 55 + "px");
      });
    }

    // get accepted extension from appconfig
    this.extentions = JSON.parse(
      localStorage.getItem("AppConfig")
    ).acceptedExtentions;
    this.setLanguageDropDown.push(this.setLanguageDropDownValue);
    this.alertmessage = this.getTranslatedLanguage(60345, "LongText");

    // get branch and department 
    getBranchAndDepartment((res) => {
      if (res && res.status == 200) {
        this.translateLanguageCode =
          res.data.translationLanguage == null ||
          res.data.translationLanguage == "" ||
          res.data.translationLanguage == undefined
            ? ""
            : res.data.translationLanguage;
        this.assignBranchCopy = [];
        if (
          res.data.arrCollaborationEntryBranch != null &&
          res.data.arrCollaborationEntryBranch.length > 0
        ) {
          for (
            var i = 0;
            i < res.data.arrCollaborationEntryBranch.length;
            i++
          ) {
            let assignBranch = this.assignedBranches.find(
              (branch) =>
                branch.value == res.data.arrCollaborationEntryBranch[i]
            );
            if (assignBranch) {
              let obj = {
                text: assignBranch.text,
                value: assignBranch.value,
              };
              this.assignBranchCopy.push(obj);
            }
          }
        }
        if (this.assignBranchCopy.length == 0) {
          let branch = this.createdBranches.find(
            (branch) => branch.entityNo == res.data.branchNo
          );
          let obj = {
            text: branch.text,
            value: branch.value,
          };
          this.assignBranchCopy.push(obj);
        }
      }
      this.getCollaborationById(
        this.selectedRowData["_id"],
        this.translateLanguageCode
      );
    });

    //get reply options and load application 
    this.getAllRepliesOptions();
    this.loadTranslatorLanguage();

    // refresh list on lang change
    languageTranslationService.getLang().subscribe(() => {
      this.getCollaborationById(
        this.selectedRowData["_id"],
        this.translateLanguageCode
      );
      this.getAllRepliesOptions();
      this.showRichTextfn();
    });

    await pageChangeService.getPageData().subscribe((res) => {
      this.dataFormate(res);
    });

    this.sub = headerSubscribeService.getHeaderSubscribe().subscribe(() => {
      loaderService.setLoader(true);
      this.getUserSubscriptionLists(this.replies);
    });
    this.logedUserEmailId = sessionStorage.getItem("emailAddress");

    //signalR for reply
    let collaborationUrl = JSON.parse(localStorage.getItem("AppConfig")).UrlApi;
    const connection = new HubConnectionBuilder()
      .withUrl(collaborationUrl + "/CollaborationSignalRHub", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();
    connection.start().then(() =>
      connection.on("SendCollaborationReply", (res) => {
        if (this.selectedRowData["_id"] == res._id) {
          this.detailsData = res;
          this.getDataBasedOnFileType(
            this.detailsData.collaborationEntry.fileType
          );
          this.replies = res?.collaborationEntry.replies;
          this.collaborationFileType =
            res?.collaborationEntry.fileType.sysValueNo;
          if (
            this.detailsData.collaborationEntry.fileType.sysValueNo == "12041"
          ) {
            this.getConsignmentNumber(
              this.detailsData.collaborationEntry.fileId
            );
          }
          if (
              this.detailsData.collaborationEntry.fileType.sysValueNo == "12042"
            ) {
              this.getPickUpOrderNumber(
                this.detailsData.collaborationEntry.fileId
              );
            }
          if (this.replies && this.replies.length != 0) {
            this.collaborationConsigmentDetails = [];
            this.replies.forEach((reply) => {
              if (reply.tags.length > 0) {
                const regex = /(<\/?\w+\s*\/?>)|(#\w+)|(\w+)|(&\w+;)/g;
                const commentArray = reply.comment.match(regex);
                const replacedText = commentArray
                  .map((tag) => {
                    if (tag.startsWith("#")) {
                      return `<a id=${tag}>${tag}</a>`;
                    } else {
                      return tag;
                    }
                  })
                  .join(" ");
                reply.comment = replacedText;
              }
            });
            const firstCreatedBranchId = this.getfirstBranchId(
              "createdBranch",
              this.replies
            );
            const firstAssignedBranchId = this.getfirstBranchId(
              "assignedBranch",
              this.replies
            );
            for (let index = 0; index < this.replies?.length; index++) {
              this.replies[index].createdDtTmLocalFormatted = moment(
                this.replies[index]?.createdDtTmLocal
              ).format("DD.MM.YY HH:mm");
              let consignmentDetailsObj = {
                fileType:
                  this.detailsData.collaborationEntry &&
                  this.detailsData.collaborationEntry.fileType &&
                  this.detailsData.collaborationEntry.fileType.longText
                    ? this.detailsData.collaborationEntry.fileType.longText
                    : "",
                fromBranch:
                    this.replies[index] && (this.replies[index].createdBranch
                    ? this.replies[index].createdBranch.branchId
                      : "") + (this.replies[index].createdBranch?.country != null
                        ? " " + this.replies[index].createdBranch?.country
                        : "") + (this.replies[index].createdBranch?.city != null
                          ? " - " + this.replies[index].createdBranch?.city
                          : ""),
                toBranch:
                    this.replies[index] && (this.replies[index].assignedBranch
                    ? this.replies[index].assignedBranch?.branchId
                      : "") + (this.replies[index].assignedBranch?.country != null
                        ? " " + this.replies[index].assignedBranch?.country
                        : "") + (this.replies[index].assignedBranch?.city != null
                          ? " - " + this.replies[index].assignedBranch?.city
                          : ""),
                fromBranchId:
                  this.replies[index] && (this.replies[index].createdBranch ? this.replies[index].createdBranch.branchId : ""),
                fromToBranchId:
                  this.replies[index] && (this.replies[index].assignedBranch ? this.replies[index].assignedBranch?.branchId : ""),
              };
              // if (this.collaborationFileType == "12041") {
              if (this.replies[index].assignedBranch !== null) {
                if (this.replies.length === 1) {
                  // this.setRefrenceToBranch(consignmentDetailsObj);
                  this.collaborationConsigmentDetails.push(
                    consignmentDetailsObj
                  );
                } else {
                  if (this.replies.length > 1) {
                    if (this.collaborationConsigmentDetails.length < 2) {
                      if (
                        this.replies[index].assignedBranch?.branchId ==
                          firstAssignedBranchId ||
                        this.replies[index].assignedBranch?.branchId ==
                          firstCreatedBranchId
                      ) {
                        this.collaborationConsigmentDetails = [];
                        this.collaborationConsigmentDetails.push(
                          consignmentDetailsObj
                        );
                      } else {
                        this.collaborationConsigmentDetails.push(
                          consignmentDetailsObj
                        );
                      }
                    } else {
                      const lastConsignmentDetails =
                        this.collaborationConsigmentDetails[
                          this.collaborationConsigmentDetails.length - 1
                        ];
                      if (
                        this.replies[index].assignedBranch?.branchId ==
                          lastConsignmentDetails.fromBranchId ||
                        this.replies[index].assignedBranch?.branchId ==
                          lastConsignmentDetails.toBranchId
                      ) {
                        this.collaborationConsigmentDetails.splice(
                          this.collaborationConsigmentDetails.length - 1,
                          1,
                          consignmentDetailsObj
                        );
                      } else {
                        this.collaborationConsigmentDetails.push(
                          consignmentDetailsObj
                        );
                      }
                    }
                  }
                }
              }
              // } else {
              //   this.collaborationConsigmentDetails.push(consignmentDetailsObj);
              // }
            }
          }
          this.createdDept =
            this.detailsData.collaborationEntry.createdDepartment.sysValueId;
          this.assignDept =
            this.detailsData.collaborationEntry.assignedDepartment.sysValueId;
          this.getUserSubscriptionLists(this.replies);
          this.detailsData.commentHTML =
            this.detailsData?.collaborationEntry.comment;
          this.detailsData.collaborationEntry.createdDtTmLocalFormat = moment(
            this.detailsData?.collaborationEntry?.createdDtTmLocal
          ).format("DD.MM.YY HH:mm");
          this.detailsData.collaborationEntry.modifiedDtTmLocalFormat = moment(
            this.detailsData?.objectInfo?.dbChangedDtTm
          ).format("DD.MM.YY HH:mm");
          this.detailsData["createdBranch.nametooltip"] = res?.createdBranch
            ?.name
            ? res?.createdBranch?.name
            : "";
          this.detailsData["createdBranch.countrytooltip"] =
            (res?.createdBranch?.country ? res?.createdBranch?.country : "") +
            (res?.createdBranch?.zipCode
              ? " - " + res?.createdBranch?.zipCode
              : "") +
            (res?.createdBranch?.city ? "  " + res?.createdBranch?.city : "");

          this.detailsData["assignedBranch.assignedNametooltip"] = res
            ?.collaborationEntry?.assignedBranch?.name
            ? res?.collaborationEntry?.assignedBranch?.name
            : "";
          this.detailsData["assignedBranch.assignedCountrytooltip"] =
            (res?.collaborationEntry?.assignedBranch?.country
              ? res?.collaborationEntry?.assignedBranch?.country
              : "") +
            (res?.collaborationEntry?.assignedBranch?.zipCode
              ? " - " + res?.collaborationEntry?.assignedBranch?.zipCode
              : "") +
            (res?.collaborationEntry?.assignedBranch?.city
              ? "  " + res?.collaborationEntry?.assignedBranch?.city
              : "");
          this.setReplyFormData();
          if (
            this.detailsData.collaborationEntry.entryStatus.longText == "closed"
          ) {
            this.isClose = true;
            this.userTyping = [];
          }else{
            this.isClose = false;
          }
          this.checkCloseStatus();
          loaderService.setLoader(false);
        }
      }),
    );
    notificationConnection.start().then(() =>
      notificationConnection.on("BroadcastActiveUserData", (response) => {
      if(this.selectedRowData["_id"] == response.ticketId){
          this.userTyping = response.lstUserId.filter((user)=>{
            return user !== sessionStorage.getItem("emailAddress")
          });
        }
      })
      )
      .then(()=>{
        this.invokeBroadcastActiveUserDataService(false);
      })
    this.$watch( "selectedRowData",(newVal,oldval)=>{
      this.getCollaborationById(
        this.selectedRowData["_id"],
        this.translateLanguageCode);
      this.invokeBroadcastActiveUserDataService(false);
      let invokeobj = {
      ticketId : oldval._id ,
      userId : sessionStorage.getItem("emailAddress") ,
      isTyping : false
    }
    notificationConnection.invoke('BroadcastActiveUserData',invokeobj);
    })
  },

  // change size of showrichtext div and detail div
  updated() {
    let write_message_height = this.$refs.write_message.clientHeight;
    let reply_div_height = this.$refs.reply_div.clientHeight;
    this.toogle == true
      ? (document.getElementById("chat_pop_toggle_true").style.height =
          reply_div_height - write_message_height - 55 + "px")
      : (document.getElementById("chat_pop_toggle_false").style.height =
          reply_div_height - write_message_height - 55 + "px");
    this.toogle == true
      ? document.getElementById("chat_pop_toggle_true").scrollTo(0, 99999)
      : document.getElementById("chat_pop_toggle_false").scrollTo(0, 99999);

      // display ... after message length is more than 20 
    if (this.writeMessage !== this.replyForm.replyMessage) {
      let text = this.replyForm?.replyMessage?.replaceAll(/<.*?>/g, "");
      if (text?.length > 21) {
        this.writeMessage = text.slice(0, 20) + "...";
      } else {
        this.writeMessage = text;
      }
    }
    // disable send button 
    if (
      (this.replyForm.replyMessage != "" &&
        this.replyForm.replyMsgStatus == null) ||
      (this.replyForm.replyMessage == "" &&
        this.replyForm.replyMsgStatus != null) ||
      (this.replyForm.replyMsgStatus == null &&
        this.replyForm.replyMessage == "")
    ) {
      this.disableClickButton = true;
    }

    if(this.showRichText && this.fileList.length != 0){
      this.invokeBroadcastActiveUserDataService(true);
    }
  },
  computed: {
    // show a matched tag on after type # 
    d_editorOptions() {
      let self = this;
      return {
        debug: "info",
        theme: "snow",
        modules: {
          mention: {
            allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["#"],
            minChars: 2,
            source: async function (searchTerm, renderList) {
              // let values=[];

              if (searchTerm.length === 0) {
                renderList(self.suggesstionText, searchTerm);
              } else if (searchTerm.length >= 2) {
                if (
                  !searchTerm.includes(" ") &&
                  searchTerm.trim().match(/(\w+)/g)?.length == 1
                ) {
                  tagsSuggestions(searchTerm).then((res) => {
                    let matches = [];
                    if (res && res.status === 200) {
                      self.suggesstionText = res.data;
                      for (let i = 0; i < self.suggesstionText.length; i++) {
                        if (
                          ~self.suggesstionText[i]
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase())
                        )
                          matches.push({
                            ...self.suggesstionText[i],
                            value: self.suggesstionText[i].slice(
                              1,
                              self.suggesstionText[i].length
                            ),
                          });
                        renderList(matches, searchTerm);
                      }
                    } else {
                      renderList(matches, searchTerm);
                    }
                  });
                } else {
                  renderList(self.suggesstionText, searchTerm);
                }
              }
            },
          },
        },
      };
    },
  },
  methods: {
    // get translated language 
    getTranslatedLanguage: function (value, text) {
      return this.translationCode[this.$i18n.locale][value] == undefined
        ? this.translationCode["en"][value][text]
        : this.translationCode[this.$i18n.locale][value][text] == undefined
        ? this.translationCode["en"][value][text]
        : this.translationCode[this.$i18n.locale][value][text];
    },
    //get subscription list of user
    getUserSubscriptionLists: function (replies) {
      let self = this;
      getUserSubscriptionList(function (res) {
        if (res?.data) {
          const checkSubscribe = res.data.entitySubscriptions.find(
            (subscribe) =>
              subscribe.department.sysValueId ==
                self.detailsData.collaborationEntry.createdDepartment
                  .sysValueId &&
              subscribe.entity.branchId ==
                self.detailsData.createdBranch.branchId
          );
          if (checkSubscribe) {
            self.createdvalue = true;
            self.createdsubscribeUnsubscribeValue =
              self.translationCode[self.$i18n.locale][60330]?.LongText;
            loaderService.setLoader(false);
          } else {
            self.createdvalue = false;
            self.createdsubscribeUnsubscribeValue =
              self.translationCode[self.$i18n.locale][60331]?.LongText;
            loaderService.setLoader(false);
          }
          if (
            self.detailsData.collaborationEntry.assignedDepartment != null &&
            self.detailsData.collaborationEntry.assignedBranch != null
          ) {
            const checkSubscribeAssign = res.data.entitySubscriptions.find(
              (subscribe) =>
                subscribe.department.sysValueId ==
                  self.detailsData.collaborationEntry.assignedDepartment
                    .sysValueId &&
                subscribe.entity.branchId ==
                  self.detailsData.collaborationEntry.assignedBranch.branchId
            );
            if (checkSubscribeAssign) {
              self.assignedvalue = true;
              self.assignedsubscribeUnsubscribeValue =
                self.translationCode[self.$i18n.locale][60330]?.LongText;
              loaderService.setLoader(false);
            } else {
              self.assignedvalue = false;
              self.assignedsubscribeUnsubscribeValue =
                self.translationCode[self.$i18n.locale][60331]?.LongText;
              loaderService.setLoader(false);
            }
          }
          if (replies) {
            loaderService.setLoader(true);
            replies.forEach((reply) => {
              const checkSubscribe1 = res.data.entitySubscriptions.find(
                (subscribe) =>
                  subscribe.department.sysValueId ==
                    reply.createdDepartment.sysValueId &&
                  subscribe.entity.branchId == reply.createdBranch.branchId
              );
              if (checkSubscribe1) {
                reply.createdvalue = true;
                reply.createdsubscribeUnsubscribeValue =
                  self.translationCode[self.$i18n.locale][60330]?.LongText;
              } else {
                reply.createdvalue = false;
                reply.createdsubscribeUnsubscribeValue =
                  self.translationCode[self.$i18n.locale][60331]?.LongText;
              }
              if (
                reply.assignedBranch != null &&
                reply.assignedDepartment != null
              ) {
                const checkSubscribe2 = res.data.entitySubscriptions.find(
                  (subscribe) =>
                    subscribe.department.sysValueId ==
                      reply.assignedDepartment.sysValueId &&
                    subscribe.entity.branchId == reply.assignedBranch.branchId
                );
                if (checkSubscribe2) {
                  reply.assignedvalue = true;
                  reply.assignedsubscribeUnsubscribeValue =
                    self.translationCode[self.$i18n.locale][60330]?.LongText;
                } else {
                  reply.assignedvalue = false;
                  reply.assignedsubscribeUnsubscribeValue =
                    self.translationCode[self.$i18n.locale][60331]?.LongText;
                }
              }
            });
            loaderService.setLoader(false);
            Vue.set(self, "replies", []);
            Vue.set(self, "replies", replies);
          }
        } else {
          self.createdvalue = false;
          self.createdsubscribeUnsubscribeValue =
            self.translationCode[self.$i18n.locale][60331]?.LongText;
          self.replies.forEach((reply) => {
            reply.createdvalue = false;
            reply.assignedvalue = false;
            reply.createdsubscribeUnsubscribeValue =
              self.translationCode[self.$i18n.locale][60331]?.LongText;
          });
          loaderService.setLoader(false);
        }
      });
    },
    // check close status
    checkCloseStatus() {
      this.onChange.subscribe(() => {
        let self = this;
        if (this.onChange._value == true) {
          this.setSelectedBranchDept(function (resBranchDept) {
            // if branch and department is same then only check the status
            if (resBranchDept) {
              self.getUserSubscriptionLists();
              if (
                self.detailsData.createdBranch.branchId ==
                  resBranchDept.createdBranch &&
                self.detailsData.collaborationEntry.createdDepartment
                  .sysValueId == self.createdDept
              ) {
                self.isbranchDept = true;
              } else {
                self.isbranchDept = false;
              }
            }
          });
        }
      });
    },

    // set close status
    setStatusClose() {
      this.replyForm["replyMsgStatus"] = "CLOSED";
      this.sendReplyMessage();
    },

    //subscribe and unsubscribe selected branch and department
    subscribeUnsubscribeAssignedBranchDepartment(ev, item) {
      let self = this;
      self.assignedvalue = ev;
      let obj = {
        entityType: {
          sysValueId: "Branch",
        },
        entity: {
          id:
            item == ""
              ? self.detailsData.collaborationEntry.assignedBranch.branchId
              : item?.assignedBranch?.branchId,
        },
        department: {
          sysValueId:
            item == ""
              ? self.detailsData.collaborationEntry.assignedDepartment
                  .sysValueId
              : item.assignedDepartment.sysValueId,
        },
        notificationType: {
          sysValueId: "EMAIL",
        },
        languageCode: {
          sysValueId: sessionStorage.getItem("localeLang"),
        },
      };
      var params = {
        obj: obj,
        value: self.assignedvalue ? "unsubscribe" : "subscribe",
      };
      loaderService.setLoader(true);
      subscribeUnsubscribeBranchAndDepartment(params, function (resSubscribe) {
        var obj = {
          department:
            self.detailsData.collaborationEntry.assignedDepartment.sysValueNo,
          branch: self.detailsData.collaborationEntry.assignedBranch.entityNo,
        };
        if (resSubscribe && resSubscribe.status == 201) {
          self.checkAssignSubscribeUnsubscribeForReply(
            resSubscribe,
            function (resReply) {
              if (resReply) {
                self.checkSubscribeUnsubscribeAssignedFlag(
                  resSubscribe,
                  function (resDetail) {
                    if (resDetail) {
                      subscribeService.setSubscribe(obj);
                      loaderService.setLoader(false);
                    } else {
                      subscribeService.setSubscribe(obj);
                      loaderService.setLoader(false);
                    }
                  }
                );
              } else {
                subscribeService.setSubscribe(obj);
                loaderService.setLoader(false);
              }
            }
          );
        } else {
          self.checkAssignSubscribeUnsubscribeForReply(
            resSubscribe,
            function (resReply) {
              if (resReply) {
                self.checkSubscribeUnsubscribeAssignedFlag(
                  resSubscribe,
                  function (resDetail) {
                    if (resDetail) {
                      subscribeService.setSubscribe(obj);
                      loaderService.setLoader(false);
                    } else {
                      subscribeService.setSubscribe(obj);
                      loaderService.setLoader(false);
                    }
                  }
                );
              } else {
                subscribeService.setSubscribe(obj);
                loaderService.setLoader(false);
              }
            }
          );
        }
      });
    },

    //subscribe and unsubscribe selected branch and department
    subscribeUnsubscribeBranchDepartment(ev, item) {
      let self = this;
      self.createdvalue = ev;
      let obj = {
        entityType: {
          sysValueId: "Branch",
        },
        entity: {
          id:
            item == ""
              ? self.detailsData.createdBranch.branchId
              : item?.createdBranch?.branchId,
        },
        department: {
          sysValueId:
            item == ""
              ? self.detailsData.collaborationEntry.createdDepartment.sysValueId
              : item.createdDepartment.sysValueId,
        },
        notificationType: {
          sysValueId: "EMAIL",
        },
        languageCode: {
          sysValueId: sessionStorage.getItem("localeLang"),
        },
      };
      var params = {
        obj: obj,
        value: self.createdvalue ? "unsubscribe" : "subscribe",
      };
      loaderService.setLoader(true);
      subscribeUnsubscribeBranchAndDepartment(params, function (resSubscribe) {
        var obj = {
          department:
            self.detailsData.collaborationEntry.createdDepartment.sysValueNo,
          branch: self.detailsData.createdBranch.entityNo,
        };
        if (resSubscribe && resSubscribe.status == 201) {
          self.checkSubscribeUnsubscribeForReply(
            resSubscribe,
            function (resReply) {
              if (resReply) {
                self.checkSubscribeUnsubscribeFlag(
                  resSubscribe,
                  function (resDetail) {
                    if (resDetail) {
                      subscribeService.setSubscribe(obj);
                      loaderService.setLoader(false);
                    } else {
                      subscribeService.setSubscribe(obj);
                      loaderService.setLoader(false);
                    }
                  }
                );
              } else {
                subscribeService.setSubscribe(obj);
                loaderService.setLoader(false);
              }
            }
          );
        } else {
          self.checkSubscribeUnsubscribeForReply(
            resSubscribe,
            function (resReply) {
              if (resReply) {
                self.checkSubscribeUnsubscribeFlag(
                  resSubscribe,
                  function (resDetail) {
                    if (resDetail) {
                      subscribeService.setSubscribe(obj);
                      loaderService.setLoader(false);
                    } else {
                      subscribeService.setSubscribe(obj);
                      loaderService.setLoader(false);
                    }
                  }
                );
              } else {
                subscribeService.setSubscribe(obj);
                loaderService.setLoader(false);
              }
            }
          );
        }
      });
    },

    //check subscribe and unsubscribe for reply
    checkSubscribeUnsubscribeForReply: function (resSubscribe, callback) {
      this.replies.forEach((reply) => {
        const checkSubscribe = resSubscribe.data.find(
          (subscribe) =>
            subscribe.department.sysValueId ==
              reply.createdDepartment.sysValueId &&
            subscribe.entity.branchId == reply.createdBranch.branchId
        );
        if (checkSubscribe) {
          reply.createdvalue = true;
          reply.createdsubscribeUnsubscribeValue = this.getTranslatedLanguage(
            60330,
            "LongText"
          );
        } else {
          reply.createdvalue = false;
          reply.createdsubscribeUnsubscribeValue = this.getTranslatedLanguage(
            60331,
            "LongText"
          );
        }
      });
      if (callback) {
        return callback(true);
      }
    },

    //check subscribe and unsubscribe for reply
    checkAssignSubscribeUnsubscribeForReply: function (resSubscribe, callback) {
      this.replies.forEach((reply) => {
        if (reply.assignedDepartment != null && reply.assignedBranch != null) {
          const checkSubscribe = resSubscribe.data.find(
            (subscribe) =>
              subscribe.department.sysValueId ==
                reply.assignedDepartment.sysValueId &&
              subscribe.entity.branchId == reply.assignedBranch.branchId
          );
          if (checkSubscribe) {
            reply.assignedvalue = true;
            reply.assignedsubscribeUnsubscribeValue =
              this.getTranslatedLanguage(60330, "LongText");
          } else {
            reply.assignedvalue = false;
            reply.assignedsubscribeUnsubscribeValue =
              this.getTranslatedLanguage(60331, "LongText");
          }
        }
      });
      if (callback) {
        return callback(true);
      }
    },

    //check subscribe and unsubscribe for detail
    checkSubscribeUnsubscribeFlag: function (resSubscribe, callback) {
      const checkSubscribe = resSubscribe.data.find(
        (subscribe) =>
          subscribe.department.sysValueNo ==
            this.detailsData.collaborationEntry.createdDepartment.sysValueNo &&
          subscribe.entity.entityNo == this.detailsData.createdBranch.entityNo
      );
      if (checkSubscribe) {
        this.createdvalue = true;
        this.createdsubscribeUnsubscribeValue = this.getTranslatedLanguage(
          60330,
          "LongText"
        );
      } else {
        this.createdvalue = false;
        this.createdsubscribeUnsubscribeValue = this.getTranslatedLanguage(
          60331,
          "LongText"
        );
      }
      if (callback) {
        return callback(true);
      }
    },

    //check subscribe and unsubscribe for assigned
    checkSubscribeUnsubscribeAssignedFlag: function (resSubscribe, callback) {
      const checkSubscribe = resSubscribe.data.find(
        (subscribe) =>
          subscribe.department.sysValueNo ==
            this.detailsData.collaborationEntry.assignedDepartment.sysValueNo &&
          subscribe.entity.entityNo ==
            this.detailsData.collaborationEntry.assignedBranch.entityNo
      );

      if (checkSubscribe) {
        this.assignedvalue = true;
        this.assignedsubscribeUnsubscribeValue = this.getTranslatedLanguage(
          60330,
          "LongText"
        );
      } else {
        this.assignedvalue = false;
        this.assignedsubscribeUnsubscribeValue = this.getTranslatedLanguage(
          60331,
          "LongText"
        );
      }
      if (callback) {
        return callback(true);
      }
    },

    // set translated language value 
    setTranslationLanuguage: function (id, translationLanguage) {
      // if (this.headerBranch == "" || this.headerDept == "") {
      getBranchAndDepartment((res) => {
        if (res && res.status == 200) {
          let obj = {
            isNotificationEnable: res.data.isNotificationEnable,
            isEmailNotificationEnable: res.data.isEmailNotificationEnable,
            isBrowserNotificationEnable: res.data.isBrowserNotificationEnable,
            arrEntryStatus: this.arrEntryStatus,
            arrCollaborationEntryBranch:
              this.arrCollaborationEntryBranch.length != 0
                ? this.arrCollaborationEntryBranch
                : res.data.arrCollaborationEntryBranch,
            branchNo:
              this.headerBranch.length != 0
                ? this.headerBranch
                : res.data.branchNo,
            arrDepartment:
              this.headerDept.length != 0
                ? this.headerDept
                : res.data.arrDepartment,
            languageCode: sessionStorage.getItem("localeLang"),
            translationLanguage: translationLanguage.translateLanguageCode,
            arrSelectedColumn: res.data.arrSelectedColumn,
          };
          let self = this;
          setBranchAndDepartment(obj, function (res) {
            if (res) {
              self.getCollaborationById(
                id,
                translationLanguage.translateLanguageCode
              );
            }
          });
        }
      });
      // } else {
      //   let obj = {
      //     arrEntryStatus: this.arrEntryStatus,
      //     arrCollaborationEntryBranch: this.arrCollaborationEntryBranch,
      //     branchNo: this.headerBranch,
      //     department: this.headerDept,
      //     languageCode: sessionStorage.getItem("localeLang"),
      //     translationLanguage: translationLanguage.translateLanguageCode,
      //   };
      //   let self = this;
      //   setBranchAndDepartment(obj, function (res) {
      //     if (res) {
      //       self.getCollaborationById(
      //         id,
      //         translationLanguage.translateLanguageCode
      //       );
      //     }
      //   });
      // }
    },

    // get collaboration detail by id
    getCollaborationById: function (id, translationLanguage) {
      loaderService.setLoader(true);
      let obj = {
        id: id,
        translationLanguage:
          translationLanguage == "" ? "" : translationLanguage,
      };
      loadCollaborationById(obj)
      .then((res) => {
          if (res && res?.status == 200) {
            this.detailsData = res?.data;
            this.getDataBasedOnFileType(
              this.detailsData.collaborationEntry.fileType
            );
            this.replies = res?.data?.collaborationEntry.replies;
            this.collaborationFileType =
              res?.data?.collaborationEntry.fileType.sysValueNo;
            if (
              this.detailsData.collaborationEntry.fileType.sysValueNo == "12041"
            ) {
              this.getConsignmentNumber(
                this.detailsData.collaborationEntry.fileId
              );
            }
            if (
              this.detailsData.collaborationEntry.fileType.sysValueNo == "12042"
            ) {
              this.getPickUpOrderNumber(
                this.detailsData.collaborationEntry.fileId
              );
            }
            if (this.replies && this.replies.length != 0) {
              this.collaborationConsigmentDetails = [];
              this.replies.forEach((reply) => {
                if (reply.tags.length > 0) {
                  const regex = /(<\/?\w+\s*\/?>)|(#\w+)|(\w+)|(&\w+;)/g;
                  const commentArray = reply.comment.match(regex);
                  const replacedText = commentArray
                    .map((tag) => {
                      if (tag.startsWith("#")) {
                        return `<a id=${tag}>${tag}</a>`;
                      } else {
                        return tag;
                      }
                    })
                    .join(" ");
                  reply.comment = replacedText;
                }
              });
              const firstCreatedBranchId = this.getfirstBranchId(
                "createdBranch",
                this.replies
              );
              const firstAssignedBranchId = this.getfirstBranchId(
                "assignedBranch",
                this.replies
              );
              for (let index = 0; index < this.replies?.length; index++) {
                this.replies[index].createdDtTmLocalFormatted = moment(
                  this.replies[index]?.createdDtTmLocal
                ).format("DD.MM.YY HH:mm");
                // const firstCreatedBranchId = res?.data?.collaborationEntry.entryType.sysValueId != 'INFO'?
                //   this.replies[0].createdBranch.branchId:this.replies[1]?.createdBranch?.branchId;
                // const firstAssignedBranchId = res?.data?.collaborationEntry.entryType.sysValueId != 'INFO'?
                //   this.replies[0]?.assignedBranch?.branchId:this.replies[1]?.assignedBranch?.branchId;
                let consignmentDetailsObj = {
                  fileType:
                    this.detailsData.collaborationEntry &&
                    this.detailsData.collaborationEntry.fileType &&
                    this.detailsData.collaborationEntry.fileType.longText
                      ? this.detailsData.collaborationEntry.fileType.longText
                      : "",
                  fromBranch:
                    this.replies[index] && (this.replies[index].createdBranch
                      ? this.replies[index].createdBranch.branchId
                      : "") + (this.replies[index].createdBranch?.country != null
                        ? " " + this.replies[index].createdBranch?.country
                        : "") + (this.replies[index].createdBranch?.city != null
                          ? " - " + this.replies[index].createdBranch?.city
                          : ""),
                  toBranch:
                    this.replies[index] && (this.replies[index].assignedBranch
                      ? this.replies[index].assignedBranch?.branchId
                      : "") + (this.replies[index].assignedBranch?.country != null
                        ? " " + this.replies[index].assignedBranch?.country
                        : "") + (this.replies[index].assignedBranch?.city != null
                          ? " - " + this.replies[index].assignedBranch?.city
                          : ""),
                  fromBranchId:
                    this.replies[index] && (this.replies[index].createdBranch ? this.replies[index].createdBranch.branchId : ""),
                  fromToBranchId:
                    this.replies[index] && (this.replies[index].assignedBranch ? this.replies[index].assignedBranch?.branchId : ""),
                };
                // if (this.collaborationFileType == "12041") {
                if (this.replies[index].assignedBranch !== null) {
                  if (this.replies.length === 1) {
                    // this.setRefrenceToBranch(consignmentDetailsObj);
                    this.collaborationConsigmentDetails.push(
                      consignmentDetailsObj
                    );
                  } else {
                    if (this.replies.length > 1) {
                      if (this.collaborationConsigmentDetails.length < 2) {
                        if (
                          this.replies[index].assignedBranch?.branchId ==
                            firstAssignedBranchId ||
                          this.replies[index].assignedBranch?.branchId ==
                            firstCreatedBranchId
                        ) {
                          this.collaborationConsigmentDetails = [];
                          // this.setRefrenceToBranch(consignmentDetailsObj);
                          this.collaborationConsigmentDetails.push(
                            consignmentDetailsObj
                          );
                        } else {
                          this.collaborationConsigmentDetails.push(
                            consignmentDetailsObj
                          );
                        }
                      } else {
                        const lastConsignmentDetails =
                          this.collaborationConsigmentDetails[
                            this.collaborationConsigmentDetails.length - 1
                          ];
                        if (
                          this.replies[index].assignedBranch?.branchId ==
                            lastConsignmentDetails.fromBranchId ||
                          this.replies[index].assignedBranch?.branchId ==
                            lastConsignmentDetails.toBranchId
                        ) {
                          this.collaborationConsigmentDetails.splice(
                            this.collaborationConsigmentDetails.length - 1,
                            1,
                            consignmentDetailsObj
                          );
                        } else {
                          this.collaborationConsigmentDetails.push(
                            consignmentDetailsObj
                          );
                        }
                      }
                    }
                  }
                }
                // } else {
                //   this.collaborationConsigmentDetails.push(
                //     consignmentDetailsObj
                //   );
                // }
              }
            }
            this.createdDept =
              this.detailsData.collaborationEntry?.createdDepartment?.sysValueId;
            this.assignDept =
              this.detailsData.collaborationEntry?.assignedDepartment?.sysValueId;
            this.getUserSubscriptionLists(this.replies);
            this.detailsData.commentHTML =
              this.detailsData?.collaborationEntry.comment;
            this.detailsData.collaborationEntry.createdDtTmLocalFormat = moment(
              this.detailsData?.collaborationEntry?.createdDtTmLocal
            ).format("DD.MM.YY HH:mm");
            this.detailsData.collaborationEntry.modifiedDtTmLocalFormat =
              moment(this.detailsData?.objectInfo?.dbChangedDtTm).format(
                "DD.MM.YY HH:mm"
              );
            this.detailsData["createdBranch.nametooltip"] = res?.data
              ?.createdBranch?.name
              ? res?.data?.createdBranch?.name
              : "";
            this.detailsData["createdBranch.countrytooltip"] =
              (res?.data?.createdBranch?.country
                ? res?.data?.createdBranch?.country
                : "") +
              (res?.data?.createdBranch?.zipCode
                ? " - " + res?.data?.createdBranch?.zipCode
                : "") +
              (res?.data?.createdBranch?.city
                ? "  " + res?.data?.createdBranch?.city
                : "");

            this.detailsData["assignedBranch.assignedNametooltip"] = res?.data
              ?.collaborationEntry?.assignedBranch?.name
              ? res?.data?.collaborationEntry?.assignedBranch?.name
              : "";
            this.detailsData["assignedBranch.assignedCountrytooltip"] =
              (res?.data?.collaborationEntry?.assignedBranch?.country
                ? res?.data?.collaborationEntry?.assignedBranch?.country
                : "") +
              (res?.data?.collaborationEntry?.assignedBranch?.zipCode
                ? " - " + res?.data?.collaborationEntry?.assignedBranch?.zipCode
                : "") +
              (res?.data?.collaborationEntry?.assignedBranch?.city
                ? "  " + res?.data?.collaborationEntry?.assignedBranch?.city
                : "");
            this.setReplyFormData();
            if (
              this.detailsData.collaborationEntry.entryStatus.longText ==
              "closed"
            ) {
              this.isClose = true;
              this.userTyping = [];
            }else{
            this.isClose = false;
          }
            this.checkCloseStatus();
            this.getAllRepliesOptions();
            loaderService.setLoader(false);
          }
        })
        .catch((err) => {
          loaderService.setLoader(false);
          console.log("loadcollborationbyid:", err);
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 2000,
          });
          addTrace(err, 4);
        });
    },

    // get latest data on clicked back button
    getLatestData: function () {
      this.getCollaborationById(
        this.selectedRowData["_id"],
        this.translateLanguageCode
      );
    },
    //set data in deatilsData and replies
    dataFormate: function (res) {
      if (res) {
        this.detailsData = res;
        this.replies = res?.collaborationEntry.replies;
        if (this.replies && this.replies.length != 0) {
          for (let index = 0; index < this.replies?.length; index++) {
            this.replies[index].createdDtTmLocalFormatted = moment(
              this.replies[index]?.createdDtTmLocal
            ).format("DD.MM.YY HH:mm");
          }
        }
        this.getUserSubscriptionLists(this.replies);
        this.detailsData.commentHTML =
          this.detailsData?.collaborationEntry.comment;
        this.detailsData.collaborationEntry.createdDtTmLocalFormat = moment(
          this.detailsData?.collaborationEntry?.createdDtTmLocal
        ).format("DD.MM.YY HH:mm");
        this.detailsData.collaborationEntry.modifiedDtTmLocalFormat = moment(
          this.detailsData?.objectInfo?.dbChangedDtTm
        ).format("DD.MM.YY HH:mm");
        this.detailsData["createdBranch.nametooltip"] = res?.createdBranch?.name
          ? res?.createdBranch?.name
          : "";
        this.detailsData["createdBranch.countrytooltip"] =
          (res?.createdBranch?.country ? res?.createdBranch?.country : "") +
          (res?.createdBranch?.zipCode
            ? " - " + res?.createdBranch?.zipCode
            : "") +
          (res?.createdBranch?.city ? "  " + res?.createdBranch?.city : "");

        this.detailsData["assignedBranch.assignedNametooltip"] = res
          ?.collaborationEntry?.assignedBranch?.name
          ? res?.collaborationEntry?.assignedBranch?.name
          : "";
        this.detailsData["assignedBranch.assignedCountrytooltip"] =
          (res?.collaborationEntry?.assignedBranch?.country
            ? res?.collaborationEntry?.assignedBranch?.country
            : "") +
          (res?.collaborationEntry?.assignedBranch?.zipCode
            ? " - " + res?.collaborationEntry?.assignedBranch?.zipCode
            : "") +
          (res?.data?.collaborationEntry?.assignedBranch?.city
            ? "  " + res?.collaborationEntry?.assignedBranch?.city
            : "");
            this.setReplyFormData();
            if (
               res?.collaborationEntry.entryStatus.longText == "closed"
              ) {
                 this.isClose = true;
                 this.userTyping = [];
                }else{
                this.isClose = false;
              }
        this.checkCloseStatus();
        loaderService.setLoader(false);
      }
    },

    //  get and set reply form data 
    setReplyFormData: function () {
      // this.replyForm["assignedBranch"] =
      //   this.detailsData?.collaborationEntry?.assignedBranch?.branchId;
      if (this.assignBranchCopy.length === 1) {
        this.replyForm["assignedBranch"] = this.assignBranchCopy[0].value;
      } else {
        let branch = this.assignBranchCopy.find((branch) => {
          return (
            branch.value ==
            this.detailsData?.collaborationEntry?.assignedBranch?.branchId
          );
        });
        if (branch) {
          if (
            this.replyForm["assignedBranch"] == null ||
            this.replyForm["assignedBranch"].value == null
          ) {
            this.replyForm["assignedBranch"] = branch;
          }
        } else {
          this.replyForm["assignedBranch"] = undefined;
        }
      }

      this.replyForm["assignedFunction"] =
        this.detailsData?.collaborationEntry?.assignedRole?.sysValueId;
      this.replyForm["assignedDepartment"] =
        this.detailsData?.collaborationEntry?.assignedDepartment?.sysValueId;
    },

    // get replies options
    getAllRepliesOptions: function () {
      this.repliesOptions = [
        {
          id: this.getTranslatedLanguage(60318, "ShortText"),
          value: this.getTranslatedLanguage(60318, "ShortText"),
          assignvalue: "INVESTIGATING",
          tooltip: this.getTranslatedLanguage(60387, "LongText"),
        },
        {
          id: this.getTranslatedLanguage(60319, "ShortText"),
          value: this.getTranslatedLanguage(60319, "ShortText"),
          assignvalue: "REASSIGN",
          tooltip: this.getTranslatedLanguage(60388, "LongText"),
        },
        {
          id: this.getTranslatedLanguage(60369, "ShortText"),
          value: this.getTranslatedLanguage(60369, "ShortText"),
          assignvalue: "ANSWERED",
          tooltip:
            this.getTranslatedLanguage(60389, "LongText") +
            " " +
            this.detailsData?.createdBranch?.branchId +
            " , " +
            this.detailsData?.collaborationEntry?.createdDepartment?.longText,
        },
      ];
    },

    //  send reply mesage in ticket
    sendReplyMessage: function () {
      const formData = new FormData();
      if (this.replyForm.replyMessage == null) {
        this.replyForm.replyMessage = "";
      }
      if (
        this.replyForm["replyMsgStatus"] == null &&
        this.replyForm.replyMessage == "" &&
        this.fileList.length > 0
      ) {
        this.replyForm["replyMsgStatus"] = "ANSWERED";
        this.selectAssignedOptionsValue = true;
      }
      loaderService.setLoader(true);
      this.replyForm.replyMessage = this.$sanitize(this.replyForm.replyMessage);
      this.replyForm.replyMessage = this.replyForm.replyMessage.replace(
        /<\/?span[^>]*>/g,
        ""
      );
      if (
        !this.$refs.replyForm.validate() &&
        this.replyForm["replyMsgStatus"] ==
          this.getTranslatedLanguage(60319, "ShortText").toUpperCase()
      ) {
        this.showAssignedOptions = true;
        this.alertPopup = false;
        loaderService.setLoader(false);
        return;
      } else {
        let self = this;
        if (
          !self.selectAssignedOptionsValue &&
          self.replyForm["replyMsgStatus"] != "CLOSED"
        ) {
          loaderService.setLoader(false);
          return;
        }

        this.setSelectedBranchDept(function (resReply) {
          formData.append("createdBranch.branchId", resReply.createdBranch);
          formData.append(
            "createdRole.sysValueId",
            self.replyForm["replyMsgStatus"] == "CLOSED"
              ? ""
              : self.selectedRowData[
                  `collaborationEntry.createdRole.sysValueId`
                ] == undefined
              ? ""
              : self.selectedRowData[
                  `collaborationEntry.createdRole.sysValueId`
                ]
          );

          formData.append(
            "createdDepartment.sysValueId",
            self.assignDept ? self.assignDept : self.createdDept
          );
          formData.append(
            "comment",
            self.replyForm["replyMsgStatus"] == "CLOSED"
              ? ""
              : self.replyForm["replyMessage"]
          );

          for (let i = 0; i < self.fileList.length; i++) {
            formData.append("arrDocument", self.fileList[i].uploadedFile);
          }

          if (self.replyForm["replyMsgStatus"] == "REASSIGN") {
            formData.append(
              "assignedBranch.branchId",
              self.replyForm["replyMsgStatus"] == "CLOSED"
                ? ""
                : self.replyForm["assignedBranch"]?.value
                ? self.replyForm["assignedBranch"]?.value
                : self.replyForm["assignedBranch"]
            );
            formData.append(
              "assignedRole.sysValueId",
              self.replyForm["replyMsgStatus"] == "CLOSED"
                ? ""
                : self.replyForm["assignedFunction"]
                ? self.replyForm["assignedFunction"]
                : self.replyForm["assignedFunction"] == undefined
                ? ""
                : self.selectedRowData[
                    `collaborationEntry.createdRole.sysValueId`
                  ]
            );
            formData.append(
              "assignedDepartment.sysValueId",
              self.replyForm["replyMsgStatus"] == "CLOSED"
                ? ""
                : self.replyForm["assignedDepartment"]?.value
                ? self.replyForm["assignedDepartment"]?.value
                : self.replyForm["assignedDepartment"]
            );
            formData.append("entryStatus.sysValueId", "INVESTIGATING");
          } else if (
            self.replyForm["replyMsgStatus"] == "ANSWERED" ||
            self.replyForm["replyMsgStatus"] == "CLOSED"
          ) {
            formData.append(
              "assignedBranch.branchId",
              self.detailsData.createdBranch.branchId
            );
            formData.append(
              "assignedRole.sysValueId",
              self.replyForm["replyMsgStatus"] == "CLOSED"
                ? ""
                : self.selectedRowData[
                    `collaborationEntry.createdRole.sysValueId`
                  ] == undefined
                ? ""
                : self.selectedRowData[
                    `collaborationEntry.createdRole.sysValueId`
                  ]
            );
            formData.append(
              "assignedDepartment.sysValueId",
              self.detailsData.collaborationEntry.createdDepartment.sysValueId
            );
            formData.append(
              "entryStatus.sysValueId",
              self.replyForm["replyMsgStatus"]
            );
          } else {
            formData.append(
              "entryStatus.sysValueId",
              self.replyForm["replyMsgStatus"]
            );
          }
          sendReplyMessage(self.selectedRowData[`_id`], formData)
            .then((res) => {
              loaderService.setLoader(false);
              if (res?.status == 201 || res?.status == 200) {
                if (res.data.errorCode == 80116) {
                  const files = self.fileList;
                  const message = self.replyForm.replyMessage;
                  self.$refs.replyForm.reset();
                  self.fileList = files;
                  self.replyForm.replyMessage = message;
                  self.alertPopup = false;
                  Vue.notify({
                    group: "foo",
                    type: "error",
                    title: res.data.description,
                    duration: 8000,
                  });
                  this.setReplyFormData();
                  return;
                } else {
                  self.fileList = [];
                  self.getCollaborationById(
                    self.selectedRowData[`_id`],
                    self.translateLanguageCode
                  );
                  refreshCollaborationDetail.setRefreshCollaborationDetailById(
                    self.selectedRowData[`_id`]
                  );
                  self.$refs.replyForm.reset();
                  self.replyForm.replyMessage = null;
                  setTimeout(() => {
                    self.replyForm["assignedBranch"] =
                      res?.data?.collaborationEntry?.assignedBranch?.branchId;
                    self.replyForm["assignedFunction"] =
                      res?.data?.collaborationEntry?.assignedRole?.sysValueId;
                    self.replyForm["assignedDepartment"] =
                      res?.data?.collaborationEntry?.assignedDepartment?.sysValueId;
                  });
                  if (
                    res?.data?.collaborationEntry?.entryStatus?.sysValueId ==
                    "CLOSED"
                  ) {
                    self.isClose = true;
                    self.userTyping = [];
                  }
                  Vue.notify({
                    group: "foo",
                    type: "success",
                    title:
                      self.translationCode[self.$i18n.locale][60317]?.LongText,
                    duration: 4000,
                  });
                  self.showRichText = false;
                }
                self.alertPopup = false;
              }
            })
            .catch((err) => {
              Vue.notify({
                group: "foo",
                type: "error",
                title: self.translationCode[self.$i18n.locale][80111]?.LongText,
                duration: 4000,
              });
              addTrace(err, 4);
              loaderService.setLoader(false);
            });
          });
          this.invokeBroadcastActiveUserDataService(false);
      }
    },
    //reply form status change
    replyStatusChange: function () {
      if (this.replyForm.replyMsgStatus.toUpperCase() == "REASSIGN") {
        // this.showAssignedOptions = true;
        // this.disableClickButton = true;
        // if (
        //   (this.detailsData?.collaborationEntry?.assignedBranch?.branchId !==
        //     undefined) |
        //     null |
        //     "" &&
        //   (this.detailsData?.collaborationEntry?.assignedDepartment
        //     ?.sysValueId !==
        //     undefined) |
        //     null |
        //     ""
        // ) {
        //   this.setReplyFormData();
        //   this.disableClickButton = false;
        // }
        // bind(this.$refs.reassignOption, { value: this.closePopup });

        // console.log('this.showAssignedOptions: ', this.showAssignedOptions);
        if (this.showAssignedOptions == false) {
          this.setReplyFormData();
          this.showAssignedOptions = true;
          this.disableClickButton = true;
          bind(this.$refs.reassignOption, { value: this.closePopup });
          if (
            this.replyForm.assignBranch != undefined &&
            this.replyForm.assignedDepartment != null
          ) {
            this.disableClickButton = false;
          }
        }
      } else {
        this.showAssignedOptions = false;
        this.disableClickButton = false;
      }
      this.selectAssignedOptionsValue = true;
    },

    //on change text in vue2 editor
    onChangeText: function () {
      this.invokeBroadcastActiveUserDataService(true);
      if (this.replyForm?.replyMsgStatus?.toUpperCase() == "REASSIGN") {
        if (
          this.replyForm.assignedBranch != undefined &&
          this.replyForm.assignedDepartment != undefined
        ) {
          this.disableClickButton = this.replyForm.replyMessage ? false : true;
        } else {
          this.disableClickButton = true;
        }
      } else {
        this.disableClickButton = false;
      }
    },

    //when click out-side close message popup and reset the form
    closemessage: function (event) {
      event.stopPropagation();
      // this.fileList = [];
      // this.$refs.replyForm.reset();
      this.showRichText = false;
      this.invokeBroadcastActiveUserDataService(false);
      this.disabledClickbtn = true;
    },

    // when click on close button beside send buttton will clear that
    closemessageAndRemoveData: function (event) {
      event.stopPropagation();
      this.$refs.replyForm.reset();
      this.replyForm.replyMsgStatus = null;
      this.replyForm.replyMessage = null;
      this.fileList = [];
      this.showRichText = false;
      this.writeMessage = "";
      this.disableClickButton = true;
      this.invokeBroadcastActiveUserDataService(false);
    },

    //close reply assgned popup on click of close btn
    closePopup: function () {
      if (this.replyForm?.replyMsgStatus?.toUpperCase() == "REASSIGN") {
        if (
          this.replyForm.assignedBranch != undefined &&
          this.replyForm.assignedDepartment != undefined
        ) {
          this.disableClickButton =
            this.replyForm.replyMessage.length !== 0 ? false : true;
        } else {
          this.disableClickButton = true;
        }
      }
      unbind(this.$refs.reassignOption);
      this.showAssignedOptions = false;
    },

    //unbind the popup on click event of popup dropdown
    unbindClickOutSide: function () {
      unbind(this.$refs.reassignOption);
    },

    //bind the popup on blur event of popup dropdown
    bindClickOutSide: function () {
      bind(this.$refs.reassignOption, { value: this.closePopup });
    },

    //when click on single input function this enables rich text editor
    showRichTextfn: function (isShowRichText) {
      this.showRichText = isShowRichText ? isShowRichText : this.showRichText;
      if (
        this.showRichText == true &&
        this.$refs.editor &&
        this.$refs.editor["$refs"] != undefined
      ) {
        if (this.writeMessage && this.replyForm.replyMessage === "") {
          this.replyForm.replyMessage = this.writeMessage;
        }
        this.$nextTick(() => {
          this.$refs.editor["$refs"].quillContainer[
            "children"
          ][0].dataset.placeholder = this.getTranslatedLanguage(
            60321,
            "LongText"
          );
          if (!this.replyForm.replyMsgStatus) {
            this.$refs.editor["$refs"].quillContainer["children"][0].focus();
          }
        });
      }
    },
    //window click event
    windowClickEvent: function (event) {
      if (
        (event.target.classList &&
          event.target.classList.value.includes("v-label")) ||
        event.target.classList.value.includes("v-input") ||
        event.target.classList.value.includes("v-select") ||
        event.target.classList.value.includes("v-list-item") ||
        event.target.classList.value.includes("v-messages") ||
        event.target.classList.value.includes("select")
      ) {
        if (
          this.replyForm.replyMsgStatus != null &&
          this.replyForm.replyMsgStatus == "REASSIGN"
        ) {
          this.showAssignedOptions = true;
        } else {
          this.showAssignedOptions = false;
        }
      } else {
        this.showAssignedOptions = false;
      }
    },
    // set selected branch and department in reply form
    setSelectedBranchDept: function (callback) {
      let createdBranch = "";
      getBranchAndDepartment((res) => {
        if (res && res.status == 200) {
          let branch = this.createdBranches.find(
            (branch) => branch.entityNo == res.data.branchNo
          );
          if (branch) {
            createdBranch = branch.value;
            this.headerBranch = branch.entityNo;
          }
          var obj = {
            createdBranch: createdBranch,
          };

          if (callback) {
            return callback(obj);
          }
        }
      }).catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback();
      });
    },

    // load data on change translation language
    loadTranslatorLanguage: function () {
      loaderService.setLoader(true);
      loadTranslatorLanguage()
        .then((res) => {
          this.setLanguageDropDown.push(...res.data);
        })
        .catch((err) => {
          console.log("loadlanguage:", err);
          loaderService.setLoader(false);
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 2000,
          });
          addTrace(err, 4);
        });
    },

    // get all avalilable functions on the bases of file type
    getDataBasedOnFileType(func) {
      this.functionValues = [];
      this.departmentValues = [];
      loaderService.setLoader(true);
      getDataFromFileType(func.sysValueId)
        .then((res) => {
          if (res && res.status == 200) {
            this.filetypedata = res.data;
            if (res.data[0].branchFunctions.length > 0) {
              res?.data[0].branchFunctions.forEach((element) => {
                let obj = {
                  text: element.branchFunction.longText
                    ? element.branchFunction.longText
                    : element.branchFunction.shortText,
                  value: element.branchFunction.sysValueId,
                };
                this.functionValues.push(obj);
              });
            } else {
              this.functionValues = [];
            }
            if (res.data[0].departments.length > 0) {
              res?.data[0].departments.forEach((element) => {
                let obj = {
                  text: "",
                  value: "",
                  deptNo: element.sysValueNo,
                };
                (obj.text = element.longText
                  ? element.longText
                  : element.shortText),
                  (obj.value = element.sysValueId),
                  this.departmentValues.push(obj);
              });
            } else {
              this.departmentValues = [];
            }
            loaderService.setLoader(false);
          } else {
            loaderService.setLoader(false);
          }
        })
        .catch((err) => {
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          loaderService.setLoader(false);
          // if (callback) return callback();
        });
    },

    // set assign branch according to branch functions
    setAssignBranch(func) {
      if (this.detailsData.branchFunction) {
        let data = this.detailsData.branchFunction.find(
          (obj) => Object.keys(obj)[0] === func.value
        );
        if (data) {
          let branch = this.assignBranchCopy.find(
            (branch) => branch.value == Object.values(data)[0]
          );
          if (branch) {
            this.replyForm.assignedBranch = {
              text: branch.text,
              value: branch.value,
            };
          } else {
            this.replyForm.assignedBranch = null;
          }
        }
      }
      let department = this.filetypedata[0].branchFunctions.find(
        (dept) => dept.branchFunction.sysValueId == func.value
      );
      if (department) {
        this.replyForm.assignedDepartment = {
          text: department.defaultDepartment.shortText,
          value: department.defaultDepartment.sysValueId,
        };
      }
    },
    //remove uploaded image
    removeImage(item) {
      for (let i = this.fileList.length; i--; ) {
        if (this.fileList[i] === item) {
          this.fileList.splice(i, 1);
        }
      }
    },
    //file input
    handleFileInput: function (e) {
      // handleFileInput(e) {
      let fileData = e.target.files;
      let self = this;
      self.prepareFileArray(fileData, function (data) {
        self.fileList.push(...data);
      });
    },
    // file input using paste and drag and drop events
    handleFileInputOnDropAndPaste(files){
      const fileData = files;
      const self = this;
      self.prepareFileArray(fileData, function (data) {
        self.fileList.push(...data);
      });
    },
    //check file extention
    checkFileExtention(item, file_ext) {
      if (item.previewImage != null) {
        if (!file_ext.includes("all")) {
          return file_ext.includes(item.fileExtention.toLowerCase())
            ? true
            : false;
        } else {
          return file_ext.includes(item.fileExtention.toLowerCase())
            ? false
            : true;
        }
      } else {
        return false;
      }
    },
    // prepare file array 
    prepareFileArray: function (fileData, callback) {
      let array = [];
      let self = this;
      // let self=this;
      function fileArray(i) {
        if((array.length + self.fileList.length) <= 5){
          if (i < fileData.length) {
            const image = fileData[i];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (e) => {
              let params = {
                fileExtention: fileData[i].name.split(".").pop(),
                uploadedFile: fileData[i],
                previewImage: e.target.result,
              };
              if(fileData[i].size <= 2000000){
                array.push(params);
                fileArray(i + 1);
              }else{
                Vue.notify({
                  group: "foo",
                  type: "error",
                  title: self.translationCode[self.$i18n.locale][60351]?.LongText,
                  duration: 5000,
                });
              }
            };
          } else {
            return callback(array);
          }
        }
        else{
          Vue.notify({
            group: "foo",
            type: "error",
            title: self.translationCode[self.$i18n.locale][60351]?.LongText,
            duration: 5000,
          });
        }
      }
      fileArray(0);
    },

    // get consignment number by id 
    getConsignmentNumber: async function (objectId) {
      await getConsignmentMenifestNumber(objectId).then((res) => {
        if (res) {
          this.setRefrenceToBranch(
            this.collaborationConsigmentDetails,
            res.data
          );
        }
      });
    },

    // get pick up order number by id
    getPickUpOrderNumber: async function (objectId) {
      await getPickUpOrderMenifestNumber(objectId).then((res) => {
        if (res) {
          this.setRefrenceToBranch(
            this.collaborationConsigmentDetails,
            res.data
          );
        }
      });
    },

    // set refrence number to same branch
    setRefrenceToBranch: function (collaborationConsigmentDetails, data) {
      if (collaborationConsigmentDetails.length > 0 && data) {
        if (data && data.ShippingBranch && data.ShippingBranch.BranchId) {
          if (
            collaborationConsigmentDetails[0].fromBranchId ===
            data.ShippingBranch.BranchId
          ) {
            this.fromBranchRefrence = data.ShippingBranchRef;
          }
          if (
            collaborationConsigmentDetails[0].toBranchId ===
            data.ShippingBranch.BranchId
          ) {
            this.toBanchRefrence = data.ShippingBranchRef;
          }
        }
        if (data && data.ReceivingBranch && data.ReceivingBranch.BranchId) {
          if (
            collaborationConsigmentDetails[0].fromBranchId ===
            data.ReceivingBranch.BranchId
          ) {
            this.fromBranchRefrence = data.ReceivingBranchRef;
          }
          if (
            collaborationConsigmentDetails[0].toBranchId ===
            data.ReceivingBranch.BranchId
          ) {
            this.toBanchRefrence = data.ReceivingBranchRef;
          }
        }
        if(data && data.PickupOrderNo){
          this.fromBranchRefrence = data.PickupOrderNo;
          this.toBanchRefrence = data.PickupOrderNo;
        }
      }
    },

    // check user subscription stetus
    userSubscription: function () {
      loaderService.setLoader(true);
      if (this.replyForm.replyMsgStatus == "REASSIGN") {
        let obj = {
          createdBranch: this.detailsData.createdBranch.branchId,
          assignedBranch:
            this.replyForm.assignedBranch.value == undefined
              ? this.replyForm.assignedBranch
              : this.replyForm.assignedBranch.value,
          department:
            this.replyForm.assignedDepartment.value == undefined
              ? this.replyForm.assignedDepartment
              : this.replyForm.assignedDepartment.value,
        };
        checkSubscribeStatus(obj)
          .then((res) => {
            loaderService.setLoader(false);
            if (res.data.length > 0) {
              this.alertPopup = false;
              this.sendReplyMessage();
            } else {
              this.alertPopup = true;
            }
          })
          .catch((err) => {
            Vue.notify({
              group: "foo",
              type: "error",
              title: `${err}`,
              duration: 4000,
            });
            addTrace(err, 4);
            loaderService.setLoader(false);
          });
      } else {
        this.sendReplyMessage();
      }
    },

    // close alert popup on close button 
    closeAlertPopup: function () {
      this.alertPopup = false;
    },

    //get first assign branch
    getfirstBranchId(data, replies) {
      let branchId = "";
      for (let index = 0; index < replies?.length; index++) {
        if (replies[index]?.assignedBranch) {
          branchId = replies[index]?.[data]?.branchId;
          break;
        }
      }
      return branchId;
    },

    // add hover effect on hover radio button label
    setHover() {
      if (document.querySelector(".v-radio > .v-label:hover") != null) {
        var selectedhover = document.querySelectorAll(
          ".v-radio > .v-label:hover"
        );
        var radiogroup = document.querySelectorAll(".v-radio ");
        radiogroup.forEach((radio) => {
          if (selectedhover[0].innerHTML == radio.childNodes[1].innerHTML) {
            radio.childNodes[0].classList.add("nav-link-hover");
          } else {
            radio.childNodes[0].classList.remove("nav-link-hover");
          }
        });
      }
    },

    // remove hover effect on hover radio button label
    removeHover() {
      var radiogroup = document.querySelectorAll(".v-radio ");
      radiogroup.forEach((radio) => {
        radio.childNodes[0].classList.remove("nav-link-hover");
      });
    },

    //  redirect to collaboration page cliked on tag and search that tag 
    redirectToHomePageWithSearchTag(tags, event) {
      if (event.target.id) {
        tags.forEach((tag) => {
          if (tag === event.target.id) {
            this.$emit("search", tag);
            this.$emit("back", "");
          }
        });
      }
    },
    // invoke BroadcastActiveUserData service
    invokeBroadcastActiveUserDataService(typingValue){
    let invokeobj = {
      ticketId : this.selectedRowData["_id"] ,
      userId : sessionStorage.getItem("emailAddress") ,
      isTyping : typingValue
    }
    notificationConnection.invoke('BroadcastActiveUserData',invokeobj);
    },

    // drop file in div and upload files
    dropFile(event) {
      event.preventDefault();
      event.stopPropagation();
        if (this.showRichText) {
          if (event.dataTransfer.files.length > 5) {
            Vue.notify({
              group: "foo",
              type: "error",
              title: self.translationCode[self.$i18n.locale][60351]?.LongText,
              duration: 5000,
            });
            this.showRichText = false;
            this.invokeBroadcastActiveUserDataService(false);
          } else {
            this.invokeBroadcastActiveUserDataService(true);
            this.handleFileInputOnDropAndPaste(event.dataTransfer.files);
          }
        }
      this.displayOverlay = false;
    },
    
    // not open or download on drag and drop
    notOpenOrDownloadFile(event){
      event.preventDefault();
      event.stopPropagation();
      this.displayOverlay = false;
    },
  },

  // before detroy
  beforeDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.invokeBroadcastActiveUserDataService(false);
    notificationConnection.stop()
  },
};
</script>

<style scoped>
.details {
  display: flex;
  margin: 0 !important;
  width: 100%;
}

.holder-main-box {
  background-color: #fff4e1;
  padding: 15px;
  border-radius: 4px !important;
  overflow-y: hidden;
  max-height: calc(100vh - 450px);
  overflow-y: auto;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.holder-main-box .space-left {
  padding-left: 26px;
}

.image-inner-wrapper p {
  margin-bottom: 5px !important;
  display: flex;
  flex-wrap: wrap;
}

.msg-title {
  justify-content: space-between;
}

.mr-10 {
  margin-right: 10px;
}

.msg-wrapper-list,
.msg-wrapper-list-me {
  padding: 2px 10px;
  background: #fff4e1;
  inline-size: fit-content;
  border-radius: 5px;
  color: #000;
  margin: 12px 0px;
  position: relative;
  padding-bottom: 5px;
}

.replies-wrapper {
  border-bottom: 1px solid #dedede;
  padding: 10px;
  margin-bottom: 10px;
}

.user_name {
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.text_data {
  font-size: 12px;
  color: #666666;
}

.time_stamp {
  font-weight: 100;
  font-size: 11px;
}

/* replies form start */
.write_message {
  width: 100%;
  position: absolute;
  bottom: 3px;
  left: 0;
  min-height: 100px;
  padding: 10px;
  color: #999;
  background: #fff;
}

.reply {
  height: 100%;
  border: 1px solid #dddddd;
  border-radius: 10px;
  background: #fff4e1;
}

/* replies form end */
/* replies section start*/
.shadow-box {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 2px 6px 4px rgb(0 0 0 / 14%),
    0px 1px 6px 0px rgb(0 0 0 / 12%);
}

.chat_pop {
  position: relative;
  background-color: white;
  border-radius: 4px !important;
  padding: 6px 10px;
  height: calc(100vh - 400px);
}

.chat_pop_message {
  position: relative;
  background-color: white;
  border-radius: 4px !important;
  padding: 6px 10px;
  height: calc(100vh - 730px) !important;
}

.header {
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  color: #000000;
  padding-bottom: 20px;
}

.thread-wrapper,
.thread-wrapper-simple {
  height: calc(100vh - 450px);
  overflow-y: auto;
  overflow-x: hidden;
}

.thread-wrapper-simple_message {
  margin-top: 7px;
  height: calc(100vh - 623px);
  overflow-y: auto;
  overflow-x: hidden;
}

.holder-inner-scroll {
  max-height: calc(100vh - 277px);
  /* overflow-y: auto; */
}

.c-holder-inner-scroll {
  overflow-y: hidden;
}

.c-holder-inner-scroll .image-inner-wrapper {
  max-height: calc(100vh - 316px);
  overflow-y: auto;
}

.comment-inner-img {
  display: flex;
  flex-direction: column;
}

.close-btn {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.setclose-btn {
  background-color: #ffa200 !important;
  border-color: #ffa200 !important;
  color: #fff !important;
}

.thread-wrapper-simple_message .top-space {
  margin-top: 7px;
  height: calc(100vh - 717px);
}

@media screen and (max-width: 1880px) {
  .thread-wrapper-simple_message {
    margin-top: 7px;
    height: calc(100vh - 546px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .thread-wrapper-simple_message .top-space {
    margin-top: 7px;
    height: calc(100vh - 542px);
  }
}

@media screen and (max-width: 1350px) {
  .thread-wrapper-simple_message {
    height: calc(100vh - 546px);
    margin-top: 7px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .thread-wrapper-simple_message .top-space {
    margin-top: 7px;
    height: calc(100vh - 542px);
  }
}

@media screen and (max-width: 1100px) {
  .details .col-6 {
    max-width: 100% !important;
  }

  .thread-wrapper-simple_message {
    height: calc(100vh - 546px);
    margin-top: 7px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .thread-wrapper-simple_message .top-space {
    margin-top: 7px;
    height: calc(100vh - 546px);
  }
}

@media screen and (max-width: 700px) {
  .details {
    flex-direction: column !important;
    height: 80vh;
    overflow-y: auto;
  }

  .thread-wrapper-simple_message {
    height: calc(100vh - 546px);
    margin-top: 7px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .thread-wrapper-simple_message .top-space {
    margin-top: 7px;
    height: calc(100vh - 542px);
  }
}

.toggle-switch {
  position: absolute;
  right: 20px;
  font-size: 14px;
  margin-right: -10px;
}

.toggle-switch .v-input--selection-controls {
  margin: 0px !important;
  padding: 0px !important;
}

.thread-wrapper-simple .replies-wrapper {
  border: none !important;
  padding: 0px 10px !important;
}

.thread-wrapper-simple .time_stamp {
  text-align: right !important;
}

.thread-wrapper-simple .image-inner-wrapper p {
  margin: 5px 0px !important;
}

.thread-wrapper-simple_message .replies-wrapper {
  border: none !important;
  padding: 0px 10px !important;
}

.thread-wrapper-simple_message .time_stamp {
  text-align: right !important;
}

.thread-wrapper-simple_message .image-inner-wrapper p {
  margin: 5px 0px !important;
}

.msg-wrapper-list,
.msg-wrapper-list-me {
  margin: 15px 0px;
}

.msg-wrapper-list-left,
.msg-wrapper-list-right {
  width: 70%;
  min-width: 340px;
}

.msg-wrapper-list-me {
  margin-left: auto;
}

.msg-wrapper-list {
  background-color: #e9e9e9 !important;
}

.msg-wrapper-list-me::after,
.msg-wrapper-list::before {
  content: "";
  height: 18px;
  width: 18px;
  position: absolute;
  top: -9px;
  border-radius: 5px;
  clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
}

.msg-wrapper-list::before {
  background-color: #e9e9e9;
  left: -6px;
  transform: rotate(45deg);
}

.msg-wrapper-list-me::after {
  background-color: #fff4e1;
  right: -6px;
  transform: rotate(-45deg);
}

/* replies section start*/
.thread-wrapper-simple.top-space {
  margin-top: 7px;
}

.thread-wrapper.c-thread-height {
  margin-top: 7px;
}

.editor-btn-group {
  /* position: relative; */
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 6px;
}

.editor-btn-group .close-icon,
.editor-btn-group,
.send-icon {
  /* top: -45px !important; */
}

.editor-option {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 0;
  padding-bottom: 0;
}

.editor-option .col {
  padding: 0;
}

.cc-close-icon {
  position: absolute !important;
  right: 15px !important;
  top: -25px !important;
}

.language-select-wrapper {
  position: absolute;
  top: 10px;
  right: 20px;
}

.c-card-radio .radio {
  margin-right: 25px !important;
}

.dropdown-list-wrapper {
  top: -230px !important;
}

.c-img-wrapper:hover .overlay-img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000069;
}

.c-img-close-wrapper:hover .overlay-img-close-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  background: #00000069;
}

.c-img-wrapper:hover .overlay-img {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 24px;
}

.c-img-close-wrapper:hover .overlay-img-close {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  color: #ffffff;
  font-size: 24px;
}

.overlay-img {
  font-size: 0px;
}

.c-img-wrapper:hover .overlay-img v-icon {
  margin: 0 10px;
}

.c-img-close-wrapper:hover .overlay-img-close v-icon {
  margin: 0 10px;
}

.c-img-wrapper {
  position: relative;
}

.c-img-close-wrapper {
  position: relative;
}

.imageLoop {
  width: 65px;
  /* height: 65px; */
  margin-bottom: 10px;
  position: relative;
}

.c-img {
  display: flex;
  flex-wrap: wrap;
}

.imageLoopData {
  display: inline-block;
  height: 100%;
  position: relative;
  border: 2px dashed #dee2e6 !important;
  cursor: pointer;
}

.c-img-wrapper:hover .overlay-img-wrapper {
  display: block;
}

.overlay-img-wrapper {
  display: none;
}

.c-img-close-wrapper:hover .overlay-img-close-wrapper {
  display: block;
}

.overlay-img-close-wrapper {
  display: none;
}

.close-alert-btn {
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  color: #fff !important;
}

.save-btn {
  background-color: #ffa200 !important;
  border-color: #ffa200 !important;
  color: #fff !important;
}
.image-inner-wrapper >>> p {
  margin-bottom: 0px;
}
.overlay-card {
  height: 100%;
  width: 100%;
  background-color: #0000007a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000 !important;
  transition: 0.5s;
  display: flex; 
  justify-content: center;
}
.typing-note{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 200px;
}
.typing-note-close-btn{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 160px;
}
.flex-between-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.custom-between-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
</style>
