var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-wrapper"},[_c('v-card',{staticClass:"c-card-wrapper"},[(_vm.showSidebarLoader)?_c('vue-loaders-ball-beat',{attrs:{"color":"black"}}):_vm._e(),(_vm.isLoaded)?_c('div',[_c('div',{staticClass:"row align-items-center m-1 "},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',{staticStyle:{"font-weight":"900"}},[_vm._v(_vm._s(_vm._f("translate")("Aggregated By","60344.ShortText")))])])])]}}],null,false,2496290662)},[_c('span',[_c('div',{staticStyle:{"font-weight":"900"}},[_vm._v(_vm._s(_vm._f("translate")("Aggregated By","60344.LongText")))])])]),_c('div',{staticClass:"col-md-6 p-0"},[_c('v-select',{attrs:{"items":_vm.timePeriodData},on:{"change":_vm.timePeriodHandler},model:{value:(_vm.aggregatedData),callback:function ($$v) {_vm.aggregatedData=$$v},expression:"aggregatedData"}})],1),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12 pr-0"},[_c('v-icon',{on:{"click":function($event){return _vm.getSidePanelGraphData()}}},[_vm._v(" mdi-refresh ")])],1)],1),_c('div',{staticClass:"c-card-inner-wrapper"},[_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(_vm._s(_vm._f("translate")("Created Entries","60334.ShortText")))])])])]}}],null,false,1602121513)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Created Entries","60334.LongText")))])])]),(_vm.createdEntriesValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"bar","options":_vm.options,"series":_vm.createdEntriesValue,"height":"80"}})],1)]),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.createdEntriesLastValue))])])],1),_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(_vm._s(_vm._f("translate")("Closed Entries","60335.ShortText")))])])])]}}],null,false,113030074)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Closed Entries","60335.LongText")))])])]),(_vm.closedEntriesValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"bar","options":_vm.options,"series":_vm.closedEntriesValue,"height":"80"}})],1)]),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.closedEntriesLastValue))])])],1),_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(_vm._s(_vm._f("translate")("Minutes Closed","60341.ShortText")))])])])]}}],null,false,1287250870)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Minutes Closed","60341.LongText")))])])]),(_vm.averageMinutesClosedValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"line","options":_vm.options,"series":_vm.averageMinutesClosedValue,"height":"80"}})],1)]),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.averageMinutesClosedLastValue))])])],1),_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(" "+_vm._s(_vm._f("translate")("Assigned Entries","60336.ShortText"))+" ")])])])]}}],null,false,101992715)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Assigned Entries","60336.LongText")))])])]),(_vm.assignedEntriesValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"bar","options":_vm.options,"series":_vm.assignedEntriesValue,"height":"100"}})],1)]),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.assignedEntriesLastValue))])])],1),_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(_vm._s(_vm._f("translate")("Created Replies","60337.ShortText")))])])])]}}],null,false,1979761644)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Created Replies","60337.LongText")))])])]),(_vm.createdRepliesValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"bar","options":_vm.options,"series":_vm.createdRepliesValue,"height":"80"}})],1)]),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.createdRepliesLastValue))])])],1),_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(_vm._s(_vm._f("translate")("Investigation","60338.ShortText")))])])])]}}],null,false,1392822039)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Investigation","60338.LongText")))])])]),(_vm.investigationsValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"bar","options":_vm.options,"series":_vm.investigationsValue,"height":"80"}})],1)]),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.investigationsLastValue))])])],1),_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(_vm._s(_vm._f("translate")("Reassigned","60339.ShortText")))])])])]}}],null,false,988020801)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Reassigned","60339.LongText")))])])]),(_vm.reassignsValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"bar","options":_vm.options,"series":_vm.reassignsValue,"height":"80"}})],1)]),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.reassignsLastValue))])])],1),_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(_vm._s(_vm._f("translate")("Answered","60340.ShortText")))])])])]}}],null,false,130804645)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Answered","60340.LongText")))])])]),(_vm.answersValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"bar","options":_vm.options,"series":_vm.answersValue,"height":"80"}})],1)]),_c('div',{staticClass:"col-md-2 col-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.answersLastValue))])])],1),_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(" "+_vm._s(_vm._f("translate")("Minutes First Reply","60342.ShortText"))+" ")])])])]}}],null,false,4108562351)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Minutes First Reply","60342.LongText")))])])]),(_vm.averageMinutesFirstReplyValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"line","options":_vm.options,"series":_vm.averageMinutesFirstReplyValue,"height":"80"}})],1)]),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.averageMinutesFirstReplyLastValue))])])],1),_c('div',{staticClass:"row align-items-center m-0"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-4 pr-0 f-12"},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('h6',[_vm._v(_vm._s(_vm._f("translate")("Minutes Answered","60343.ShortText")))])])])]}}],null,false,1337784859)},[_c('span',[_c('div',[_vm._v(_vm._s(_vm._f("translate")("Minutes Answered","60343.LongText")))])])]),(_vm.averageMinutesAnsweredValue[0].data.length === 0)?_c('div',[_vm._v(" No Record Found ")]):_c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('apexcharts',{attrs:{"type":"line","options":_vm.options,"series":_vm.averageMinutesAnsweredValue,"height":"80"}})],1)]),_c('div',{staticClass:"col-md-2 pl-0 text-right f-12"},[_c('h6',[_vm._v(_vm._s(_vm.averageMinutesAnsweredLastValue))])])],1)])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }