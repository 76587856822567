<template>
  <div class="sidebar-wrapper">
    <v-card class="c-card-wrapper">
      <vue-loaders-ball-beat
        v-if="showSidebarLoader"
        color="black"
      ></vue-loaders-ball-beat>
      <div v-if="isLoaded">
       
      <div class="row align-items-center m-1 ">      
        <v-tooltip bottom max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12" >
              <span v-bind="attrs" v-on="on">
                <h6 style="font-weight: 900">{{ "Aggregated By" | translate("60344.ShortText") }}</h6>
              </span>
            </div>
          </template>
          <span>
            <div style="font-weight: 900">{{ "Aggregated By" | translate("60344.LongText") }}</div>
          </span>
        </v-tooltip>
        <div class="col-md-6 p-0">
            <v-select
              :items="timePeriodData"
              @change="timePeriodHandler"
              v-model="aggregatedData"
            ></v-select>       
        </div>
        <div class="col-md-2 pl-0 text-right f-12 pr-0">
            <v-icon  @click="getSidePanelGraphData()">
             mdi-refresh
           </v-icon>     
        </div>
      </div>
      <div class="c-card-inner-wrapper">
      <div class="row align-items-center m-0">
        <v-tooltip bottom max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12">
              <span v-bind="attrs" v-on="on">
                <h6>{{ "Created Entries" | translate("60334.ShortText") }}</h6>
              </span>
            </div>
          </template>
          <span>
            <div>{{ "Created Entries" | translate("60334.LongText") }}</div>
          </span>
        </v-tooltip>
        <div v-if="createdEntriesValue[0].data.length === 0">
          No Record Found
        </div>
        <div class="col-md-6 p-0" v-else>
          <div style="width: 100%">
            <apexcharts
              type="bar"
              :options="options"
              :series="createdEntriesValue"
              height="80"
            >
            </apexcharts>
          </div>
        </div>
        <div class="col-md-2 pl-0 text-right f-12">
          <h6>{{ createdEntriesLastValue }}</h6>
        </div>
      </div>
      <div class="row align-items-center m-0">
        <v-tooltip bottom max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12">
              <span v-bind="attrs" v-on="on">
                <h6>{{ "Closed Entries" | translate("60335.ShortText") }}</h6>
              </span>
            </div>
          </template>
          <span>
            <div>{{ "Closed Entries" | translate("60335.LongText") }}</div>
          </span>
        </v-tooltip>
        <div v-if="closedEntriesValue[0].data.length === 0">
          No Record Found
        </div>
        <div class="col-md-6 p-0" v-else>
          <div style="width: 100%">
            <apexcharts
              type="bar"
              :options="options"
              :series="closedEntriesValue"
              height="80"
            >
            </apexcharts>
          </div>
        </div>
        <div class="col-md-2 pl-0 text-right f-12">
          <h6>{{ closedEntriesLastValue }}</h6>
        </div>
      </div>

      <div class="row align-items-center m-0">
        <v-tooltip bottom max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12">
              <span v-bind="attrs" v-on="on">
                <h6>{{ "Minutes Closed" | translate("60341.ShortText") }}</h6>
              </span>
            </div>
          </template>
          <span>
            <div>{{ "Minutes Closed" | translate("60341.LongText") }}</div>
          </span>
        </v-tooltip>
        <div v-if="averageMinutesClosedValue[0].data.length === 0">
            No Record Found
        </div>
        <div class="col-md-6 p-0" v-else>
          <div style="width: 100%">
            <apexcharts
              type="line"
              :options="options"
              :series="averageMinutesClosedValue"
              height="80"
            >
            </apexcharts>
          </div>
        </div>
        <div class="col-md-2 pl-0 text-right f-12">
          <h6>{{ averageMinutesClosedLastValue }}</h6>
        </div>
      </div>

      <div class="row align-items-center m-0">
          <v-tooltip bottom max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <div class="col-md-4 pr-0 f-12">
                <span v-bind="attrs" v-on="on">
                  <h6>
                    {{ "Assigned Entries" | translate("60336.ShortText") }}
                  </h6>
                </span>
              </div>
            </template>
            <span>
              <div>{{ "Assigned Entries" | translate("60336.LongText") }}</div>
            </span>
          </v-tooltip>
          <div v-if="assignedEntriesValue[0].data.length === 0">
            No Record Found
          </div>
          <div class="col-md-6 p-0" v-else>
            <div style="width: 100%">
              <apexcharts
                type="bar"
                :options="options"
                :series="assignedEntriesValue"
                height="100"
              >
              </apexcharts>
            </div>
          </div>
          <div class="col-md-2 pl-0 text-right f-12">
            <h6>{{ assignedEntriesLastValue }}</h6>
          </div>
        </div>

      <div class="row align-items-center m-0">
        <v-tooltip bottom max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12">
              <span v-bind="attrs" v-on="on">
                <h6>{{ "Created Replies" | translate("60337.ShortText") }}</h6>
              </span>
            </div>
          </template>
          <span>
            <div>{{ "Created Replies" | translate("60337.LongText") }}</div>
          </span>
        </v-tooltip>
         <div v-if="createdRepliesValue[0].data.length === 0">
            No Record Found
        </div>
        <div class="col-md-6 p-0" v-else>
          <div style="width: 100%">
            <apexcharts
              type="bar"
              :options="options"
              :series="createdRepliesValue"
              height="80"
            >
            </apexcharts>
          </div>
        </div>
        <div class="col-md-2 pl-0 text-right f-12">
          <h6>{{ createdRepliesLastValue }}</h6>
        </div>
      </div>
      <div class="row align-items-center m-0">
        <v-tooltip bottom max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12">
              <span v-bind="attrs" v-on="on">
                <h6>{{ "Investigation" | translate("60338.ShortText") }}</h6>
              </span>
            </div>
          </template>
          <span>
            <div>{{ "Investigation" | translate("60338.LongText") }}</div>
          </span>
        </v-tooltip>
        <div v-if="investigationsValue[0].data.length === 0">
            No Record Found
        </div>
        <div class="col-md-6 p-0" v-else>
          <div style="width: 100%">
            <apexcharts
              type="bar"
              :options="options"
              :series="investigationsValue"
              height="80"
            >
            </apexcharts>
          </div>
        </div>
        <div class="col-md-2 pl-0 text-right f-12">
          <h6>{{ investigationsLastValue }}</h6>
        </div>
      </div>
      <div class="row align-items-center m-0">
        <v-tooltip bottom max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12">
              <span v-bind="attrs" v-on="on">
                <h6>{{ "Reassigned" | translate("60339.ShortText") }}</h6>
              </span>
            </div>
          </template>
          <span>
            <div>{{ "Reassigned" | translate("60339.LongText") }}</div>
          </span>
        </v-tooltip>
        <div v-if="reassignsValue[0].data.length === 0">
            No Record Found
        </div>
        <div class="col-md-6 p-0" v-else>
          <div style="width: 100%">
            <apexcharts
              type="bar"
              :options="options"
              :series="reassignsValue"
              height="80"
            >
            </apexcharts>
          </div>
        </div>
        <div class="col-md-2 pl-0 text-right f-12">
          <h6>{{ reassignsLastValue }}</h6>
        </div>
      </div>
      <div class="row align-items-center m-0">
        <v-tooltip bottom max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12">
              <span v-bind="attrs" v-on="on">
                <h6>{{ "Answered" | translate("60340.ShortText") }}</h6>
              </span>
            </div>
          </template>
          <span>
            <div>{{ "Answered" | translate("60340.LongText") }}</div>
          </span>
        </v-tooltip>
        <div v-if="answersValue[0].data.length === 0">
            No Record Found
        </div>
        <div class="col-md-6 p-0" v-else>
          <div style="width: 100%">
            <apexcharts
              type="bar"
              :options="options"
              :series="answersValue"
              height="80"
            >
            </apexcharts>
          </div>
        </div>
        <div class="col-md-2 col-2 pl-0 text-right f-12">
          <h6>{{ answersLastValue }}</h6>
        </div>
      </div>
      <div class="row align-items-center m-0">
        <v-tooltip bottom max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12">
              <span v-bind="attrs" v-on="on">
                <h6>
                  {{ "Minutes First Reply" | translate("60342.ShortText") }}
                </h6>
              </span>
            </div>
          </template>
          <span>
            <div>{{ "Minutes First Reply" | translate("60342.LongText") }}</div>
          </span>
        </v-tooltip>
         <div v-if="averageMinutesFirstReplyValue[0].data.length === 0">
            No Record Found
        </div>
        <div class="col-md-6 p-0" v-else>
          <div style="width: 100%">
            <apexcharts
              type="line"
              :options="options"
              :series="averageMinutesFirstReplyValue"
              height="80"
            >
            </apexcharts>
          </div>
        </div>
        <div class="col-md-2 pl-0 text-right f-12">
          <h6>{{ averageMinutesFirstReplyLastValue }}</h6>
        </div>
      </div>
      <div class="row align-items-center m-0">
        <v-tooltip bottom max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <div class="col-md-4 pr-0 f-12">
              <span v-bind="attrs" v-on="on">
                <h6>{{ "Minutes Answered" | translate("60343.ShortText") }}</h6>
              </span>
            </div>
          </template>
          <span>
            <div>{{ "Minutes Answered" | translate("60343.LongText") }}</div>
          </span>
        </v-tooltip>
        <div v-if="averageMinutesAnsweredValue[0].data.length === 0">
            No Record Found
        </div>
        <div class="col-md-6 p-0" v-else>
          <div style="width: 100%">
            <apexcharts
              type="line"
              :options="options"
              :series="averageMinutesAnsweredValue"
              height="80"
            >
            </apexcharts>
          </div>
        </div>
        <div class="col-md-2 pl-0 text-right f-12">
          <h6>{{ averageMinutesAnsweredLastValue }}</h6>
        </div>
      </div>
      </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import moment from "moment";

const AppConfig = JSON.parse(localStorage.getItem("AppConfig"));

import {
  getBranchAndDepartment,
  getAllAvailableCreatedBranch,
  getAllAvailableDynamicDropDowns,
  getAllCollaborationChartData,
} from "@/services/collaboration.service";
import { addTrace } from "@/services/logging";
import {
  BranchorDepartmentChangeService,
  // departmentChangeService,
  loaderServiceSidebar
} from "@/services/observables";
import { languageTranslationService } from "../services/observables";
Vue.use(Vuetify);
export default {
  name: "SidePanel",
   components: {
    apexcharts: VueApexCharts,
  },
data: () => ({
    showSidebarLoader: false,
    averageMinutesFirstReplyValue: [],
    averageMinutesAnsweredValue: [],
    createdRepliesValue: [],
    investigationsValue: [],
    reassignsValue: [],
    answersValue: [],
    closedEntriesValue: [],
    averageMinutesClosedValue: [],
    createdBranches: [],
    departments: [],
    selectedDepartment: "",
    selectedBranch: "",
    averageMinutesAnsweredLastValue: "",
    averageMinutesFirstReplyLastValue: "",
    createdEntriesLastValue: "",
    createdEntriesValue: [],
    assignedEntriesValue: [],
    assignedEntriesLastValue: "",
    averageMinutesClosedLastValue: "",
    closedEntriesLastValue: "",
    createdRepliesLastValue: "",
    investigationsLastValue: "",
    reassignsLastValue: "",
    answersLastValue: "",
    aggregatedData: "DAY",
    timePeriodData: [],
    options: {},
    isLoaded: false,
    arrDepartment:[]
  }),

  // on create hok
  async created() {
    loaderServiceSidebar.getLoader().subscribe((res) => {
      this.showSidebarLoader = res;
    });

    // get data accroding day,month and year
    this.getAllAvailableAggrergationTypeData()

    // get all branch and department
    loaderServiceSidebar.setLoader(true);
    this.getAllBranchAndDepartment(function () {
      loaderServiceSidebar.setLoader(false);
    })

  // invoke sertvice on branch or department change 
    BranchorDepartmentChangeService.getBranchorDepartment().subscribe(()=>{
      this.getAllBranchAndDepartment(function () {
        loaderServiceSidebar.setLoader(false);
      })
    })

    // invoke service on language translation
    languageTranslationService.getLang().subscribe(() => {
      this.timePeriodData = [];
      this.getAllAvailableAggrergationTypeData()
    });
  },

  methods: {

    // get day,month and year data foe side panel 
  getAllAvailableAggrergationTypeData: function () {
      
      getAllAvailableDynamicDropDowns("AggregationType")
        .then((res) => {
          
          if (res.status == 200) {
             
            res?.data.forEach((element) => {
              let obj = {
                text: "",
                value: "",
                aggregationNo: element.SysValueNo,
              };
            
              element.TextValues.forEach((ele) => {
                  (obj.text = ele.ShortText),
                  (obj.value = element.SysValueId),
                  this.timePeriodData.push(obj);
              });
            });  
          }
        })
        .catch((err) => {
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          
        });
    },

    // get side panel data and set it with loader
    timePeriodHandler() {      
      loaderServiceSidebar.setLoader(true);
      this.getSidePanelGraphData();
    },

    // get all avilable branch and department
    getAllBranchAndDepartment(callback){
      loaderServiceSidebar.setLoader(true);
      let self = this;
      this.getAllAvailableCreatedBranches(function (resBranch) {
        if (resBranch) {
          self.getAllAvailableDepartments(function (resDept) {
            if (resDept) {
              self.checkSelectedBranchDept(function (resBranchDept) {
                if (resBranchDept) {
                  loaderServiceSidebar.setLoader(true);
                  self.getSidePanelGraphData();
                }
              });
            }
          });
        }
      });
      if(callback){
        return callback(true);
      }
    },

    // get side panel data
    getSidePanelGraphData() {
      const isOpenSideBar = localStorage.getItem("isOpenSideBar")
      if(this.selectedBranch!='' && this.arrDepartment && this.arrDepartment.length>0 && isOpenSideBar=='true'){
          let departmentList=[]
          this.arrDepartment.forEach((x) => {
          let department = this.departments.find(
            (dept) => dept.deptNo == x
          );
            if (department) {
              departmentList.push(department.value)
            }
          });
        let obj = {
          branchId: this.selectedBranch,
          department: departmentList,
          timeaggregation: this.aggregatedData,
          date: moment().format("YYYY-MM-DD"),
          NoOfAnalyticsPeriod: AppConfig.NoOfAnalyticsPeriod
        };
        loaderServiceSidebar.setLoader(true);
        getAllCollaborationChartData(obj)
        .then((res) => {
            if (res && res.status === 200) {
              
               this.createdEntriesValue = [
                {
                  data: [...res.data.createdEntries],
                },
              ];
              this.closedEntriesValue = [
                {
                  data: [...res.data.closedEntries],
                }
              ];
              this.assignedEntriesValue = [
                { 
                  data: [...res.data.assignedEntries]
                },
              ];
              this.createdRepliesValue = [
                {
                  data: [...res.data.createdReplies]
                }
              ];
              this.investigationsValue = [
                {
                  data: [...res.data.investigations]
                }
              ];
              this.reassignsValue = [
                {
                  data: [...res.data.reassigns]
                }
              ];
              this.answersValue = [
                {
                  data: [...res.data.answers]
                }
              ];
              this.averageMinutesClosedValue = [
                {
                  data: [...res.data.averageMinutesClosed,]
                }
              ];
              this.averageMinutesFirstReplyValue = [
                {
                  data: [...res.data.averageMinutesFirstReply],
                }
              ];
              this.averageMinutesAnsweredValue = [
                {
                  data: [...res.data.averageMinutesAnswered],
                }
              ];

            this.createdEntriesLastValue =
              res.data.createdEntries[res.data.createdEntries.length - 1];
            this.closedEntriesLastValue =
              res.data.closedEntries[res.data.closedEntries.length - 1];
              this.assignedEntriesLastValue =
                res.data.assignedEntries[res.data.assignedEntries.length - 1];
            this.createdRepliesLastValue =
              res.data.createdReplies[res.data.createdReplies.length - 1];
            this.investigationsLastValue =
              res.data.investigations[res.data.investigations.length - 1];
            this.reassignsLastValue =
              res.data.reassigns[res.data.reassigns.length - 1];
            this.answersLastValue =
              res.data.answers[res.data.answers.length - 1];
            this.averageMinutesClosedLastValue =
              res.data.averageMinutesClosed[
                res.data.averageMinutesClosed.length - 1
              ];
            this.averageMinutesFirstReplyLastValue =
              res.data.averageMinutesFirstReply[
                res.data.averageMinutesFirstReply.length - 1
              ];
            this.averageMinutesAnsweredLastValue =
              res.data.averageMinutesAnswered[
                res.data.averageMinutesAnswered.length - 1
              ];

              this.isLoaded = true;
              
              this.options={
                items: [
                {
                  id: 0,
                  name: "Collaboration",
                  text: "Collaboration",
                  icon: "mdi-clock",
                },
                ],
                chart: {
                  id: "vuechart-example",
                  sparkline: {
                    enable: true,
                  },
                  toolbar: {
                    show: false,
                  },
                },
                plotOptions: {
                  bar: {
                    columnWidth: 65,
                    padding: 0.5,
                  },
                  line: {
                  //   colors: ['#0000cc'],
                    radius: 0,
                    lineCap: "round",
                  //   padding: 8,
                  //   gradient: gradients[5],
                  // gradientDirection: "top",
                  }
                },
                stroke: {
                  curve: "smooth"
                },
                colors: ["#0000cc"],
                xaxis: {
                  categories: [...res.data.periodLabels],
                  labels: {
                    show: false,
                  },
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                  tooltip: {
                    enabled: false,
                  },
                },
                yaxis: {
                  labels: {
                    show: false,
                  },
                },
                grid: {
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  show: false,
                },
                tooltip: {
                  custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    let period;
                    if (w.config.chart.type == "bar") {
                      w.globals.labels = [...res.data.periodLabels];
                      period = w.globals.labels[dataPointIndex];
                    }
                    if (w.config.chart.type == "line") {
                      w.globals.categoryLabels = [...res.data.periodLabels];
                      period = w.globals.categoryLabels[dataPointIndex];
                    }
                    return (
                      '<div class="arrow_box" style="color:#fff;padding:10px;background-color: rgb(0 0 0); opacity: 0.5;font-weight:bold}">' +
                      "<span>" +
                      period +
                      " : " +
                      series[seriesIndex][dataPointIndex] +
                      "</span>" +
                      "</div>"
                    );
                  },
                  fixed: {
                    enabled: false,
                  },
                  x: {
                    show: false,
                  },
                  marker: {
                    show: false,
                  },
                },
              }
          }
          loaderServiceSidebar.setLoader(false);
        })
        .catch((err) => {
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          loaderServiceSidebar.setLoader(false);
        });
      }else{
        loaderServiceSidebar.setLoader(false);
      }
    },

    // get avilable branches
    getAllAvailableCreatedBranches: function (callback) {
      getAllAvailableCreatedBranch()
        .then((res) => {
          this.createdBranches = [];
          if (res.status == 200) {
            res?.data.forEach((element) => {
              if (element?.entityType == "10019") {
                let obj = {
                  text:
                    element.shortName +
                    (element.country ? " " + element.country : "") +
                    (element.city ? " - " + element.city : ""),
                  value: element.shortName,
                  entityNo: element.entityNo,
                };
                this.createdBranches.push(obj);
              }
            });
            if (callback) {
              return callback(true);
            }
          } else {
            if (callback) {
              return callback(false);
            }
          }
        })
        .catch((err) => {
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          if (callback) return callback(false);
        });
    },

    // get avilable department
    getAllAvailableDepartments: function (callback) {
      getAllAvailableDynamicDropDowns("Department")
        .then((res) => {
          this.departments = [];
          if (res.status == 200) {
            res?.data.forEach((element) => {
              let obj = {
                text: "",
                value: "",
                deptNo: element.SysValueNo,
              };
              element.TextValues.forEach((ele) => {
                (obj.text = ele.LongText ? ele.LongText : ele.ShortText),
                  (obj.value = element.SysValueId),
                  this.departments.push(obj);
              });
            });
            if (callback) {
              return callback(true);
            }
          } else {
            if (callback) {
              return callback(false);
            }
          }
        })
        .catch((err) => {
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          if (callback) return callback(false);
        });
    },

    // get branch and department and check it
    checkSelectedBranchDept: function (callback) {
      getBranchAndDepartment((res) => {
        if (res && res.status == 200) {
         

          let branch = this.createdBranches.find(
            (branch) => branch.entityNo == res.data.branchNo
          );
          if (branch) {
            this.selectedBranch = branch.value;
          }
          this.arrDepartment=res.data.arrDepartment;
          if (callback) {
            let obj = {
              branch: this.selectedBranch,
            };
            return callback(obj);
          }
        } else {
          if (callback) {
            return callback();
          }
        }
      }).catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
    },
  },
};
</script>

<style scoped>
.sidebar-wrapper {
  /* min-width: 250px; */
  background: #fff;
  border-radius: 8px;
}
.v-card {
  border-radius: 8px !important;
}
.v-sheet.v-list {
  border-radius: 8px !important;
}
.theme--light.v-list-item--active::before {
  background: #ffa200 !important;
}
.v-list .v-list-item--active {
  color: #ffa200 !important;
}
.v-list-item__icon {
  margin-right: 12px;
}
.f-12 h6{
  font-size: 12px;
}
.c-card-wrapper{
      height: calc(100vh - 150px);
    transition: all 0.5s ease;
}
.c-card-inner-wrapper{
      height: calc(100vh - 225px);
    overflow-y: auto;
    transition: all 0.5s ease;
}
.vue-loaders {
  position: absolute;
  top: calc(100vh - 640px);
  left: calc(100% - 200px);
  z-index: 99999999 !important;
}
.fixed-header{
position: fixed;
    background: #fff;
    z-index: 2;
    overflow: hidden;
}
</style>
