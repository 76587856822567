<template>
  <div>
    <v-form ref="addForm" v-model="valid" lazy-validation>
      <v-card>
        <div class="header-wrapper">
          <div>
            <v-card-title>
              <span class="text-h5">
                {{ "Add Collaboration" | translate("60314.LongText") }}</span
              >
            </v-card-title>
          </div>
          <div class="p-14">
            <v-btn
              icon
              class="close-btn"
              v-if="!hideCloseButton"
              @click="reset"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider class="m-0"></v-divider>
        <v-card-text class="modal-content-wrapper">
          <v-container>
            <v-row>
               <v-col cols="12" sm="6" md="4" class="ptb-0">
                <v-select
                  :items="fileTypes"
                  v-model="addForm.fileType"
                  id="parentId"
                  :label="'FileType' | translate('60307.LongText')"
                  @change="
                    getDataBasedOnFileType(addForm.fileType)
                  "
                  :rules="addFormRules.fileTypeRules"
                  :disabled="this.pluginObjData && this.pluginObjData.FileId ? true : false"
                  auto
                  return-object
                  data-cy="v-menu_content"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="ptb-0">
                <v-text-field
                  v-model="addForm.fileId"
                  :label="'FileId' | translate('60308.LongText')"
                  :rules="addFormRules.fileIdRules"
                  :counter="50"
                  :disabled="this.pluginObjData && this.pluginObjData.FileType ? true : false"
                  type="text"
                  id="fId"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="4" class="ptb-0" v-if="functionValues && functionValues.length > 0">
                <v-select
                  :items="functionValues"
                  v-model="addForm.createdFunction"
                  :label="'Creating Branch Function' | translate('60324.LongText')"
                  :rules="addFormRules.createdFunctionRules"
                  auto
                   @change="setDepartmentFromBranchFunction(addForm.createdFunction)"
                  return-object
                  data-cy="create_branch_function"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="ptb-0">
                <v-select
                  :items="createdBranches"
                  v-model="addForm.createdBranch"
                  :label="'Created Branch' | translate('60300.LongText')"
                  :rules="addFormRules.createdBranchRules"
                   @change="setCreatedBranchFunction(addForm.createdBranch)"
                  auto
                  return-object
                  data-cy="create_branch"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="ptb-0" v-if="departmentValues && departmentValues.length > 0">
                <v-select
                  :items="departmentValues"
                  v-model="addForm.createdDepartment"
                  :label="'Created Department' | translate('60394.LongText')"
                  :rules="addFormRules.createdDepartmentRules"
                  auto
                  return-object
                  data-cy="department"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="12" class="ptb-0">
                <label class="f-16">{{
                  "Comment" | translate("60311.LongText")
                }}</label>
                 <div style="display: flex;">
                  <div class="c-img-close-wrapper" style="margin-left: 5px;" v-for="(item, i) in fileList" :key="i">
                    <!-- show pdf files -->
                    <div><img v-if="checkFileExtention(item, fileExtentionsObj.pdf)"
                                src="@/assets/images/icons/pdf.png"  style="height: 85px;width:85px;margin-bottom: 10px;" class="img-fluid c-img-wrapper"></div>
                    <!-- show doc files -->
                    <div><img v-if="checkFileExtention(item, fileExtentionsObj.doc)"
                      src="@/assets/images/icons/file.png" style="height: 85px;width:85px;margin-bottom: 10px;" class="img-fluid c-img-wrapper"></div>
                    <!-- show excel files -->
                    <div><img v-if="checkFileExtention(item, fileExtentionsObj.xlsx)"
                                src="@/assets/images/icons/excel.png" style="height: 85px;width:85px;margin-bottom: 10px;" class="img-fluid c-img-wrapper"></div>
                    <!-- show images -->
                    <div><img
                      v-if="checkFileExtention(item, fileExtentionsObj.png)"
                          :src="item.previewImage" style="height: 85px;width:85px;margin-bottom: 10px;"
                                onError="src='@/assets/images/icons/nofile-image.jpg'" class="img-fluid c-img-wrapper">
                        </div>
                    <!-- show other files -->
                    <div
                    v-if="checkFileExtention(item, fileExtentionsObj.all)">
                
                      <img src="@/assets/images/icons/file.png" style="height: 85px;width:85px;margin-bottom: 10px;"
                          onError="src='@/assets/images/icons/no-image.jpg'" class="img-fluid c-img-wrapper">
                    </div>
                    <div class="overlay-img-close-wrapper">
                      <div class="overlay-img-close">
                        <v-btn style="height: 36px !important;min-width: 36px !important;padding: 0 0px !important;"
                                  @click="removeImage(item)"
                                >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <vue-editor
                  :editorOptions="d_editorOptions"
                  v-model="addForm.comment"
                  :counter="100"
                  id="comment"
                ></vue-editor>
                <div class="editor-btn-group" > 
                  <div class="image-upload" style="position: absolute;bottom: 0px;right: 20px;">                    
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <label for="file-input">
                            <img src="@/assets/images/icons/attachment.png" style="height: 36px; border-radius: 30px;"                              
                            />
                        </label>
                        </span>
                      </template>
                      <span>{{ "Upload file" | translate("60350.LongText") }}</span>
                    </v-tooltip>                   
                    <input id="file-input" type="file" multiple @change="handleFileInput" :accept="extentions"/>
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="ptb-0">
                <v-checkbox
                  v-model="addForm.actionRequired"
                  class="checkbox-bottom-space"
                  :label="'Action required' | translate('60316.LongText')"
                  :rules="addFormRules.actionRequiredRules"
                  @change="checkboxChange($event)"
                  data-cy="taskCheck"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="ptb-0">
                <v-select
                  :items="prorities"
                  v-model="addForm.priority"
                  :label="'Priority' | translate('60306.LongText')"
                  :rules="addFormRules.priorityRules"
                  auto
                  return-object
                  data-cy="priority"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4" class="ptb-0" v-if="functionValues && functionValues.length > 0">
                <v-select
                  :items="functionValues"
                  v-model="addForm.assignedFunction"
                  :label="'Assigned Branch Function' | translate('60325.LongText')"
                  :disabled="!addForm.actionRequired"
                  :rules="addFormRules.assignedFunctionRules"
                  @change="setAssignBranch(addForm.assignedFunction)"
                  auto
                  return-object
                  data-cy="assign_branch_function"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="ptb-0">
                <v-select
                  :items="assignBranchCopy"
                  v-model="addForm.assignedBranch"
                  :label="'Assigned Branch' | translate('60305.LongText')"
                  :disabled="!addForm.actionRequired"
                  :rules="addFormRules.assignedBranchRules"
                  auto
                  return-object
                  data-cy="assign_to"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="ptb-0" v-if="departmentValues && departmentValues.length > 0">
                <v-select
                  :items="departmentValues"
                  v-model="addForm.assignedDepartment"
                  :label="'Assigned Department' | translate('60160.LongText')"
                  :disabled="!addForm.actionRequired"
                  :rules="addFormRules.assignedDepartmentRules"
                  auto
                  return-object
                  data-cy="departments"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="m-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="close-btn mr-2 mb-2"
            v-if="!hideCloseButton"
            text
            @click="reset"
          >
            {{ "Close" | translate("60199.LongText") }}
          </v-btn>
          <v-btn
            rounded
            class="save-btn mr-2 mb-2"
            text
            @click="checkUUID"
            data-cy="submit"
          >
            {{ "Save" | translate("60150.LongText") }}
          </v-btn>
          <!-- Dilaogbox for if not user is active for assignedBracnh and assignedDepartment. -->
          <v-dialog
            v-model="alertPopup"
            max-width="570"
          >
            <v-card>
              <v-card-title class="text-h5">
                {{ "Important" | translate("60346.LongText") }}
              </v-card-title>
              <v-card-text>
                <div v-html="alertmessage"></div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  class="close-btn mr-2 mb-2"
                  text
                  @click="closeAlertPopup"
                >
                  {{ "Close" | translate("60199.LongText") }}
                </v-btn>
                <v-btn
                  rounded
                  class="save-btn mr-2 mb-2"
                  text
                  @click="saveCollaboration"
                  data-cy="submit"
                >
                  {{ "CreateAnyway" | translate("60347.LongText") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialogbox for macthing created branch with partner branchs.  -->
          <v-dialog
            v-model="alertPopupCreatedBranch"
            max-width="570"
          >
            <v-card>
              <v-card-title class="text-h5">
                {{ "Important" | translate("60346.LongText") }}
              </v-card-title>
              <v-card-text>
                <div v-html="alertmessageCreatedBranch"></div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  class="close-btn mr-2 mb-2"
                  text
                  @click="closeAlertPopup"
                >
                  {{ "Close" | translate("60199.LongText") }}
                </v-btn>
                <v-btn
                  rounded
                  class="save-btn mr-2 mb-2"
                  text
                  @click="userSubscription"
                  data-cy="submit"
                >
                  {{ "CreateAnyway" | translate("60347.LongText") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialogbox for validating UUID -->
          <v-dialog
            v-model="alertPopupUUID"
            max-width="570"
          >
            <v-card>
              <v-card-title class="text-h5">
                {{ "Important" | translate("60346.LongText") }}
              </v-card-title>
              <v-card-text>
                <div v-html="alertmessageUUID"></div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  class="close-btn mr-2 mb-2"
                  text
                  @click="closeAlertPopup"
                >
                  {{ "Close" | translate("60199.LongText") }}
                </v-btn>
                <v-btn
                  rounded
                  class="save-btn mr-2 mb-2"
                  text
                  @click="userSubscription"
                >
                  {{ "CreateAnyway" | translate("60347.LongText") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { 
  saveCollaboration,
  checkSubscribeStatus,
  getBranchAndDepartment,
  getAllAvailableDynamicDropDowns,
  getDataFromFileType,
  getAllAvailableCreatedBranch,
  decodePluginToken,
  tagsSuggestions,
  getConsignmentMenifestNumber,
} from "@/services/collaboration.service";
import Vue from "vue";
import { addTrace } from "@/services/logging";
import { loaderService } from "@/services/observables";
import VueSanitize from "vue-sanitize";
Vue.use(VueSanitize);

export default {
  name: "CollaborationAdd",
  props: [
    "selectedRowData",
    "translationCode",
    "createdBranches",
    "assignedBranches",
    "functions",
    "departments",
    "collaborationTypes",
    "collaborationStatus",
    "prorities",
    "fileTypes",
  ],
  data() {
    return {
      alertPopup: false,
      valid: true,
      alertmessage:'',
      pluginObjData:'',
      hideCloseButton: false,
      references :[],
      suggesstionText :[],
      filetypedata :[],
      functionValues: this.functions,
      departmentValues:this.departments,
      addForm: {
        createdBranch: null,
        createdFunction: null,
        createdDepartment: null,
        status: "new",
        actionRequired: true,
        entityType: "INFO",
        assignedBranch: null,
        assignedFunction: null,
        assignedDepartment: null,
        priority: "PRIO2",
        fileType: null,
        comment: null,
        fileId: null,
        branchFunctions:[]
      },
      addFormRules: {
        createdBranchRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60300,'LongText')+
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
        
        createdFunctionRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60324,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
        createdDepartmentRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60394,'ShortText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
        typeRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60304,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
        statusRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60303,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
        actionRequiredRules: [],
        // assignedBranchRules: [],
        // assignedFunctionRules: [],
        // assignedDepartmentRules: [],
        assignedBranchRules : [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60305,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
        assignedFunctionRules : [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60325,'ShortText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
        assignedDepartmentRules : [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60160,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
        priorityRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60306,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText')
,
        ],
        fileTypeRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60307,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
        fileIdRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60308,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
          (v) =>
            (v && v.length <= 50) ||
            this.getTranslatedLanguage(60308,'LongText') +
              " must be less than 50 characters",
        ],
        commentRules: [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60311,'LongText')+
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ],
      },
      extentions:[],
      fileList:[],
      fileExtentionsObj: {
        pdf: ["pdf"],
        xlsx: ["xlsx","xls","csv","ods","xla","xlam","xlsb","xlsm","xml"],
        doc: ["doc", "docx", "txt","html","htm","xps","mp4","mkv","mp3","ppt","pptx","ppsx","tif","mht","mhtml","rtf"],
        png: ["png", "webp", "jfif", "jpg", "jpeg", "JPEG"],
        all: ["pdf","xls","xlsx","csv","doc","docx","jpg","jpeg","png","txt","html","htm","ods","xla","xlam","xlsb","xlsm","xps","mp4","mkv","mp3","ppt","pptx","ppsx","tif","xml","mht","mhtml","rtf","jfif","all"],
      },
      assignBranchCopy:[],
      alertPopupCreatedBranch:false,
      alertmessageCreatedBranch:'',
      brancheFunctionValues:[],
      alertPopupUUID:false,
      alertmessageUUID:'',
    };
  },
  computed: {
    // show a matched tag on after type # 
     d_editorOptions() {
      let self = this
      return {
        debug: "info",
        theme: "snow",
        modules: {
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["#"],
            minChars:2,
            source: async function  (searchTerm, renderList) {
              // let values=[];

              if (searchTerm.length === 0) {
                renderList(self.suggesstionText, searchTerm);
              } else if(searchTerm.length >= 2) {
                if (!searchTerm.includes(" ") && searchTerm.trim().match(/(\w+)/g)?.length == 1) {
                  let matches = [];
                  tagsSuggestions(searchTerm).then((res) => {
                    if(res && res.status===200){
                      self.suggesstionText = res.data
                      for (let i = 0; i < self.suggesstionText.length; i++) {
                        if (
                          ~self.suggesstionText[i]
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase())
                        )
                          matches.push({...self.suggesstionText[i],value:self.suggesstionText[i].slice(1,self.suggesstionText[i].length)});
                          renderList(matches, searchTerm);
                      }
                    }else{
                      renderList(matches, searchTerm);
                    }
                  })
                }else{
                  renderList(self.suggesstionText, searchTerm);
                }
              }
            },
          },
        },
      };
    },
  },
  // set branch and department if any changes in asignedBranches array
  watch: { 
    assignedBranches:function(){
      this.setSelectedBranchDept();
    }
  },
  // create and set element data 
  created() {
    this.extentions=JSON.parse(localStorage.getItem("AppConfig")).acceptedExtentions
    this.alertmessage=this.getTranslatedLanguage(60345,'LongText');
    this.alertmessageCreatedBranch=this.getTranslatedLanguage(60384,'LongText');
    this.alertmessageUUID=this.getTranslatedLanguage(60385,'LongText');
    // this.assignBranchCopy=this.assignedBranches
    let self=this;
     if(this.$route.params.pluginToken){
       self.decodePluginToken(self.$route.params.pluginToken,function(resPluginObj){
        if(resPluginObj.cacheData!=null){
          self.pluginObjData=resPluginObj.cacheData
          self.addForm.fileType = self.pluginObjData.FileType;
          self.addForm.fileId = self.pluginObjData.FileId;
          self.hideCloseButton = true;
          self.getDataBasedOnFileType(self.addForm.fileType);
          self.setBranchFunctionData()
        }else{
        Vue.notify({
            group: "foo",
            type: "error",
            title: `No Configuation payload found for this plugin`,
            duration: 4000,
          });
      }
       })
     }
    if (this.selectedRowData?._id) {
      this.getCollaborationById(this.selectedRowData._id);
    }

  },
  mounted(){
    // set electe branch and department on mount hook
    this.setSelectedBranchDept();
  },
  methods: {
    // get translated languange
    getTranslatedLanguage:function(value,text){
      return this.translationCode[this.$i18n.locale][value]==undefined?this.translationCode['en'][value][text]: this.translationCode[this.$i18n.locale][value][text]==undefined?this.translationCode['en'][value][text]:this.translationCode[this.$i18n.locale][value][text]
    },

    // get and set branch function data
    setBranchFunctionData: function(){
    //if in route params we get branchFunction then set refrences in variable
   if(this.pluginObjData?.branchFunction){
      let self=this;
      self.branchFunctions = self.pluginObjData.branchFunction;
      this.setSelectedBranchDept(function (callback){
        if(callback){
          let funs = [];
          if(self.branchFunctions.length > 0){
            if(self.addForm.createdBranch != null){
              self.branchFunctions.forEach((Function)=>{
                let data=Object.keys(Function).find(key =>Function[key] === self.addForm.createdBranch.value)
                if(data!=undefined){
                  funs.push(data)
                }
              });
            }
            if(funs.length>0){
                  self.getAllAvailableFunctions(function (resFunctions){
                    if(resFunctions){
                      self.addForm.createdFunction = resFunctions.find((fun)=>fun.value==funs[0]);
                    }
                  });
            }
          }
        }
      });
    }

      //if in route params we get refrences then set refrences in variable
    if(this.pluginObjData?.references){
      this.references = this.pluginObjData.references;
    }
},
// decode Plugin token and verify
     decodePluginToken: function(parametersetId,callback){
     decodePluginToken(parametersetId)
        .then((res) => {
          if(res.data){
          return callback(res.data)
          }else {
            return callback()
          }
        })
    },

    getCollaborationById: function (id) {
      console.log(id);
    },
    // get and set data for create a collaboration ticket
    saveCollaboration: function () {
      this.closeAlertPopup()
      loaderService.setLoader(true);
      if (!this.$refs.addForm.validate()) {
        loaderService.setLoader(false);
        return;
      } else {
        this.addForm.comment = this.$sanitize(this.addForm.comment);
        this.addForm.comment = this.addForm.comment.replace(/<\/?span[^>]*>/g,"");
        if (this.addForm.actionRequired) {
          this.addForm.entityType = "ACTION";
        } else {
          this.addForm.assignedBranch = null;
          this.addForm.assignedDepartment = null;
          this.addForm.assignedFunction = null;
          this.addForm.entityType = "INFO";
        }
          
        const formData = new FormData();
        formData.append('CreatedBranch.BranchId',  this.addForm?.createdBranch?.value);
        formData.append('CollaborationEntry.CreatedRole.SysValueId',  this.addForm?.createdFunction?.value==undefined? '' :this.addForm?.createdFunction?.value);
        formData.append('CollaborationEntry.CreatedDepartment.SysValueId',  this.addForm?.createdDepartment?.value);
        formData.append('CollaborationEntry.EntryType.SysValueId',  this.addForm?.entityType);
        formData.append('CollaborationEntry.AssignedBranch.BranchId',  this.addForm?.assignedBranch?.value==undefined?'': this.addForm?.assignedBranch?.value);
        formData.append('CollaborationEntry.AssignedRole.SysValueId',  this.addForm?.assignedFunction?.value==undefined?'': this.addForm?.assignedFunction?.value);
        formData.append('CollaborationEntry.AssignedDepartment.SysValueId',   this.addForm.actionRequired?(this.addForm.assignedDepartment.value==undefined?(this.addForm.assignedDepartment?this.addForm.assignedDepartment:undefined):this.addForm.assignedDepartment.value):(this.addForm.assignedDepartment==null || this.addForm.assignedDepartment==undefined?'':(this.addForm.assignedDepartment?this.addForm.assignedDepartment:undefined)));
        formData.append('CollaborationEntry.Priority.SysValueId',  this.addForm?.priority?.value==undefined? this.addForm?.priority:this.addForm?.priority?.value);
        formData.append('CollaborationEntry.FileType.SysValueId', this.pluginObjData && this.pluginObjData.FileType
                ? this.pluginObjData.FileType
                : this.addForm?.fileType?.value);
        formData.append('CollaborationEntry.FileId', this.addForm?.fileId.trim());
        formData.append('CollaborationEntry.FileUrl', this.pluginObjData && this.pluginObjData.FileUrl?this.pluginObjData.FileUrl:'');
        formData.append('CollaborationEntry.Comment',this.addForm?.comment);
        if(this.references && this.references.length != 0){
          formData.append('References',this.references);
        }
        if(this.pluginObjData && this.pluginObjData.branchFunction.length != 0){
          for(let j=0;j<this.pluginObjData.branchFunction.length;j++){
            if(Object.keys(this.pluginObjData.branchFunction[j]) != ''){
              formData.append('BranchFunction['+j+'].'+Object.keys(this.pluginObjData.branchFunction[j]),Object.values(this.pluginObjData.branchFunction[j]))
            }
          }
        }
        for(let i=0;i<this.fileList.length;i++){
            formData.append('CollaborationEntry.arrDocument',  this.fileList[i].uploadedFile);
        }
        saveCollaboration(formData)
          .then((res) => {
            loaderService.setLoader(false);
            if (res?.status == 201 || res?.status == 200) {
              if(res.data.errorCode==80116){
                Vue.notify({
                  group: "foo",
                  type: "error",
                  title:res.data.description,
                  duration: 8000,
                });
                return;
              }else{
                Vue.notify({
                  group: "foo",
                  type: "success",
                  title:this.getTranslatedLanguage(60317,'LongText'),
                  duration: 4000,
                });
                addTrace(this.getTranslatedLanguage(60317,'LongText'),
                  4
                );
                this.closePopup({
                  success: true,
                });
              }
            } else {
              Vue.notify({
                group: "foo",
                type: "error",
                title:this.getTranslatedLanguage(80111,'LongText'),
                duration: 4000,
                speed: 1000,
              });
              addTrace(this.getTranslatedLanguage(80111,'LongText'),
                4
              );
            }
          })
          .catch((err) => {
            Vue.notify({
              group: "foo",
              type: "error",
              title: `${err}`,
              duration: 4000,
            });
            addTrace(err, 4);
            loaderService.setLoader(false);
          });
      }
    },
    // check uer subscription for creating and assigning branch and department
    userSubscription: function (){
      loaderService.setLoader(true);
      if(this.addForm.actionRequired) {   
        if (!this.$refs.addForm.validate()) {
          loaderService.setLoader(false);
          return;
      } else {

        let obj={
          createdBranch:this.addForm.createdBranch.value,
          assignedBranch:this.addForm.assignedBranch.value,
          department:this.addForm.assignedDepartment.value==undefined?this.addForm.assignedDepartment:this.addForm.assignedDepartment.value
        }     

      checkSubscribeStatus(obj)
         .then((res) => {
           loaderService.setLoader(false);
            if(res.data.length>0){
              this.alertPopup=false
              this.saveCollaboration()
            } else{
              this.alertPopup=true
            }
          })
          .catch((err) => {
            Vue.notify({
              group: "foo",
              type: "error",
              title: `${err}`,
              duration: 4000,
            });
            addTrace(err, 4);
           loaderService.setLoader(false);
          });
          }
      } 
      else {
        loaderService.setLoader(false);
        this.saveCollaboration()
      }
    },

    reset: function () {
      this.closePopup();
    },
    // close alert popup click on close button 
    closeAlertPopup: function() {
      this.alertPopup = false;
      this.alertPopupCreatedBranch = false;
      this.alertPopupUUID = false;
    }, 	
    // change ticket action type 
    checkboxChange: function ($event) {
      if ($event) {
        this.addFormRules.assignedBranchRules = [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60305,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ];
        this.addFormRules.assignedFunctionRules = [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60325,'ShortText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ];
        this.addFormRules.assignedDepartmentRules = [
          (v) =>
            !!v ||
            this.getTranslatedLanguage(60160,'LongText') +
              " " +
              this.getTranslatedLanguage(80110,'LongText'),
        ];
      } else {
        this.addFormRules.assignedBranchRules = [];
        this.addFormRules.assignedFunctionRules = [];
        this.addFormRules.assignedDepartmentRules = [];
      }
    },
    // reset form and cloe popup
    closePopup: function (obj) {
      if (this.$refs.addForm) {
        this.$refs.addForm.reset();
      }
      setTimeout(() => {
        this.addForm.status = "new";
      });
      this.$emit("close", obj);
    },
    // set branch and department in addform
    setSelectedBranchDept: function (callback) {
      getBranchAndDepartment((res) => {
        if (res && res.status == 200) {
          if(res.data.arrDepartment?.length==1){
            let self=this;
            this.getAllAvailableDepartments(function (resDept) {
              if (resDept) {
                let department = resDept.find(
                  (dept) => dept.deptNo == res.data.arrDepartment[0]
                );
                if (department) {
                  self.addForm.createdDepartment = {
                    text: department.text,
                    value:  department.value 
                  };
                }
              }
            })
          }
          this.assignBranchCopy=[]
          if(res.data.arrCollaborationEntryBranch!=null && res.data.arrCollaborationEntryBranch.length>0){
            for(var i=0;i<res.data.arrCollaborationEntryBranch.length;i++){
              let assignBranch = this.assignedBranches.find(
                (branch) => branch.value == res.data.arrCollaborationEntryBranch[i]
              );
              if (assignBranch) {
                let obj={
                  text: assignBranch.text,
                  value:  assignBranch.value 
                }
                this.assignBranchCopy.push(obj)
              }
            }
          }
          if(this.assignBranchCopy.length==1){
            this.addForm.assignedBranch=this.assignBranchCopy[0]
          }
          // if createdbranches array is empty then it will get data from getAllAvailableCreatedBranches and set data in addform
          if(this.createdBranches.length == 0){
            let self=this;
            this.getAllAvailableCreatedBranches(function (resBranch) {
              if (resBranch) {
                let branch = resBranch.find(
                  (branch) => branch.entityNo == res.data.branchNo
                );
                if (branch) {
                  self.addForm.createdBranch = {
                    text: branch.text ,
                    value: branch.value 
                  };
                }
              }
            })
          }else{
            let branch = this.createdBranches.find(
              (branch) => branch.entityNo == res.data.branchNo
            );
            if (branch) {
              this.addForm.createdBranch = {
                text: branch.text ,
                value: branch.value 
              };
              if(this.assignBranchCopy.length==0){
                let obj={
                  text: branch.text,
                  value:  branch.value 
                }
                this.assignBranchCopy.push(obj)
                this.addForm.assignedBranch=this.assignBranchCopy[0]
              }
            }
          }
          if (callback) {
            return callback(true);
          }
        }else{
          if (callback) {
            return callback(false);
          }
        }
      }).catch((err) => {
        console.log("setbranchdept", err)
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
    },

    //get all available departments 
    getAllAvailableDepartments: function (callback) {
      getAllAvailableDynamicDropDowns("Department")
        .then((res) => {
          let departments = [];
          if (res && res.status == 200) {
            res?.data.forEach((element) => {
              let obj = {
                text: "",
                value: "",
                deptNo: element.SysValueNo,
              };
              element.TextValues.forEach((ele) => {
                (obj.text = ele.LongText ? ele.LongText : ele.ShortText),
                  (obj.value = element.SysValueId),
                  departments.push(obj);
              });
            });
            if (callback) {
              return callback(departments);
            }
          }else{
            if (callback) {
              return callback(departments);
            }
          }
        })
        .catch((err) => {
          console.log("dept", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          if (callback) return callback(false);
        });
    },

    //get all available created branches
    getAllAvailableCreatedBranches: function (callback) {
      getAllAvailableCreatedBranch()
        .then((res) => {
          let createdBranches = [];
          if (res && res.status == 200) {
            res?.data.forEach((element) => {
              if (element?.entityType == "10019") {
                let obj = {
                  text:
                    element.shortName +
                    (element.country ? " " + element.country : "") +
                    (element.city ? " - " + element.city : ""),
                  value: element.shortName,
                  entityNo: element.entityNo,
                };
                createdBranches.push(obj);
              }
            });
            if (callback) {
              return callback(createdBranches);
            }
          }else{
             if (callback) {
              return callback(createdBranches);
            }
          }
        })
        .catch((err) => {
          console.log("branch", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          if (callback) return callback(false);
        });
    },

    // get all avalilable functions
    getAllAvailableFunctions: function (callback) {
      let functionValues=[];
      getAllAvailableDynamicDropDowns("BranchFunction")
        .then((res) => {
          if (res && res.status == 200) {
            res?.data.forEach((element) => {
              element.TextValues.forEach((ele) => {
                let obj = {
                  text: ele.LongText ? ele.LongText : ele.ShortText,
                  value: element.SysValueId,
                };
                functionValues.push(obj);
              });
            });
            if(callback){
              return callback(functionValues);
            }
          }else{
            if(callback){
              return callback(functionValues);
            }
          }
            
        })
        .catch((err) => {
          console.log("function", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          if (callback) return callback(false);
        });
    },
    //get branch function, department and default department data from filetype
    getDataBasedOnFileType(func) {
      if(func){
        this.functionValues=[]
        this.departmentValues=[]
        loaderService.setLoader(true);
        let filetype=func.value?func.value:func
        getDataFromFileType(filetype)
          .then((res) => {
            if (res && res.status == 200) {
              this.filetypedata = res.data;
              if (res.data[0].branchFunctions.length > 0) {
                res?.data[0].branchFunctions.forEach((element) => {
                let obj = {
                  text: element.branchFunction.longText
                    ? element.branchFunction.longText
                    : element.branchFunction.shortText,
                  value: element.branchFunction.sysValueId,
                };
                this.functionValues.push(obj);
                });
              } else {
                this.functionValues = [];
              }
              if (res.data[0].departments.length > 0) {
                res?.data[0].departments.forEach((element) => {
                  let obj = {
                    text: "",
                    value: "",
                    deptNo: element.sysValueNo,
                  };
                  (obj.text = element.longText
                    ? element.longText
                    : element.shortText),
                    (obj.value = element.sysValueId),
                    this.departmentValues.push(obj);
                });
              } else {
                this.departmentValues = [];
              }
              loaderService.setLoader(false);
            }else{
              loaderService.setLoader(true);
            }
          })
          .catch((err) => {
            Vue.notify({
              group: "foo",
              type: "error",
              title: `${err}`,
              duration: 4000,
            });
            addTrace(err, 4);
          });
      }
    },
    // set Created Branch Function according to Created Branch
    setCreatedBranchFunction(func){
      if (this.branchFunctions) {
        let branchFunctionData=[]
        let data = this.branchFunctions.find(
          (obj) => Object.values(obj)[0] === func.value
        );
        branchFunctionData.push(data)
        let self=this;
        if (data) {
          this.getAllAvailableFunctions(function (resFunctions){
            if(resFunctions){
              let branchfunction = resFunctions.find(
                (branch) => branch.value ==Object.keys(data)[0] 
              );
              self.addForm.createdFunction=branchfunction
            }
        });
        }else{
           self.addForm.createdFunction=null
        }
      }

    },
    // set assign branch according to branch functions
    setAssignBranch(assignFunc) {
      if (this.branchFunctions) {
        let data = this.branchFunctions.find(
          (obj) => Object.keys(obj)[0] === assignFunc.value
        );
        if (data) {
          let branch = this.assignBranchCopy.find(
            (branch) => branch.value == Object.values(data)[0]
          );
          if (branch) {
            this.addForm.assignedBranch = {
              text: branch.text,
              value: branch.value,
            };
          } else {
            this.addForm.assignedBranch = null;
          }
        }else{
           this.addForm.assignedBranch = null;
        }
      }
      let department = this.filetypedata[0].branchFunctions.find(
        (dept) => dept.branchFunction.sysValueId == assignFunc.value
      );
      this.addForm.assignedDepartment = department.defaultDepartment.sysValueId;
    },
    //get Department Values From created Branch Function
    setDepartmentFromBranchFunction(createFunc){
      if(this.filetypedata.length>0 && this.filetypedata[0].branchFunctions){
        let department = this.filetypedata[0].branchFunctions.find(
          (dept) => dept.branchFunction.sysValueId == createFunc.value
        );
        if(department){
          this.addForm.createdDepartment = {
            text: department.defaultDepartment.shortText,
            value:  department.defaultDepartment.sysValueId 
          };
        }
      }
    },
    //upload files
    handleFileInput: function (e) {
      let fileData=e.target.files;
      let self=this;
      self.prepareFileArray(fileData, function (data) {
        self.fileList.push(...data)
      })
    },
    //prepare files array
    prepareFileArray: function (fileData, callback) {
      let array=[];
      function fileArray(i){
        if(i<fileData.length){
          const image = fileData[i];
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onload = e =>{
              let params={
                fileExtention : fileData[i].name.split('.').pop(),
                uploadedFile:fileData[i],
                previewImage:e.target.result
              }
              array.push(params)
              fileArray(i+1)
          };
        }else{
          return callback(array)
        }
      }
      fileArray(0)
    }, 
    //remove uploaded image
    removeImage(item){
      for (let i = this.fileList.length; i--;) {
        if (this.fileList[i] === item){ 
          this.fileList.splice(i, 1);
        }
      }
    },
    //check file extention
    checkFileExtention(item, file_ext) {
      if (item.previewImage != null) {
        if (!file_ext.includes("all")) {
          return file_ext.includes((item.fileExtention).toLowerCase()) ? true : false;
        } else {
          return file_ext.includes((item.fileExtention).toLowerCase()) ? false : true;
        }
      } else {
        return false;
      }
    },
    // validate creating branch
    checkBranch: async function () {
      this.closeAlertPopup();
      loaderService.setLoader(true);
      if (!this.$refs.addForm.validate()) {
        loaderService.setLoader(false);
        return;
      } else {
        if (this.brancheFunctionValues.length !== 0) {
          const matchedBranch = this.brancheFunctionValues.find((branch) => {
            return branch == this.addForm.createdBranch.value
          })
          if (!matchedBranch) {
            loaderService.setLoader(false);
            this.alertPopupCreatedBranch = true;
          } else {
            loaderService.setLoader(false);
            this.userSubscription();
          }
        } else {
          loaderService.setLoader(false);
          this.userSubscription();
        }
      }
    },
    // validate UUID
    checkUUID: async function () {
      loaderService.setLoader(true);
      if (!this.$refs.addForm.validate()) {
        loaderService.setLoader(false);
        return;
      } else {
        const fileType = this.pluginObjData ? this.pluginObjData.FileType : this.addForm.fileType.value;
        const fileId = this.pluginObjData ? this.pluginObjData.FileId : this.addForm.fileId;
        if (fileType == "CONSIGNMENT" || fileType == "PICKUPORDER") {
          await getConsignmentMenifestNumber(fileId).then((res) => {
            if (res) {
              this.brancheFunctionValues = []
              const ContractedBranch = res?.data.ContractedBranch?.BranchId;
              const ShippingBranch = res?.data.ShippingBranch?.BranchId;
              const ReceivingBranch = res?.data.ReceivingBranch?.BranchId;
              const OrderingBranch = res?.data.OrderingBranch?.BranchId;
              this.brancheFunctionValues.push(ContractedBranch, ShippingBranch, ReceivingBranch, OrderingBranch);
              this.checkBranch();
              loaderService.setLoader(false);
            } else {
              loaderService.setLoader(false);
              this.alertPopupUUID = true;
            }
          });
        } else {
          loaderService.setLoader(false);
          this.userSubscription();
        }
      }
    },
  },
};
</script>

<style scoped>
.header-wrapper {
  display: flex;
  justify-content: space-between;
}
.close-btn {
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  color: #fff !important;
}
.save-btn {
  background-color: #ffa200 !important;
  border-color: #ffa200 !important;
  color: #fff !important;
}
.p-14 {
  padding: 14px;
}
.m-0 {
  margin: 0px;
}
.ptb-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.f-16 {
  font-size: 16px;
}
.modal-content-wrapper {
  height: calc(100vh - 310px);
  overflow-y: auto;
}
.editor-btn-group {
  /* position: relative; */
  display: flex;
  justify-content: flex-end;
  /* width: 100%; */
}
.c-img-wrapper:hover .overlay-img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000069;
}
.c-img-close-wrapper:hover .overlay-img-close-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  background: #00000069;
}
.c-img-wrapper:hover .overlay-img {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 24px;
}
.c-img-close-wrapper:hover .overlay-img-close {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  color: #ffffff;
  font-size: 24px;
}
.overlay-img {
  font-size: 0px;
}
.c-img-wrapper:hover .overlay-img v-icon {
  margin: 0 10px;
}
.c-img-close-wrapper:hover .overlay-img-close v-icon {
  margin: 0 10px;
}
.c-img-wrapper {
  position: relative;
}

.c-img-close-wrapper {
  position: relative;
}


.imageLoop {
  width: 65px;
  /* height: 65px; */
  margin-bottom: 10px;
  position: relative;
}
.c-img {
  display: flex;
  flex-wrap: wrap;
}
.imageLoopData {
  display: inline-block;
  height: 100%;
  position: relative;
  border: 2px dashed #dee2e6 !important;
  cursor: pointer;
}
.c-img-wrapper:hover .overlay-img-wrapper {
  display: block;
}
.overlay-img-wrapper {
  display: none;
}
.c-img-close-wrapper:hover .overlay-img-close-wrapper {
  display: block;
}
.overlay-img-close-wrapper {
  display: none;
}
</style>