<template>
    <div>
        <v-form ref="settingsForm">
            <v-card @mousedown="removeSearch">
                <div class="header-wrapper">
                    <div>
                        <v-card-title>
                        <span class="text-h5">
                            {{ "Settings" | translate("60371.LongText") }}
                        </span>
                        </v-card-title>
                    </div>
                    <div class="p-14">
                        <v-btn
                        icon
                        class="close-btn"
                        @click="closePopup()"
                        >
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-divider class="m-0"></v-divider>
                <v-card-text class="modal-content-wrapper">
                    <v-container>
                        
                        <v-row>
                            <v-col cols="12" sm="6" md="5" class="ptb-0">
                                <v-select v-model="settingsForm.createdBranch" :items="createdBranches"
                                @change="onChangeBranch"
                                item-value="entityNo"
                                :label="'Branch' | translate('60372.LongText')"
                                >
                                    <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                        <v-text-field v-model="searchBranch" autofocus :placeholder="'Search' | translate('60322.ShortText')"  @input="searchBranches"></v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="5" class="ptb-0 c-select-input">
                                <v-select
                                    @change="removeSearch()"
                                    v-model="settingsForm.arrDepartment"
                                    :items="departments"
                                    item-value="deptNo"
                                    :label="'Department' | translate('60373.LongText')"
                                    multiple
                                    >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                            <v-text-field v-model="searchDepartment" autofocus :placeholder="'Search' | translate('60322.ShortText')" @input="searchDepartments"></v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                        <v-list-item
                                        @mousedown.prevent
                                        @click="onchangeDepartment"
                                        v-bind:class="[settingsForm.arrDepartment.length == departments.length ? 'select_bg' : '']"
                                        >
                                        <v-list-item-action>
                                            <v-icon :color="settingsForm.arrDepartment.length > 0 ? 'indigo-1 darken-5' : ''" 
                                                v-bind:class="[settingsForm.arrDepartment.length == departments.length ? 'select_all' : '']" >
                                            {{ iconDepartment }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content  v-bind:class="[settingsForm.arrDepartment.length == departments.length ? 'select_all' : '']">
                                            <v-list-item-title>
                                                {{ "Select All" | translate("60382.LongText") }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row class="padingbtm"> 
                            <v-col cols="12" sm="12" md="10" class="ptb-0 c-select-input"> 
                                <v-select 
                                    @change="removeSearch()"
                                    v-model="settingsForm.selectedCollaborationBranch"
                                    :items="allCollaborationBranch"
                                    :label="'I work with' | translate('60374.LongText')"
                                    multiple 
                                    >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                            <v-text-field v-model="searchAllBranch" autofocus :placeholder="'Search' | translate('60322.ShortText')"  @input="searchAllBranches"></v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item
                                        ripple
                                        @mousedown.prevent
                                        @click="onChangeAllBranch"
                                        v-bind:class="[settingsForm.selectedCollaborationBranch!=null &&settingsForm.selectedCollaborationBranch.length == allCollaborationBranch.length ? 'select_bg' : '']"
                                        >
                                        <v-list-item-action>
                                            <v-icon :color="settingsForm.selectedCollaborationBranch!=null &&settingsForm.selectedCollaborationBranch.length > 0 ? 'indigo-1 darken-5' : ''"
                                                v-bind:class="[settingsForm.selectedCollaborationBranch!=null &&settingsForm.selectedCollaborationBranch.length == allCollaborationBranch.length ? 'select_all' : '']">
                                            {{ iconBranch }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content  v-bind:class="[settingsForm.selectedCollaborationBranch!=null &&settingsForm.selectedCollaborationBranch.length == allCollaborationBranch.length ? 'select_all' : '']">
                                            <v-list-item-title>
                                                {{ "Select All" | translate("60382.LongText") }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-select> 
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <span class="note-content">
                                    {{ "notes" | translate("60375.LongText") }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="d-flex">
                                <div class="me-4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="toggle-switch">
                                            <span v-bind="attrs" v-on="on">
                                                <v-switch
                                                v-model="isNotificationEnable" color="#ffa200"  @change="askNotification(isNotificationEnable)"></v-switch>
                                            </span>
                                        </div>
                                    </template>
                                    <span>{{ "Enabled / Disabled" | translate("60380.LongText")}}</span>
                                    
                                </v-tooltip>
                                <span class="toggle-switch"></span>
                                </div>
                               
                                <div class="f-16" style="padding-top: 2px;">
                                    {{ "Enable notification" | translate("60376.LongText") }}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="3" class="ptb-0">
                                <v-checkbox
                                    v-model="settingsForm.emailNotification"
                                    class="pm-0"
                                    :disabled="isNotificationEnable==false"
                                    @change="selectemailNotificationOption($event)">
                                    <template v-slot:label style="padding-top:5px !important;">
                                        <div style="padding-top: 6px;">{{ "email" | translate("60377.LongText") }}</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" class="ptb-0">
                                <v-checkbox
                                    v-model="settingsForm.browserNotification"
                                    class="pm-0"
                                    :disabled="isNotificationEnable==false"
                                    @change="selectbrowserNotificationOption($event)">
                                    <template v-slot:label style="padding-top:5px !important;">
                                        <div style="padding-top: 6px;">{{ "browser notification" | translate("60378.LongText") }}</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <span class="note-content">
                                    {{ "Enable notification notes" | translate("60379.LongText") }}
                                </span>
                            </v-col>
                        </v-row>
                        <div class="imageLoop">
                            <img  src="@/assets/images/icons/Note_collaboration_browser.png" class="uploading-image" />
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        rounded
                        class="close-btn mr-2 mb-2"
                        text
                        @click="closePopup()"
                    >
                        {{ "Close" | translate("60199.LongText") }}
                    </v-btn>
                    <v-btn
                        rounded
                        class="save-btn mr-2 mb-2"
                        text
                        data-cy="submit"
                        @click="saveSettings()"
                    >
                        {{ "Save" | translate("60150.LongText") }}
                    </v-btn>
                
                </v-card-actions>
            </v-card>
        </v-form>
    </div>
  </template>
  
  <script>
  import { 
    getBranchAndDepartment,
    setBranchAndDepartment
  } from "@/services/collaboration.service";
  import {
    BranchorDepartmentChangeService,
    loaderService,
  } from "@/services/observables";
  import Vue from "vue";
  import { addTrace } from "@/services/logging";
  import VueSanitize from "vue-sanitize";
  Vue.use(VueSanitize);
  
  export default {
    name: "CollaborationSettings",
    props: [
      "createdBranches",
      "departments",
      "allCollaborationBranch",
      "translationCode",
      "arrEntryStatus",
      "columns"
    ],
    data() {
      return {
        searchBranch:"",
        searchAllBranch:"",
        searchDepartment: "",
        createdBranchesCopy:[],
        branchesCopy:[],
        departmentsCopy: [],
        isNotificationEnable:true,
        valid: true,
        translateLanguageCode:null,
        settingsForm: {
          createdBranch: null,
          arrDepartment: [],
          selectedCollaborationBranch:[],
          browserNotification:true,
          emailNotification:true
        },
      };
    },
    // on created hook
    created() {
    },
    computed:{
        // select all department
      selectAllDepartments () {
        return this.settingsForm.arrDepartment.length === this.departmentsCopy.length
      },
    //   change icon on select all department
      iconDepartment () {
        if (this.selectAllDepartments) return 'mdi-close-box'
        return 'mdi-checkbox-blank-outline'
      },
    //   select all branch
      selectAllBranches () {
        return this.settingsForm.selectedCollaborationBranch?.length === this.branchesCopy.length
      },
    //   change icon on select all branch
      iconBranch () {
        if (this.selectAllBranches) return 'mdi-close-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
    mounted(){
        // check select branhc and department and set value 
        this.checkSelectedBranchDept()
        this.departmentsCopy = [...this.departments];
        this.branchesCopy = [...this.allCollaborationBranch];
        this.createdBranchesCopy = [...this.createdBranches];
    },
    methods: {
        // get letest translated language
        getTranslatedLanguage:function(value,text){
            return this.translationCode[this.$i18n.locale][value]==undefined?this.translationCode['en'][value][text]: this.translationCode[this.$i18n.locale][value][text]==undefined?this.translationCode['en'][value][text]:this.translationCode[this.$i18n.locale][value][text]
        },
        // open alert if notification is not allowed
        askNotification:function(){
            if(this.isNotificationEnable){
                Notification.requestPermission((perm) => {
                    if (perm != "granted") {
                        Notification.requestPermission();
                    }
                })
            }
        },

        // close popup on clicked on close button
        closePopup: function (obj) {
            if (this.$refs.settingsForm) {
                this.$refs.settingsForm.reset();
            }
            this.$emit("close", obj);
        },
        closeBox: function () {
            this.$refs.settingsForm.reset();
        },
        // check selected branch and department
        checkSelectedBranchDept: function (callback) {
            loaderService.setLoader(true);
            getBranchAndDepartment((res) => {
                if (res && res.status == 200) {
                    this.isNotificationEnable= res.data.isNotificationEnable;
                    if(this.isNotificationEnable){
                        Notification.requestPermission((perm) => {
                            if (perm != "granted") {
                                Notification.requestPermission();
                            }
                        })
                    }
                    this.settingsForm.selectedCollaborationBranch=this.settingsForm.selectedCollaborationBranchCopy=res.data.arrCollaborationEntryBranch;
                    this.settingsForm.browserNotification=res.data.isBrowserNotificationEnable;
                    this.settingsForm.emailNotification=res.data.isEmailNotificationEnable;
                    this.settingsForm.arrDepartment=this.settingsForm.arrDepartmentCopy=res.data.arrDepartment;
                    this.translateLanguageCode=(res.data.translationLanguage==null || res.data.translationLanguage=='' ||res.data.translationLanguage==undefined )?'':res.data.translationLanguage
                    let branch = this.createdBranches.find(
                        (branch) => branch.entityNo == res.data.branchNo
                    );
                    if (branch) {
                        this.settingsForm.createdBranch = branch.entityNo;
                    }
                    loaderService.setLoader(false);
                    if(callback){
                        return callback(true);
                    }
                }else{
                    loaderService.setLoader(false);
                    if(callback){
                        return callback(false);
                    }
                }
            }).catch((err) => {
                Vue.notify({
                group: "foo",
                type: "error",
                title: `${err}`,
                duration: 4000,
                });
                addTrace(err, 4);
                if (callback) return callback(false);
            });
        },
        // save setting on click on save button
        saveSettings: function () {
            if(this.settingsForm.arrDepartment.length==0){
                Vue.notify({
                    group: "foo",
                    type: "error",
                    title:this.translationCode[this.$i18n.locale][60381]?.LongText,
                    duration: 4000,
                });
            }
            else{
                let arrDepartment=[];
                for(var i=0;i<this.settingsForm.arrDepartment.length;i++){
                      arrDepartment.push(this.settingsForm.arrDepartment[i].deptNo==undefined?this.settingsForm.arrDepartment[i]:this.settingsForm.arrDepartment[i].deptNo)
                }
                let arrBranch=[];
                for(var j=0;j<this.settingsForm.selectedCollaborationBranch.length;j++){
                    arrBranch.push(this.settingsForm.selectedCollaborationBranch[j].value==undefined?this.settingsForm.selectedCollaborationBranch[j]:this.settingsForm.selectedCollaborationBranch[j].value)
                }
                let self=this;
                let obj={
                    isNotificationEnable:self.isNotificationEnable,
                    isEmailNotificationEnable:self.settingsForm.emailNotification,
                    isBrowserNotificationEnable:self.settingsForm.browserNotification,
                    arrEntryStatus:self.arrEntryStatus,
                    arrCollaborationEntryBranch:arrBranch,
                    branchNo: self.settingsForm.createdBranch,
                    arrDepartment: arrDepartment,
                    languageCode: sessionStorage.getItem("localeLang"),
                    translationLanguage:self.translateLanguageCode,
                    arrSelectedColumn:this.columns
                };
                loaderService.setLoader(true);
                setBranchAndDepartment(obj, function (res) {
                    if (res && res.status == 200) {
                        BranchorDepartmentChangeService.setBranchorDepartment({type:"branch",value:obj.branchNo})
                        self.closePopup();
                        loaderService.setLoader(false);
                    
                    }else{
                        self.closePopup()
                        loaderService.setLoader(false);
                    }
                })
            }
        },
        // set changed branch on change 
        onChangeBranch(){
            this.searchBranch=""
            this.createdBranches = this.createdBranchesCopy;
        },
        // search branch fromj dropdown
        searchBranches() {
            if (!this.searchBranch) {
                this.createdBranches = this.createdBranchesCopy;
            }
            this.createdBranches = this.createdBranchesCopy.filter((branch) => {
                return branch?.text?.toLowerCase().indexOf(this.searchBranch?.toLowerCase()) > -1;
            });
        },
        // select all branch from branch dropdown
        onChangeAllBranch(){
            this.$nextTick(() => {
                if (this.selectAllBranches) {
                    this.settingsForm.selectedCollaborationBranch = []
                } else {
                    this.settingsForm.selectedCollaborationBranch = this.branchesCopy.slice()
                }
            })
        },
        // search branch from branchy dropdown 
        searchAllBranches() {
            if (!this.searchAllBranch) {
                this.allCollaborationBranch = this.branchesCopy;
            }
            this.allCollaborationBranch = this.branchesCopy.filter((branch) => {
                return branch?.text?.toLowerCase().indexOf(this.searchAllBranch?.toLowerCase()) > -1;
            });
        },
        // remove all serches on click on remove button 
        removeSearch(){
            this.searchAllBranch="";
            this.allCollaborationBranch = this.branchesCopy.filter((branch) => {
                return branch?.text?.toLowerCase().indexOf(this.searchAllBranch?.toLowerCase()) > -1;
            });
            this.searchDepartment="";
            this.departments = this.departmentsCopy.filter((dept) => {
                return dept?.text?.toLowerCase().indexOf(this.searchDepartment?.toLowerCase()) > -1;
            });
        },
        // set changed department to variable
        onchangeDepartment(){
            this.$nextTick(() => {
                if (this.selectAllDepartments) {
                    this.settingsForm.arrDepartment = []
                } else {
                    this.settingsForm.arrDepartment = this.departmentsCopy.slice()
                }
            })
        },
        // search department from dropdown dropdown
        searchDepartments() {
            if (!this.searchDepartment) {
                this.departments = this.departmentsCopy;
            }
            this.departments = this.departmentsCopy.filter((dept) => {
                return dept?.text?.toLowerCase().indexOf(this.searchDepartment?.toLowerCase()) > -1;
            });
        },
        // set email notification value on change
        selectemailNotificationOption: function(){
            if(this.settingsForm.emailNotification==false){
                this.settingsForm.browserNotification=true
            }
        },
        // set browser notification value on change
        selectbrowserNotificationOption: function(){
            if(this.settingsForm.browserNotification==false){
                this.settingsForm.emailNotification=true
            }
        }
    },
  };
</script>

<style scoped>
  .header-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .close-btn {
    background-color: #cfcfcf !important;
    border-color: #cfcfcf !important;
    color: #fff !important;
  }
  .save-btn {
    background-color: #ffa200 !important;
    border-color: #ffa200 !important;
    color: #fff !important;
  }
  .p-14 {
    padding: 14px;
  }
  .m-0 {
    margin: 0px;
  }
  .ptb-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .padingbtm {
    padding-bottom: 20px;
  }
  .note-content {
    color: #212121;
    font-size: 18px;
  }
  .modal-content-wrapper {
    height: calc(100vh - 310px);
    overflow-y: auto;
  }
  .toggle-switch {
    font-size: 14px;
  }
  .toggle-switch .v-input--selection-controls {
    margin: 0px !important;
    padding: 0px !important;
  }
  .d-flex{
    display: flex;
  }
  .f-16{
    margin-left: -6px;
    font-size: 16px;
  }
  .pm-0{
    padding: 0;
    margin: 0;
  }
  .theme--light.v-select .v-select__selections {
    color: rgba(0, 0, 0, 0.87);
    min-height: unset;
    max-height: 33px !important;
    overflow-y: auto;
    margin-top: 10px;
}
.v-text-field.v-text-field--solo .v-input__control{
    min-height: 10px;
}
  @media (max-width: 1200px){
    .f-16{
    margin-left: 10px;
    font-size: 16px;
    line-height: 16px;
  }
  }

.theme--light.indigo-1--text.text--darken-5 {
    color: rgba(0, 0, 0, 0.54);
}

.v-application  .indigo-1--text.text--darken-5.select_all {
    color: #1976d2;
    caret-color: #1976d2;
}
.v-list-item.v-list-item--link.theme--light.select_bg{
    background: #e4effa;
}
.theme--light.v-list-item .v-list-item__content .v-list-item__title{
    color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-list-item .v-list-item__content.select_all .v-list-item__title{
    color: rgb(25 118 210);
}

.theme--light.v-list-item--active::before {
    opacity: 0.12;
}
/* .v-list-item--link:before{
    background: #e4effa;
} */
.v-list-item--highlighted::before{
    opacity: 0;
}
.theme--light.v-list-item.v-list-item--highlighted::before {
    opacity: 0;
}

</style>