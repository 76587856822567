<template>
  <div class="row">
    <div class="col-lg-3 col-4 c-remove-transition mt-10" v-if="(closesidebar && !detailForm) && !$route.params.token">
      <SidePanel />
    </div>
    <div
      v-bind:class="{ 'col-lg-9 col-8': (closesidebar && !detailForm), 'col-lg-9 col-12 col-lg-12  c-transition': (!closesidebar || detailForm) }">
      <!-- <div class="col-lg-12"> -->
      <div class="collaboration-width">
        <v-card class="c-v-card">
          <div class="collaboration-card" v-if="!detailForm && !addEntry">
            <div>
              <div class="ml-3 mr-3">
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="w-50">
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="2" lg="2"
                        style="padding-right:0px !important;padding-bottom: 0px !important;">
                        <div>
                          <v-tooltip v-if="closesidebar && !detailForm" bottom max-width="600px">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">

                                <v-btn rounded class="setclose-btn mr-2 mb-2 c-btn-space"
                                  v-if="closesidebar && !detailForm" text @click="setStatusClose()">
                                  <!-- <v-icon @click="showSidebar = !showSidebar" id="menu">mdi-menu</v-icon> -->
                                  <img :src="pin" id="menu" />
                                </v-btn>
                              </span>
                            </template>

                            <span>
                              {{ "Click to Hide Sidebar " }}
                            </span>
                          </v-tooltip>

                          <v-tooltip v-if="!closesidebar" bottom max-width="600px">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-btn rounded class="setclose-btn mr-2 mb-2 " v-if="!closesidebar" text
                                  @click="setStatusClose()">
                                  <img :src="pin" id="menu" style="transform: rotate(270deg)" />
                                </v-btn>
                              </span>
                            </template>

                            <span>
                              <div>{{ "Click to Show Sidebar " }}</div>
                            </span>
                          </v-tooltip>

                        </div>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="10" lg="10"
                        style="padding-left:0px !important;padding-bottom: 0px !important;">
                        <v-card-title class="main-table-title">{{
                          "Collaboration App" | translate("60310.LongText")
                        }}: (<span @click="openSettings()" style="cursor:pointer;text-decoration: underline;">{{
  "Settings" | translate("60371.ShortText")
}}</span>&nbsp;
                          <v-icon>
                            mdi-cog
                          </v-icon>)
                        </v-card-title>

                        <v-dialog v-model="settingsForm" persistent max-width="750px" class="add-entry-modal">
                          <div v-if="settingsForm">
                            <CollaborationSettings :createdBranches="createdBranches" :departments="departments"
                              :translationCode="translationCode" :arrEntryStatus="selectedCollaborationStatus"
                              :allCollaborationBranch="allCollaborationBranch"
                              :columns="columns" @close="closeAllDialogs">
                            </CollaborationSettings>
                          </div>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="2" lg="2"
                        style="padding-right:0px !important;padding-top: 0px !important;"></v-col>
                      <v-col class="d-flex" cols="12" sm="10" lg="10"
                        style="padding-left:0px !important;padding-top: 0px !important;">
                        <div class="header-wrapper c-width">
                          <div class="d-flex align-items-center justify-content-start">
                            <p class="branch-text" v-if="selectedBranch">Branch: {{ selectedBranch.text }}<span
                                v-if="selectedDepartmentList">,&nbsp;{{ selectedDepartmentList }}</span></p>
                          </div>
                        </div>

                      </v-col>
                    </v-row>
                  </div>
                  <div class="w-50">
                    <div class="m-right d-flex "
                      v-bind:class="[notificationCount || notificationUpdateCount ? 'justify-content-between' : 'justify-content-end']">
                      <v-btn v-if="notificationCount != 0 || notificationUpdateCount > 0" rounded small top
                        style=" background-color: whitesmoke; z-index: 2 ;height: 35px;" @click="getLatestData()">
                        <v-icon style="background-color: #ffa200; border-radius: 50%; color: white; height: 19px;" left
                          size="medium">mdi-autorenew</v-icon>
                        <span v-if="notificationCount > 0" style="font-size:0.875rem ; ">{{ notificationCount }} New {{
                          notificationCount > 1 ?
                          'Tickets' : 'Ticket' }}</span>
                        <span v-if="notificationCount != 0 && notificationUpdateCount > 0" style="font-size:0.875rem ;"> |
                        </span>
                        <span v-if="notificationUpdateCount > 0" style="font-size:0.875rem ;"> {{ notificationUpdateCount
                        }} update</span>
                      </v-btn>
                      <v-dialog v-model="addForm" persistent max-width="900px" class="add-entry-modal">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn rounded v-bind="attrs" v-on="on" class="collaboration-dialog-btn" data-cy="createEntry">
                            {{ "Add Collaboration" | translate("60312.LongText") }}
                          </v-btn>
                        </template>
                        <div v-if="addForm && !addEntry">
                          <CollaborationAdd :selectedRowData="selectedRowData" :translationCode="translationCode"
                            :createdBranches="createdBranches" :assignedBranches="assignedBranches" :functions="functions"
                            :departments="departments" :collaborationTypes="collaborationTypes"
                            :collaborationStatus="collaborationStatus" :prorities="prorities" :fileTypes="fileTypes"
                            @close="closeAllDialogs">
                          </CollaborationAdd>
                        </div>
                      </v-dialog>
                    </div>
                  </div>
                </div>
                <v-row align="center">
                  <v-col class="d-flex" cols="12" sm="4" lg="6"></v-col>
                  <v-col class="d-flex flex-wrap modal-dialog-wrapper justify-content-end align-items-center"
                    style="padding-top:0px !important;" cols="12" sm="8" lg="6">
                    <v-text-field v-model="search" data-cy="taskInput" :label="'Search' | translate('60322.LongText')"
                      hide-details class="custom-field" style="padding-top: 0px !important;margin-right: 15px;"
                      @input="searchFilter">
                      <template slot="append">
                        <v-icon v-if="search" @click="refreshList" data-cy=removeInput>mdi-close</v-icon>

                      </template>
                    </v-text-field>
                    <!-- refresh button -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn icon style="margin-right: 15px;" @click="getLatestData()">
                            <v-icon>
                              mdi-refresh
                            </v-icon>
                          </v-btn>
                        </span>
                      </template>
                      Refresh
                    </v-tooltip>
                    <!-- Filter display button -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn icon style="margin-right: 15px;" @click="showFilter = !showFilter"
                            v-bind:class="[!showFilter ? 'active-filter-btn' : '']">
                            <v-icon id="filter" class="filter-icon ">
                              mdi-format-list-bulleted-square</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      Filter Option
                    </v-tooltip>
                    <v-menu style="margin-right: 15px;" bottom left :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <img src="@/assets/images/icons/icon-select_columns_dark.svg"
                                  v-if="selectedTableColumns && selectedTableColumns.length !== columns.length"
                                  style="height: 25px;width: 25px;">
                                <img src="@/assets/images/icons/icon-select_columns.svg" v-else
                                  style="height: 25px;width: 25px;">
                              </v-btn>
                            </template>
                            Select Columns
                          </v-tooltip>
                        </span>
                      </template>
                      <v-list>
                        <div v-for="(item, index) in columns" :key="index">
                          <v-list-item>
                            <v-checkbox :value="item" :label="item.text" v-model="selectedTableColumns"
                              @change="onchangeTableHeader(index)"
                              :disabled="selectedTableColumns.length == 1 && item.value == selectedTableColumns[0].value"></v-checkbox>
                          </v-list-item>
                        </div>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col class="d-flex flex-wrap mb-4" cols="12" sm="12" v-if="!showFilter">
                    <v-row align="center" class="flex-wrap w-100">
                      <v-col class="d-flex pt-0 pb-0" cols="12" sm="3">
                        <v-select multiple :items="collaborationStatus" :label="'Status' | translate('60303.ShortText')"
                          v-model="selectedCollaborationStatus" id="status" v-on:change="getCollaborationListfn()"
                          hide-details class="custom-field"></v-select>
                      </v-col>

                      <v-col class="d-flex pt-0 pb-0 dropdown-h c-select-input" cols="12" sm="3">
                        <v-select multiple :items="workwithBranch" :label="'Branch'" v-model="selectedCollaborationBranch"
                          id="collaborationBranch" v-on:change="getCollaborationListfn(); removeSearch();" hide-details
                          class="custom-field">
                          <template v-slot:prepend-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-text-field v-model="searchAllBranch" autofocus
                                  :placeholder="'Search' | translate('60322.ShortText')"
                                  @input="searchAllBranches"></v-text-field>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item ripple @mousedown.prevent @click="onChangeAllBranch"
                              v-if="workwithBranch.length != 0"
                              v-bind:class="[selectedCollaborationBranch != null && selectedCollaborationBranch.length == workwithBranch.length ? 'select_bg' : '']">
                              <v-list-item-action>
                                <v-icon
                                  :color="selectedCollaborationBranch != null && selectedCollaborationBranch.length > 0 ? 'indigo-1 darken-5' : ''"
                                  v-bind:class="[selectedCollaborationBranch != null && selectedCollaborationBranch.length == workwithBranch.length ? 'select_all' : '']">
                                  {{ iconBranch }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content
                                v-bind:class="[selectedCollaborationBranch != null && selectedCollaborationBranch.length == workwithBranch.length ? 'select_all' : '']">
                                <v-list-item-title>
                                  {{ "Select All" | translate("60382.LongText") }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template></v-select>
                      </v-col>

                      <v-col class="d-flex pt-0 pb-0" cols="12" sm="3">
                        <v-select :items="fileTypes" :label="'FileType' | translate('60307.LongText')"
                          v-model="selectedCollaborationFileType" id="collaborationFileType"
                          v-on:change="getCollaborationListfn()" hide-details class="custom-field"></v-select>
                      </v-col>

                      <div class="d-flex align-center justify-content-start ps-2 pt-4" cols="2" sm="3">
                        <div>
                          <date-picker v-model="pickerDates" range :style="{ width: '200px' }" placeholder="Any Date"
                            :shortcuts="shortcuts" @clear="() => {
                              this.startingDate = '';
                              this.endingDate = '';
                              this.innerValue = [new Date(NaN), new Date(NaN)];
                              this.getCollaborationListfn()
                            }">
                            <template v-slot:content>
                              <calendar-panel :value="innerValue" :get-classes="getCalenderClasses"
                                @select="handleSelect"></calendar-panel>
                            </template>
                          </date-picker>
                        </div>

                        <div class="ps-2">
                          <v-icon @click="clearFilter()">mdi-close</v-icon>
                        </div>
                      </div>

                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div>
              <v-data-table ref="vtable" class="collaboration-table-wrapper" :headers="currentTableHeaders"
                :items="tableList" :page="page" :items-per-page="itemsPerPage" :footer-props="footerProps"
                :sortBy="sortBy" :sortDesc="sortDesc" :groupBy="groupBy" :multiSort="multiSort" :mustSort="mustSort"
                :server-items-length="totalNumberOfItems" @update:options="updatePagination" @click:row="openDetailPage"
                hide-default-header data-cy="entryDetail">
                <template v-slot:[`item.createdBranch.branchId`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{
                        item["createdBranch.branchId"]
                      }}</span>
                    </template>
                    <span>{{ item[`createdBranch.nametooltip`] }}</span><br />
                    <span>{{ item[`createdBranch.countrytooltip`] }}</span><br />
                  </v-tooltip>
                </template>
                <template v-slot:[`item.collaborationEntry.assignedBranch.branchId`]="{
                  item,
                }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{
                        item["collaborationEntry.assignedBranch.branchId"]
                      }}</span>
                    </template>
                    <span>{{ item[`assignedBranch.assignedNametooltip`] }}</span><br />
                    <span>{{ item[`assignedBranch.assignedCountrytooltip`] }}</span><br />
                  </v-tooltip>
                </template>
                <template v-slot:[`item.collaborationEntry.tags`]="{ item }">
                  <span><a @click.stop="assignValueToSearch(i)" v-for="i in item['collaborationEntry.tags']" :data-cy="i"
                      :key="i">{{ i }}
                    </a></span>
                </template>
                <template v-slot:header="{ props }">
                  <thead class="v-data-table-header">
                    <draggable v-model="currentTableHeaders" tag="tr" @change="setHeader($event)">
                      <th v-for="(head, index) of props.headers" :key="index" :class="{ active: head.isActive == true }"
                        class="text-start sortable">
                        <div v-if="head.isSortable !== false" @click="sortTableData(head.value)">
                          {{ head.text }}
                          <v-icon v-if="head.isActive == true" style="font-size: 18px;color: rgba(0, 0, 0, 0.87);"> {{
                            sortDesc[0]
                            ? 'mdi-arrow-down' : 'mdi-arrow-up' }} </v-icon>
                          <v-icon v-if="!head.isActive" class="arrow-visible"> {{ sortDesc[0] ? 'mdi-arrow-down'
                            :
                            'mdi-arrow-up' }} </v-icon>
                        </div>
                        <div v-if="head.isSortable == false">
                          {{ head.text }}
                        </div>
                      </th>
                    </draggable>
                  </thead>
                </template>
                <template v-slot:[`item.collaborationEntry.consignmentNumber`]="{ item }">
                  <div>
                    {{ item['collaborationEntry.consignmentNumber'] }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon size="small"
                            @click="copyConsignmentNumber(item['collaborationEntry.consignmentNumber'], $event)"
                            v-if="item['collaborationEntry.consignmentNumber']">
                            mdi-content-copy
                          </v-icon>
                        </span>
                      </template>
                      <span> Copy No.</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </div>
          </div>
          <div v-if="detailForm">
            <div class="p-20 c-width">
              <div class="detail-header-wrapper">
                <div>
                  <v-chip dark v-if="showBackButton == true" @click="closedetailpage">
                    <v-icon dark> mdi-arrow-left-thick </v-icon>
                  </v-chip>
                </div>
                <div>
                  <span class="detail-title">{{
                    "Collaboration Entry Details" | translate("60315.LongText")
                  }}</span>
                </div>
                <div></div>
              </div>
              <div class="pagination_wrapper" v-if="showpagination == true">
                <div>
                  <button class="navigate-button" :disabled="navigationInfo.previous == null ? true : false"
                    @click="pagintationController('first')">
                    &lt;&lt;
                  </button>
                  <button class="navigate-button" :disabled="navigationInfo.previous == null ? true : false"
                    @click="pagintationController('back')">
                    &lt;
                  </button>
                  <button class="navigate-button" @click="OpenAddEntry()">
                    {{
                      "new" | translate("60152.LongText")
                    }}
                  </button>
                  <button class="navigate-button" :disabled="navigationInfo.next == null ? true : false"
                    @click="pagintationController('next')">
                    &gt;
                  </button>
                  <button class="navigate-button" :disabled="navigationInfo.next == null ? true : false"
                    @click="pagintationController('last')">
                    &gt;&gt;
                  </button>
                </div>
                <div>
                  <span>{{ recordInfo.currentPosition }}&nbsp;of&nbsp;1-{{
                    recordInfo.totalLength
                  }}</span>
                </div>
              </div>
              <div class="collaboration-entry-detail">
                <CollaborationDetail class="w-100" :selectedRowData="selectedRowData" :translationCode="translationCode"
                  :assignedBranches="assignedBranches" :createdBranches="createdBranches"
                  :arrEntryStatus="selectedCollaborationStatus" :arrCollaborationEntryBranch="selectedCollaborationBranch"
                  :functions="functions" :departments="departments" :onChange="config"
                  @search="assignValueToSearch($event)" @back="closedetailpage()"></CollaborationDetail>
              </div>
            </div>
          </div>
          <div v-if="addEntry">
            <CollaborationAdd :selectedRowData="selectedRowData" :translationCode="translationCode"
              :createdBranches="createdBranches" :assignedBranches="assignedBranches" :functions="functions"
              :departments="departments" :collaborationTypes="collaborationTypes"
              :collaborationStatus="collaborationStatus" :prorities="prorities" :fileTypes="fileTypes"
              @close="closeAllDialogs">
            </CollaborationAdd>
          </div>
        </v-card>
        <div class="overlay-card"
          v-if="displayOverlay && notificationCount > 0 || displayOverlay && notificationUpdateCount > 0"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  languageTranslationService,
  loaderService,
  pageChangeService,
  branchChangeService,
  departmentChangeService,
  BranchorDepartmentChangeService
} from "@/services/observables";
import { BehaviorSubject } from "rxjs";
import {
  decodePluginToken,
  loadCollaboration,
  getAllAvailableCreatedBranch,
  getAllAvailableAssignedBranch,
  getAllAvailableDynamicDropDowns,
  loadCollaborationById,
  getBranchAndDepartment,
  setBranchAndDepartment,
  setLangCode,
} from "@/services/collaboration.service";
import moment from "moment";
import CollaborationAdd from "./Collabortaion-add.vue";
import CollaborationSettings from "./Collaboration-settings.vue";
import CollaborationDetail from "./Collaboration-detail.vue";
import Vue from "vue";
import { addTrace } from "@/services/logging";
import { versionApi } from "@/services/service-methods";
import SidePanel from "@/components/SidePanel.vue";
import pin from "../../../src/assets/images/icons/pin.svg";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { HubConnectionBuilder } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
import debounce from "debounce";
import draggable from "vuedraggable";

const { CalendarPanel } = DatePicker;

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}
export default {
  name: "Collaboration",
  components: {
    DatePicker,
    CalendarPanel,
    CollaborationAdd,
    CollaborationDetail,
    CollaborationSettings,
    SidePanel,
    draggable
  },
  data() {

    localStorage.setItem("isOpenSideBar", true);

    return {
      settingsForm: false,
      pickerDates: [],
      innerValue: [new Date(NaN), new Date(NaN)],
      shortcuts: [
        {
          text: "Today",
          onClick: () => {
            const start = new Date();
            const end = new Date();
            const date = [start, end];
            this.startingDate = moment(date[0]).format("YYYY-MM-DD");
            this.endingDate = moment(date[1]).format("YYYY-MM-DD");
            this.getCollaborationListfn()
            return date;
          },
        },
        {
          text: "Yesterday",
          onClick: () => {
            const start = new Date();
            const end = new Date();
            start.setTime(end.getTime() - 24 * 3600 * 1000);
            end.setTime(end.getTime() - 24 * 3600 * 1000);
            const date = [start, end];
            this.startingDate = moment(date[0]).format("YYYY-MM-DD");
            this.endingDate = moment(date[1]).format("YYYY-MM-DD");
            this.getCollaborationListfn()
            return date;
          },
        },
        {
          text: "Last Week",
          onClick: () => {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
            const date = [start, end];
            this.startingDate = moment(date[0]).format("YYYY-MM-DD");
            this.endingDate = moment(date[1]).format("YYYY-MM-DD");
            this.getCollaborationListfn()
            return date;
          },
        },
        {
          text: "Last Month",
          onClick: () => {
            const start = new Date();
            const end = new Date();
            start.setTime(end.getTime() - 30 * 24 * 3600 * 1000);
            const date = [start, end];
            this.startingDate = moment(date[0]).format("YYYY-MM-DD");
            this.endingDate = moment(date[1]).format("YYYY-MM-DD");
            this.getCollaborationListfn()
            return date;
          },
        },
      ],
      search: "",
      pluginObjData: "",
      page: 1,
      itemsPerPage: 10,
      footerProps: { 'items-per-page-options': [5, 10, 15, 50, 100] },
      sortDesc: [true],
      sortBy: ["objectInfo.dbChangedDtTm"],
      groupBy: [],
      multiSort: false,
      mustSort: true,
      languages: [],
      setLocale: null,
      items: ["data", "fees", "new data"],
      showFilter: false,
      showSidebar: true,
      createdBranches: [],
      assignedBranches: [],
      allCollaborationBranch: [],
      functions: [],
      departments: [],
      collaborationTypes: [],
      collaborationStatus: [],
      prorities: [],
      fileTypes: [],
      addForm: false,
      addEntry: false,
      detailForm: false,
      tableHeaders: [],
      tableList: [],
      translationCode: null,
      selectedRowData: null,
      totalNumberOfItems: 0,
      selectedCollaborationStatus: null,
      selectedCollaborationBranch: null,
      selectedCollaborationFileType: null,
      entryStatusConfig: null,
      entryBranchConfig: null,
      createdFileTypeName: null,
      createdFileTypeValue: '',
      collaborationEntryBranch: '',
      loader: false,
      showBackButton: true,
      showpagination: false,
      currentPage: 0,
      navigationInfo: {},
      recordInfo: {},
      selectedBranch: "",
      mainBranch: "",
      selectedDepartment: [],
      selectedDepartmentList: "",
      userSettingLang: 'en-UK',
      closesidebar: false,
      pin: pin,
      config: new BehaviorSubject(null),
      startingDate: '',
      endingDate: '',
      isNotificationEnable: true,
      isBrowserNotificationEnable: true,
      searchAllBranch: "",
      branchesCopy: [],
      workwithBranch: [],
      currentTableHeaders: [],
      columns: [],
      selectedTableColumns: [],
      previousSearch: '',
      previousFileType: '',
      previousStatus: '',
      previousDate: '',
      previousSelectedBranch: '',
      notificationCount: 0,
      displayOverlay: false,
      notificationUpdateCount: 0,
      notificationticketArray: [],
      openedRecordId: '',
      totalNotificationsCount: 0,
    };
  },
  filters: {
    date(date) {
      return new Intl.DateTimeFormat("en-US").format(date);
    }
  },
  created() {
    // display or hide overlay on window focus
    window.onfocus = () => {
      if (document.getElementsByClassName("collaboration-card").length > 0) {
        this.displayOverlay = true;
      }
      setTimeout(() => {
        this.displayOverlay = false;
      }, 2000);
    };
    if (!this.$route.params.token && !this.$route.params.pluginToken) {
      Notification.requestPermission((perm) => {
        if (perm != "granted") {
          alert(this.getTranslatedLanguage(60358, 'LongText'))
        }
      })
    }
    // conenct with signalR service
    this.signalRConnection();
    
    let self = this;
    if (this.$route.params.token && this.$route.params.pluginToken) {
      this.decodePluginToken(this.$route.params.pluginToken, function (resPluginObj) {
        if (resPluginObj.cacheData != null) {
          self.pluginObjData = resPluginObj.cacheData
          self.closesidebar = false
          localStorage.setItem("isOpenSideBar", false);
          versionApi(function () {
            setLangCode(function () {
              self.setFilterLanguge();
              self.initLoad();
            });
          });
        } else {
          Vue.notify({
            group: "foo",
            type: "error",
            title: `No Configuation payload found for this plugin`,
            duration: 4000,
          });
        }
      })
    }
    else if (this.$route.params.sharedId) {
      versionApi(function () {
        setLangCode(function () {
          self.setFilterLanguge();
          self.initLoad();
        });
      });

    } else {
      self.initLoad();
    }
  },
  computed: {
    selectAllBranches() {
      return this.selectedCollaborationBranch?.length === this.branchesCopy.length
    },
    iconBranch() {
      if (this.selectAllBranches) return 'mdi-close-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    // open setting page on click on setting button
    openSettings: function () {
      this.settingsForm = true
    },
    // get translated code 
    getTranslatedLanguage: function (value, text) {
      return this.translationCode[this.$i18n.locale][value] == undefined ? this.translationCode['en'][value][text] : this.translationCode[this.$i18n.locale][value][text] == undefined ? this.translationCode['en'][value][text] : this.translationCode[this.$i18n.locale][value][text]
    },
    // get and set class for calender
    getCalenderClasses(cellDate, currentDates, classes) {
      if (
        !/disabled|active|not-current-month/.test(classes) &&
        currentDates.length === 2 &&
        cellDate.getTime() > currentDates[0].getTime() &&
        cellDate.getTime() < currentDates[1].getTime()
      ) {
        return "in-range";
      }
      return "";
    },
// manage date range filter
    handleSelect(date) {
      const [startValue, endValue] = this.innerValue;
      if (isValidDate(startValue) && !isValidDate(endValue)) {
        if (startValue.getTime() > date.getTime()) {
          this.innerValue = [date, startValue];
        } else {
          this.innerValue = [startValue, date];
        }
        this.pickerDates = this.innerValue;
        this.startingDate = moment(this.pickerDates[0]).format("YYYY-MM-DD");
        this.endingDate = moment(this.pickerDates[1]).format("YYYY-MM-DD");
        this.getCollaborationListfn()
      } else {
        this.innerValue = [date, new Date(NaN)];
      }
    },
    // set ticket status to closeon click to close buton
    setStatusClose: function () {
      this.closesidebar = this.closesidebar == true ? false : true
      localStorage.setItem("isOpenSideBar", this.closesidebar);
    },
    // decode plugin token when open ticket in plugin
    decodePluginToken: function (parametersetId, callback) {
      decodePluginToken(parametersetId)
        .then((res) => {
          return callback(res.data)
        })
    },
    // get and set data on create a component
    initLoad: function () {
      this.getAllDropDownValues();
      this.loadLocaleTexts();
      let self = this;
      Vue.filter("translate", function (fallbackStr, str) {
        if (str != undefined) {
          let value = str.split('.')[0];
          let text = str.split('.')[1];
          return self.translationCode[self.$i18n.locale][value] == undefined ? self.translationCode['en'][value][text] : self.translationCode[self.$i18n.locale][value][text] == undefined ? self.translationCode['en'][value][text] : self.translationCode[self.$i18n.locale][value][text]
        } else {
          return fallbackStr
        }
      });
      self.getAllAvailableCreatedBranches(function (resBranch) {
        if (resBranch) {
          self.getAllAvailableDepartments(function (resDept) {
            if (resDept) {
              self.checkSelectedBranchDept();
            }
          });
        }
      });
      languageTranslationService.getLang().subscribe(() => {
        this.setHeaders();
        this.getAllDropDownValues();
        let self = this;
        this.getAllAvailableCreatedBranches(function (resBranch) {
          if (resBranch) {
            self.getAllAvailableDepartments(function (resDept) {
              if (resDept) {
                self.refreshList();
              }
            });
          }
        });
      });

      //getbranch from service and set app data
      branchChangeService.getBranch().subscribe(() => {
        // this.getAllBranchAndDepartment();
      });
      //getDepartment from service and set app data
      departmentChangeService.getDepartment().subscribe(() => {
        // this.getAllBranchAndDepartment();
      })
      // set data on branch or department change
      BranchorDepartmentChangeService.getBranchorDepartment().subscribe(() => {
        this.getAllBranchAndDepartment();
      })

    },
    // get all created branches and departments value
    getAllBranchAndDepartment(callback) {
      let self = this;
      this.getAllAvailableCreatedBranches(function (resBranch) {
        if (resBranch) {
          self.getAllAvailableDepartments(function (resDept) {
            if (resDept) {
              self.checkSelectedBranchDept(function (resBranchDept) {
                if (resBranchDept) {
                  self.refreshList();
                  self.detailForm = false;
                }
              });
            }
          });
        }
      });
      if (callback) {
        return callback(true);
      }
    },
    // set data acoording to selected language
    setFilterLanguge: function () {
      const objPassedLanguage = this.getLanguageDetail(this.userSettingLang);
      if (objPassedLanguage != null) {
        sessionStorage.setItem("langCode", objPassedLanguage.SysValueNo);
        this.$i18n.locale = objPassedLanguage.LangText;
        this.$cookie.set("localeLang", objPassedLanguage.SysValueId);
        this.$cookie.set("localeLangCode", objPassedLanguage.SysValueNo);
      }
    },
    // get and set language details
    getLanguageDetail(langTXT) {
      const languages = JSON.parse(sessionStorage.getItem("languages"));
      let objLanguage = null;
      if (languages) {
        objLanguage = languages.find(
          (x) => x.SysValueId == langTXT
        );
        if (typeof objLanguage != "object") {
          objLanguage = null;
        }
      }
      return objLanguage;
    },
    // get and set text values on selected language code
    loadLocaleTexts: function (callback) {
      const texts = {};
      const languages = JSON.parse(sessionStorage.getItem("languages"));
      if (languages) {
        languages.map((language) => {
          texts[language.LangText] = JSON.parse(
            sessionStorage.getItem(language.LangText + "-localeTexts")
          );
        });
      }
      this.translationCode = texts;
      if (callback) {
        return callback(this.translationCode);
      } else return this.translationCode;
    },
    // manage pagination in plugin
    updatePagination: function (obj) {
      if (this.loader != true) {
        loaderService.setLoader(true);
        if (obj?.itemsPerPage < 0) {
          obj.itemsPerPage = this.totalNumberOfItems;
        }
        this.sortDesc = obj?.sortDesc;
        this.sortBy = obj?.sortBy;
        this.page = obj?.page;
        this.itemsPerPage = obj?.itemsPerPage;
        let self = this;
        if (
          this.translationCode &&
          this.selectedDepartment && this.selectedDepartment.length > 0 &&
          this.selectedBranch
        ) {
          self.getCollaborationListfn();
        } else {
          setLangCode(function () {
            self.loadLocaleTexts(function () {
              self.checkSelectedBranchDept(function () {
                // self.checkSelectedBranchDept(function (resBranchDept) {
                // if(resBranchDept){
                self.getCollaborationListfn();
                // }
              });
            });
          });
        }

        return obj;
      } else {
        this.loader = false;
      }
    },
    // search and set data after stop typing 
    searchFilter: debounce(function () {
      if (!this.search || this.search.length >= 2) {
        let obj = {
          sortDesc: this.sortDesc,
          page: this.page,
          itemsPerPage: this.itemsPerPage,
          sortBy: this.sortBy,
        };
        this.updatePagination(obj)
      }
    }, 1000),
    // check selected language code
    checkSelectedLangCode: function () {
      return this.$cookie.get("localeLang")
        ? this.$cookie.get("localeLang")
        : sessionStorage.getItem("localeLang");
    },

    // get collaboration list 
    getCollaborationList: function (config) {
      loaderService.setLoader(true)
      loadCollaboration(config)
        .then((res) => {
          loaderService.setLoader(false);
          if (res?.status == 200) {
            this.totalNumberOfItems = res?.data?.totalCount;
            if (this.$route.params.pluginToken) {
              this.showBackButton = false;
              this.tableList = res.data.result;
              this.setDetails();
            } else if (this.$route.params.sharedId) {
              this.getCollaborationById(this.$route.params.sharedId, true);
            } else {
              this.setHeaders();
              this.getTableList(res.data?.result);
            }
          } else if (res?.status == 204) {
            this.totalNumberOfItems = 0;
            this.tableHeaders = [];
            this.tableList = [];
            if (this.$route.params.pluginToken) {
              this.showBackButton = false;
              this.setDetails();
            } else if (this.$route.params.sharedId) {
              this.setDetails();
            }
          }
        })
        .catch((err) => {
          console.log("getlist:", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          loaderService.setLoader(false);
        });
    },

    // set table headers
    setHeaders: function () {
      this.tableHeaders = [
        {
          text: this.getTranslatedLanguage(60301, 'LongText'),
          value: "collaborationEntry.createdDtTmLocal",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60386, 'LongText'),
          value: "objectInfo.dbChangedDtTm",
          isSortable: true,
          isActive: true,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60399, 'LongText'),
          value: "objectInfo.dbChangedUser",
          isSortable: false,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60303, 'ShortText'),
          value: "collaborationEntry.entryStatus.longText",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60370, 'ShortText'),
          value: "collaborationEntry.consignmentNumber",
          isSortable: false,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60306, 'LongText'),
          value: "collaborationEntry.priority.shortText",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60304, 'LongText'),
          value: "collaborationEntry.entryType.longText",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60305, 'LongText'),
          value: "collaborationEntry.assignedBranch.branchId",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60160, 'LongText'),
          value: "collaborationEntry.assignedDepartment.shortText",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60071, 'ShortText'),
          value: "collaborationEntry.assignedRole.shortText",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60300, 'LongText'),
          value: "createdBranch.branchId",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60394, 'LongText'),
          value: "collaborationEntry.createdDepartment.longText",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60393, 'ShortText'),
          value: "collaborationEntry.createdRole.shortText",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60302, 'LongText'),
          value: "objectInfo.dbCreatedUser",
          isSortable: false,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60307, 'LongText'),
          value: "collaborationEntry.fileType.longText",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60308, 'LongText'),
          value: "collaborationEntry.fileId",
          isSortable: true,
          isActive: false,
          isDisplay: true,
        },
        {
          text: this.getTranslatedLanguage(60309, 'LongText'),
          value: "collaborationEntry.tags",
          isSortable: false,
          isActive: false,
          isDisplay: true,
        },
      ];
      this.tableHeaders.forEach((header) => {
        if (header.value === this.sortBy[0]) {
          header.isActive = true;
        } else {
          header.isActive = false;
        }
      });
      if (this.selectedTableColumns.length === 0) {
        this.columns = [...this.tableHeaders]
        this.selectedTableColumns = [...this.tableHeaders]
      }else if(this.tableHeaders.length !== this.columns.length){
        this.columns = [...this.tableHeaders]
        this.selectedTableColumns = [...this.tableHeaders]
      }else{
        this.selectedTableColumns.forEach((col) => {
         let column = this.tableHeaders.find((header) => { return header.value == col.value})
         if(column){
          col.text = column.text;
         }
        });
        this.columns.forEach((col) => {
          let column = this.tableHeaders.find((header) => { return header.value == col.value})
         if(column){
          col.text = column.text;
         }
        });
      }
      this.currentTableHeaders = [...this.selectedTableColumns];
    },

    // get table list data
    getTableList: function (res) {
      this.tableList = [];
      for (let index = 0; index < res.length; index++) {
        let obj = {
          "collaborationEntry.createdDtTmLocal": moment(
            res[index]?.collaborationEntry?.createdDtTmLocal
          ).format("DD.MM.YY HH:mm"),
          "objectInfo.dbChangedDtTm": res[index]?.collaborationEntry?.entryStatus?.sysValueNo !== 12035 ? moment(res[index]?.objectInfo?.dbChangedDtTm).format("DD.MM.YY HH:mm") : '',
          "createdBranch.branchId": (res[index]?.createdBranch?.branchId != null ? res[index]?.createdBranch?.branchId : '' ) +
          (res[index]?.createdBranch?.country != null ? " " + res[index]?.createdBranch?.country : '') +
          (res[index]?.createdBranch?.city != null ?  " - " + res[index]?.createdBranch?.city : '') ,
          "createdBranch.nametooltip": res[index]?.createdBranch?.name
            ? res[index]?.createdBranch?.name
            : "",
          "createdBranch.countrytooltip":
            (res[index]?.createdBranch?.country
              ? res[index]?.createdBranch?.country
              : "") +
            (res[index]?.createdBranch?.zipCode
              ? " - " + res[index]?.createdBranch?.zipCode
              : "") +
            (res[index]?.createdBranch?.city
              ? "  " + res[index]?.createdBranch?.city
              : ""),
          "collaborationEntry.createdRole.shortText":
            res[index]?.collaborationEntry?.createdRole?.shortText,
          "collaborationEntry.createdRole.sysValueId":
            res[index]?.collaborationEntry?.createdRole?.sysValueId,
          "collaborationEntry.createdDepartment.longText":
            res[index]?.collaborationEntry?.createdDepartment?.longText,
          "collaborationEntry.createdDepartment.sysValueId":
            res[index]?.collaborationEntry?.createdDepartment?.sysValueId,
          "objectInfo.dbCreatedUser": res[index]?.objectInfo?.dbCreatedUser,
          "objectInfo.dbChangedUser": res[index]?.collaborationEntry?.entryStatus?.sysValueNo !== 12035 ? res[index]?.objectInfo?.dbChangedUser : '',
          "collaborationEntry.entryType.longText":
            res[index]?.collaborationEntry?.entryType?.longText,
          "collaborationEntry.entryStatus.longText":
            res[index]?.collaborationEntry?.entryStatus?.longText,
          "collaborationEntry.assignedBranch.branchId":
            (res[index]?.collaborationEntry?.assignedBranch?.branchId != null ? res[index]?.collaborationEntry?.assignedBranch?.branchId : '' )+  
            (res[index]?.collaborationEntry?.assignedBranch?.country != null ?  " " + res[index]?.collaborationEntry?.assignedBranch?.country : '' )+  
            (res[index]?.collaborationEntry?.assignedBranch?.city != null ? " - " + res[index]?.collaborationEntry?.assignedBranch?.city : ''),
          "assignedBranch.assignedNametooltip": res[index]?.collaborationEntry
            ?.assignedBranch?.name
            ? res[index]?.collaborationEntry?.assignedBranch?.name
            : "",
          "assignedBranch.assignedCountrytooltip":
            (res[index]?.collaborationEntry?.assignedBranch?.country
              ? res[index]?.collaborationEntry?.assignedBranch?.country
              : "") +
            (res[index]?.collaborationEntry?.assignedBranch?.zipCode
              ? " - " + res[index]?.collaborationEntry?.assignedBranch?.zipCode
              : "") +
            (res[index]?.collaborationEntry?.assignedBranch?.city
              ? "  " + res[index]?.collaborationEntry?.assignedBranch?.city
              : ""),
          "collaborationEntry.assignedRole.shortText":
            res[index]?.collaborationEntry?.assignedRole?.shortText,
          "collaborationEntry.assignedRole.sysValueId":
            res[index]?.collaborationEntry?.assignedRole?.sysValueId,
          "collaborationEntry.assignedDepartment.shortText":
            res[index]?.collaborationEntry?.assignedDepartment?.shortText,
          "collaborationEntry.assignedDepartment.sysValueId":
            res[index]?.collaborationEntry?.assignedDepartment?.sysValueId,
          "collaborationEntry.priority.shortText":
            res[index]?.collaborationEntry?.priority?.shortText,
          "collaborationEntry.fileType.longText":
            res[index]?.collaborationEntry?.fileType?.longText,
          "collaborationEntry.fileId": res[index]?.collaborationEntry?.fileId,
          "collaborationEntry.tags": res[index]?.collaborationEntry?.tags,
          "collaborationEntry.replies": res[index]?.collaborationEntry?.replies,
          "collaborationEntry.repliesOtherInfo":
            res[index]?.collaborationEntry?.repliesOtherInfo,
          "collaborationEntry.comment": res[index]?.collaborationEntry?.comment,
          "collaborationEntry.consignmentNumber": res[index]?.collaborationEntry.consignmentNumber,
          _id: res[index]?._id,
        };
        this.tableList.push(obj);
      }
      if (this.$route.params.pluginToken) {
        this.showBackButton = false;
        this.setDetails();
      } else if (this.$route.params.sharedId) {
        this.setDetails();
      }
    },

    // get collaboration detail by id
    getCollaborationById: function (id, isFromPagination = false) {
      let obj = {
        id: id,
        translationLanguage: this.translationLanguageCode
      }
      loadCollaborationById(obj)
        .then((res) => {
          if (res && res?.status == 200) {

            if (isFromPagination) {
              this.selectedRowData = res.data;
              if (!this.detailForm) {
                this.openDetailPage(res.data)
              }
            }
            pageChangeService.setPageData(res.data);
            this.navigationInfo = res.data.navigationInfo;
            this.recordInfo.currentPosition =
              this.navigationInfo.records.indexOf(id) + 1;
            this.recordInfo.totalLength = this.navigationInfo.records.length;
            loaderService.setLoader(false);
          }
        })
        .catch((err) => {
          console.log("loadCollaborationById:", err)
          loaderService.setLoader(false);
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 2000,
          });
          addTrace(err, 4);
        });
    },

    // set details of collaboration detail
    setDetails() {
      if (this.tableList.length == 0) {
        if (this.$route.params.sharedId) {
          this.getCollaborationById(this.$route.params.sharedId, true);
        } else {
        this.addEntry = true;
        }
      } else {
        if (this.$route.params.sharedId) {
          this.openDetailPage(this.tableList[0], true);
          this.getCollaborationById(this.$route.params.sharedId, true);
        } else {
          let index = this.tableList.findIndex((res) => {
            return res["collaborationEntry.repliesOtherInfo"] == null;
          });
          this.showpagination = this.$route.params.sharedId ? false : true;
          if (index >= 0) {
            //sets current page and opens last unanswered detail page
            this.currentPage = index;
            this.openDetailPage(
              this.tableList.find((res) => {
                return res["collaborationEntry.repliesOtherInfo"] == null;
              })
            );
          } else {
            //if all requests are answered then will open last page
            this.currentPage = 0;
            this.openDetailPage(this.tableList[0]);
          }
          this.getCollaborationById(this.selectedRowData._id);
        }
      }
      // this.currentPage = 0;
    },

    // set pagination control in plugin
    pagintationController: function (action) {

      if (action == "next") {
        if (this.navigationInfo.next != null) {
          this.getCollaborationById(this.navigationInfo.next, true);
        }
      } else if (action == "back") {
        if (this.navigationInfo.previous != null) {
          this.getCollaborationById(this.navigationInfo.previous, true);
        }
      } else if (action == "last") {
        if (this.navigationInfo.last != null) {
          this.getCollaborationById(this.navigationInfo.last, true);
        }
      } else if (action == "first") {
        if (this.navigationInfo.first != null) {
          this.getCollaborationById(this.navigationInfo.first, true);
        }
      }
      //send table data to collobration-detail where api for data will be called according to page number

      // pageChangeService.setPageData(this.tableList[this.currentPage]);
    },

    // get and set dropdown values 
    getAllDropDownValues: function () {
      this.assignedBranches = [];
      this.functions = [];
      this.collaborationTypes = [];
      this.collaborationStatus = [];
      this.prorities = [];
      this.fileTypes = [];
      this.getAllAvailableAssignedBranches();
      this.getAllAvailableFunctions();
      this.getAllAvailableCollaborationTypes();
      this.getAllAvailableCollaborationStatus();
      this.getAllAvailableCollaborationPrios();
      this.getAllAvailableCollaborationFileTypes();
    },

    // get and set created branches
    getAllAvailableCreatedBranches: function (callback) {
      getAllAvailableCreatedBranch()
        .then((res) => {
          loaderService.setLoader(true);
          this.createdBranches = [];
          this.config.next(true);
          if (res.status == 200) {
            res?.data.forEach((element) => {
              if (element?.entityType == "10019" && element?.tenant == 1) {
                let obj = {
                  text:
                    element.shortName +
                    (element.country ? " " + element.country : "") +
                    (element.city ? " - " + element.city : ""),
                  value: element.shortName,
                  entityNo: element.entityNo,
                };
                this.createdBranches.push(obj);
                const matched = this.allCollaborationBranch.find(
                  (subscribe) =>
                    subscribe.value == obj.value
                );
                if (!matched) {
                  this.allCollaborationBranch.push(obj);
                  // this.branchesCopy.push(obj);

                }
              }
            });
            loaderService.setLoader(false);
            if (callback) {
              return callback(true);
            }
          } else {
            loaderService.setLoader(false);
            if (callback) {
              return callback(false);
            }
          }
        })
        .catch((err) => {
          console.log("getcreatedbranches:", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          if (callback) return callback(false);
        });
    },
    // get and set assigned branches
    getAllAvailableAssignedBranches: function () {
      getAllAvailableAssignedBranch()
        .then((res) => {
          if (res.status == 200) {
            res?.data.forEach((element) => {
              if (element?.Tenant == 1) {
                let obj = {
                  text:
                    element.ShortName +
                    (element?.Country ? " " + element.Country : "") +
                    (element?.City ? " - " + element.City : ""),
                  value: element.ShortName,
                };
                this.assignedBranches.push(obj);
                const matched = this.allCollaborationBranch.find(
                  (subscribe) =>
                    subscribe.value == obj.value
                );
                if (!matched) {
                  this.allCollaborationBranch.push(obj);
                  // this.branchesCopy.push(obj);
                }
              }
            });
          }
        })
        .catch((err) => {
          console.log("getassignbranch:", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
        });
    },

    // get and set branch functions options
    getAllAvailableFunctions: function () {
      getAllAvailableDynamicDropDowns("BranchFunction")
        .then((res) => {
          if (res.status == 200) {
            res?.data.forEach((element) => {
              element.TextValues.forEach((ele) => {
                let obj = {
                  text: ele.LongText ? ele.LongText : ele.ShortText,
                  value: element.SysValueId,
                };
                this.functions.push(obj);
              });
            });
          }
        })
        .catch((err) => {
          console.log("getfunctions:", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
        });
    },

    // get and set all departments 
    getAllAvailableDepartments: function (callback) {
      getAllAvailableDynamicDropDowns("Department")
        .then((res) => {
          this.departments = [];
          if (res.status == 200) {
            this.config.next(true);
            res?.data.forEach((element) => {
              let obj = {
                text: "",
                value: "",
                deptNo: element.SysValueNo,
              };
              element.TextValues.forEach((ele) => {
                (obj.text = ele.LongText ? ele.LongText : ele.ShortText),
                  (obj.value = element.SysValueId),
                  this.departments.push(obj);
              });
            });
            if (callback) {
              return callback(true);
            }
          } else {
            if (callback) {
              return callback(false);
            }
          }
        })
        .catch((err) => {
          console.log("getdepts:", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          if (callback) return callback(false);
        });
    },
    // get and set types of collaboration
    getAllAvailableCollaborationTypes: function () {
      getAllAvailableDynamicDropDowns("CollaborationType")
        .then((res) => {
          if (res.status == 200) {
            res?.data.forEach((element) => {
              element.TextValues.forEach((ele) => {
                let obj = {
                  text: ele.LongText ? ele.LongText : ele.ShortText,
                  value: element.SysValueId,
                };
                this.collaborationTypes.push(obj);
              });
            });
          }
        })
        .catch((err) => {
          console.log("gettype:", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
        });
    },
    // get and set status of collaboration
    getAllAvailableCollaborationStatus: function () {
      getAllAvailableDynamicDropDowns("CollaborationStatus")
        .then((res) => {
          if (res.status == 200) {
            res?.data.forEach((element) => {
              element.TextValues.forEach((ele) => {
                let obj = {
                  text: ele.LongText ? ele.LongText : ele.ShortText,
                  value: element.SysValueId,
                };
                this.collaborationStatus.push(obj);
              });
            });
          }
        })
        .catch((err) => {
          console.log("getstatus:", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
        });
    },
    // get and set type of priory options 
    getAllAvailableCollaborationPrios: function () {
      getAllAvailableDynamicDropDowns("CollaborationPrio")
        .then((res) => {
          if (res.status == 200) {
            res?.data.forEach((element) => {
              element.TextValues.forEach((ele) => {
                let obj = {
                  text: ele.LongText ? ele.LongText : ele.ShortText,
                  value: element.SysValueId,
                };
                this.prorities.push(obj);
              });
            });
          }
        })
        .catch((err) => {
          console.log("getprio:", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
        });
    },

    // get and set collaboration file type
    getAllAvailableCollaborationFileTypes: function () {
      getAllAvailableDynamicDropDowns("CollaborationFileType")
        .then((res) => {
          if (res.status == 200) {
            res?.data.forEach((element) => {
              element.TextValues.forEach((ele) => {
                let obj = {
                  text: ele.LongText ? ele.LongText : ele.ShortText,
                  value: element.SysValueId,
                };
                this.fileTypes.push(obj);
              });
            });
          }
        })
        .catch((err) => {
          console.log("getfiletype:", err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
        });
    },

    // open detail page  on click record from table 
    openDetailPage: function (rowData, isFromShared) {
      this.openedRecordId = rowData._id;
      const index = this.notificationticketArray.indexOf(rowData._id);
      if (index > -1) {
        this.notificationticketArray.splice(index, 1);
        this.notificationUpdateCount -= 1;
        this.changeTabTitle();
      }
      this.repliesOptions = [];
      this.selectedRowData = rowData;
      if (!this.addForm) {
        this.addForm = false;
        this.addEntry = false;
        if (isFromShared != true) {
          this.detailForm = true;
        }
      }

    },

    // close all dialoges
    closeAllDialogs: function (res) {
      this.addForm = false;
      this.addEntry = false;
      this.detailForm = false;
      this.settingsForm = false;
      if (res == undefined) {
        this.loader = true;
        this.sortDesc = [true];
        let obj = {
          sortDesc: this.sortDesc,
          page: 1,
          itemsPerPage: this.itemsPerPage,
          sortBy: this.sortBy,
        };
        this.updatePagination(obj);
      } else {
        this.loader = false;
        this.refreshList();
      }
    },

    // close detail page on back button
    closedetailpage: function () {
      this.openedRecordId = '';
      this.addForm = false;
      this.addEntry = false;
      this.detailForm = false;
      this.loader = true;
      this.$route.params.sharedId = false;
      window.history.replaceState('', '', "/");
      this.getLatestData();
    },

    // get letest data and set to table 
    refreshList: function () {
      this.search = "";
      this.sortDesc = [true];
      let obj = {
        sortDesc: this.sortDesc,
        page: 1,
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortBy,
      };
      this.updatePagination(obj);
    },

    // get collaboration list
    getLatestData: function () {
      this.getCollaborationListfn()
    },
    //when click on tags column this function call
    assignValueToSearch: function (RowData) {
      this.search = RowData;
      this.page = 1;
      this.getCollaborationListfn();
    },
    //common calling get collaboration list
    getCollaborationListfn: function () {
      this.notificationCount = 0;
      this.notificationUpdateCount = 0;
      this.notificationticketArray = [];
      this.changeTabTitle();
      let self = this;
      if (this.selectedBranch == "" || this.selectedDepartment == "" || (this.selectedDepartment && this.selectedDepartment.length == 0)) {
        self.checkSelectedBranchDept(function (resBranchDept) {
          if (resBranchDept) {
            self.getCollaborationListfn()
          }
        })
      } else if (this.departments.length == 0) {
        self.getAllAvailableDepartments(function (resDept) {
          if (resDept) {
            self.getCollaborationListfn()
          }
        });
      } else {
        this.entryStatusConfig = "";
        if (this.selectedCollaborationStatus?.length > 0) {
          this.selectedCollaborationStatus.forEach((x) => {
            this.entryStatusConfig = this.entryStatusConfig + "&EntryStatus=" + x;
          });
        }

        this.arrDepartmentList = "";
        if (this.selectedDepartment?.length > 0) {
          this.selectedDepartment.forEach((x) => {
            let department = this.departments.find(
              (dept) => dept.deptNo == x
            );
            if (department) {
              this.arrDepartmentList = this.arrDepartmentList + "&arrDepartment=" + department.value;
            }
          });
        }
        this.entryBranchConfig = "";
        if (this.selectedCollaborationBranch?.length > 0) {
          this.selectedCollaborationBranch.forEach((x) => {
            this.entryBranchConfig = this.entryBranchConfig + "&arrCollaborationEntryBranch=" + x;
          });
        } else if (this.workwithBranch?.length > 0) {
          this.workwithBranch.forEach((x) => {
            this.entryBranchConfig = this.entryBranchConfig + "&arrCollaborationEntryBranch=" + x.value;
          });
        }

        let filetype = ''

        if (this.previousSearch != encodeURIComponent(this.search) || this.previousStatus != this.selectedCollaborationStatus ||
          this.previousSelectedBranch != this.selectedCollaborationBranch || this.previousFileType != this.selectedCollaborationFileType ||
          this.previousDate != this.pickerDates) {
          this.page = 1;
        }
        this.previousSearch = encodeURIComponent(this.search);
        this.previousStatus = this.selectedCollaborationStatus;
        this.previousSelectedBranch = this.selectedCollaborationBranch;
        this.previousFileType = this.selectedCollaborationFileType;
        this.previousDate = this.pickerDates;
        if (this.selectedCollaborationFileType) {
          filetype = this.selectedCollaborationFileType;
        }
        if (this.pluginObjData) {
          this.search = this.pluginObjData.FileId;
          filetype = this.pluginObjData.FileType
        }
        let config =
          "?languageCode=" +
          this.checkSelectedLangCode() +
          "&pageNo=" +
          this.page +
          "&pageSize=" +
          this.itemsPerPage +
          "&fileType=" +
          filetype +
          "&orderByColumnName=" +
          this.sortBy +
          "&branch=" +
          this.selectedBranch?.value +
          this.arrDepartmentList +
          "&sortColumnDir=" +
          (this.sortDesc && this.sortDesc[0] == true ? "desc" : "asc") +
          "&searchText=" +
          encodeURIComponent(this.search) +
          this.entryStatusConfig +
          "&startDate=" +
          this.startingDate +
          "&endDate=" +
          this.endingDate +
          this.entryBranchConfig;
        this.getCollaborationList(config);
      }
    },
    // open add entry component
    OpenAddEntry() {
      this.addEntry = true;
      this.detailForm = false;
    },
    // check selected branch and department
    checkSelectedBranchDept: function (callback) {
      getBranchAndDepartment((res) => {
        if (res && res.status == 200) {
          this.selectedCollaborationBranch = [];
          this.isNotificationEnable = res.data.isNotificationEnable;
          this.isBrowserNotificationEnable = res.data.isBrowserNotificationEnable;
          this.isEmailNotificationEnable = res.data.isEmailNotificationEnable
          this.selectedCollaborationStatus = res.data.arrEntryStatus;
          this.selectedDepartment = res.data.arrDepartment;
          if (res.data.arrSelectedColumn && res.data.arrSelectedColumn.length > 0) {
            this.columns = res.data.arrSelectedColumn;
            this.selectedTableColumns = []
            this.selectedTableColumns = this.columns.filter(col => col.isDisplay);
            this.sortBy = this.columns.filter(col => col.isActive).map(col => col.value);
          }
          if (this.selectedDepartment == null || this.selectedDepartment?.length == 0) {
            this.selectedDepartment = [];
            if (this.departments[0]?.deptNo) {
              this.selectedDepartment.push(this.departments[0].deptNo)
            }
            let obj = {
              isNotificationEnable: this.isNotificationEnable,
              isEmailNotificationEnable: this.isEmailNotificationEnable,
              isBrowserNotificationEnable: this.isBrowserNotificationEnable,
              arrEntryStatus: this.selectedCollaborationStatus,
              arrCollaborationEntryBranch: res.data.arrCollaborationEntryBranch,
              branchNo: res.data.branchNo,
              arrDepartment: this.selectedDepartment,
              languageCode: res.data.languageCode,
              translationLanguage: res.data.translationLanguage,
              arrSelectedColumn: this.columns
            };
            setBranchAndDepartment(obj)
          }
          this.selectedDepartmentList = ""
          this.selectedDepartment.forEach((x) => {
            let department = this.departments.find(
              (dept) => dept.deptNo == x
            );
            if (department) {
              this.selectedDepartmentList = this.selectedDepartmentList == '' ? department.text : this.selectedDepartmentList + ', ' + department.text
            }
          });

          // this.selectedCollaborationBranch=res.data.arrCollaborationEntryBranch;
          if (res.data.arrCollaborationEntryBranch) {
            this.workwithBranch = this.allCollaborationBranch.filter((branch) => {
              return res.data.arrCollaborationEntryBranch.includes(branch.value)
            });
            this.branchesCopy = [...this.workwithBranch]
          }
          this.userSettingLang = (res.data.languageCode == null || res.data.languageCode == '' || res.data.languageCode == undefined) ? 'en-UK' : res.data.languageCode
          this.translationLanguageCode = (res.data.translationLanguage == null || res.data.translationLanguage == '' || res.data.translationLanguage == undefined) ? '' : res.data.translationLanguage

          if (this.createdBranches.length == 0) {
            this.getAllAvailableCreatedBranches()
          }
          let branch = this.createdBranches.find(
            (branch) => branch.entityNo == res.data.branchNo
          );
          if (branch) {
            // this.selectedBranch = branch.value;
            this.selectedBranch = branch;
            this.mainBranch = branch.entityNo
          } else if (this.createdBranches && this.createdBranches.length > 0) {
            this.selectedBranch = this.createdBranches[0];
            this.mainBranch = this.createdBranches[0].entityNo
            this.setBranchandDepartment();
          }
          if (callback) {
            let obj = {
              branch: this.selectedBranch.value,
              department: this.selectedDepartment
            }
            return callback(obj);
          }
        } else {
          if (callback) {
            return callback();
          }
        }
      }).catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
    },
    //clear advance filter
    clearFilter: function () {
      this.pickerDates = [];
      this.startingDate = '';
      this.endingDate = '';
      this.entryStatusConfig = null;
      this.entryBranchConfig = null;
      this.selectedCollaborationStatus = null;
      this.selectedCollaborationBranch = null;
      this.selectedCollaborationFileType = null;
      this.createdFileTypeValue = '';
      this.collaborationEntryBranch = '';
      this.innerValue = [new Date(NaN), new Date(NaN)];
      this.getCollaborationListfn()
    },

    // search branch from dropdown 
    searchAllBranches() {
      if (!this.searchAllBranch) {
        this.workwithBranch = this.branchesCopy;
      }
      this.workwithBranch = this.branchesCopy.filter((branch) => {
        return branch?.text?.toLowerCase().indexOf(this.searchAllBranch?.toLowerCase()) > -1;
      });
    },
    // select all branch from dropdown
    onChangeAllBranch() {
      this.$nextTick(() => {
        if (this.selectAllBranches) {
          this.selectedCollaborationBranch = []
        } else {
          // this.selectBranch = this.branchesCopy.slice()
          this.selectedCollaborationBranch = []
          for (let i = 0; i < this.branchesCopy.length; i++) {
            this.selectedCollaborationBranch.push(this.branchesCopy[i].value)
          }
        }
        this.getCollaborationListfn();
        this.removeSearch();
      });
    },
    // remove search on clear button
    removeSearch() {
      this.searchAllBranch = "";
      this.workwithBranch = this.branchesCopy.filter((branch) => {
        return branch?.text?.toLowerCase().indexOf(this.searchAllBranch?.toLowerCase()) > -1;
      });
    },
    // set branch and department
    setBranchandDepartment() {
      this.userSettingLang = sessionStorage.getItem("localeLang");
      let obj = {
        isNotificationEnable: this.isNotificationEnable,
        isEmailNotificationEnable: this.isEmailNotificationEnable,
        isBrowserNotificationEnable: this.isBrowserNotificationEnable,
        arrEntryStatus: this.selectedCollaborationStatus,
        arrCollaborationEntryBranch: this.workwithBranch.map((branch) => branch.value),
        branchNo: this.mainBranch,
        arrDepartment: this.selectedDepartment,
        languageCode: this.userSettingLang,
        translationLanguage: this.translationLanguageCode,
        arrSelectedColumn: this.columns,
      };
      loaderService.setLoader(true);
      setBranchAndDepartment(obj, function () {
        loaderService.setLoader(false);
      })
    },
    // get index on drag and drop table column
    getIndex(value) {
      let index = this.columns.findIndex((col) => col.value == value);
      if (index > 0) {
        let field = this.selectedTableColumns.find((col) => col.value == this.columns[index - 1].value);
        if (field != undefined && field != null) {
          let fieldIndex = this.selectedTableColumns.findIndex((col) => col.value == field.value);
          return (fieldIndex + 1);
        } else {
          return this.getIndex(this.columns[index - 1].value);
        }
      } else {
        console.log('error 1811');
      }
    },
    // set value to filter after change table column using drag and drop functionality
    onchangeTableHeader(index) {
      this.columns[index].isDisplay = !this.columns[index].isDisplay;
      if (this.columns[index].value == this.selectedTableColumns[this.selectedTableColumns.length - 1].value) {
        let indexIn = this.getIndex(this.selectedTableColumns[this.selectedTableColumns.length - 1].value);
        this.selectedTableColumns.splice(this.selectedTableColumns.length - 1, 1);
        this.selectedTableColumns.splice(indexIn, 0, this.columns[index]);
        this.currentTableHeaders = [...this.selectedTableColumns];
      } else {
        this.currentTableHeaders = [...this.selectedTableColumns];
      }
      this.setBranchandDepartment();
    },
    // change tab title if have any updates in ticket or new ticket
    changeTabTitle() {
      this.totalNotificationCount = this.notificationCount + this.notificationUpdateCount
      if (this.totalNotificationCount > 0) {
        document.title = "(" + this.totalNotificationCount + ")" + " " + " SAE Collaboration App"
      }
      else {
        document.title = "SAE Collaboration App";
      }
    },
    // set header acording to selected columns
    setHeader(event) {
      const index = this.columns.findIndex((col) => col.value == event.moved.element.value);
      const col = this.columns.splice(index, 1);

      if (this.columns[event.moved.newIndex - 1] === this.currentTableHeaders[event.moved.newIndex - 1]) {
        this.columns.splice(event.moved.newIndex, 0, col[0]);
      } else {
        const colIndex =  this.columns.findIndex((header)=>{
          return header.value == this.currentTableHeaders[event.moved.newIndex-1].value
        })
        this.columns.splice(colIndex+1, 0, col[0]);
      }

      this.selectedTableColumns = [...this.currentTableHeaders];
      this.setBranchandDepartment();
    },
    // sort table data on selected columns
    sortTableData(event) {
      this.sortBy = []
      this.sortBy = [event]
      this.sortDesc = !this.sortDesc[0];
      this.isFromSorting = true;
      this.columns.forEach((header) => {
        if (header.value === this.sortBy[0]) {
          header.isActive = true;
        } else {
          header.isActive = false;
        }
      });
      this.selectedTableColumns = [...this.currentTableHeaders];
      this.setBranchandDepartment();
    },
    // copy consignment number on click on copy button
    copyConsignmentNumber(ConsignmentNumber,event){
      event.stopPropagation();
      navigator.clipboard.writeText(ConsignmentNumber);
    },
    // connect signalR for notification
    signalRConnection() {
      // signalR copnnectin URL
      let notificationUrl = JSON.parse(localStorage.getItem("AppConfig")).UrlNotification
      // connect with the signalR hub
      const connection = new HubConnectionBuilder()
        .withUrl(notificationUrl + '/notify', {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .build();
      connection
        .start()
        .then(() =>
          connection.on('SendCollaborationNotifications', (response) => {
            if (this.isNotificationEnable == true && this.isBrowserNotificationEnable == true && sessionStorage.getItem("emailAddress") == response.userId) {
              Notification.requestPermission((perm) => {
                if (perm == "granted") {
                  var notification = new Notification(response.title, {
                    icon: require('@/assets/images/icons/notification.png'),
                    body: response.message,
                    tag: sessionStorage.getItem("emailAddress"),
                  });
                  notification.onclick = function () {
                    window.open(response.message, '_blank', 'noreferrer');
                  };
                } else {
                  Notification.requestPermission();
                }
              });
            }
          }),
          connection.on('SendNotificationCounter', (response) => {
            if (sessionStorage.getItem("emailAddress") == response.userId && response.isTicketCreated) {
              if (document.getElementsByClassName("collaboration-card").length > 0) {
                this.displayOverlay = true;
              }
              this.notificationticketArray.push(response.ticketId)
              this.notificationCount += 1;
              this.changeTabTitle();
            } else if (sessionStorage.getItem("emailAddress") == response.userId && !response.isTicketCreated && !this.notificationticketArray.includes(response.ticketId)) {
              if (document.getElementsByClassName("collaboration-card").length > 0) {
                this.displayOverlay = true;
              }
              if (this.openedRecordId !== response.ticketId) {
                this.notificationUpdateCount += 1;
                this.notificationticketArray.push(response.ticketId)
                this.changeTabTitle();
              }
            }
            // time out for display over to focus on notification counter button
            setTimeout(() => {
              this.displayOverlay = false;
            }, 2000);
          })
        )
        .catch(err => {
          console.log("connecting hub failed err is : ", err)
          // try to reconnect the signalR hub
          this.scheduleReconnection();
        });
      connection.onclose(() => {
        // try to reconnect the signalR hub
        this.scheduleReconnection();
      });
    },
    // this function reconnects the signalR hub
    scheduleReconnection() {
      this.reconnectionTimer = setTimeout(() => {
        this.signalRConnection();
      }, 5000); // 5 seconds
    },
    // cancel reconnection after connection
    cancelReconnection() {
      clearTimeout(this.reconnectionTimer);
    }
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.modal-dialog-wrapper .v-dialog__container {
  display: unset;
}

.v-card__title.main-table-title {
  padding: 0;
  padding-bottom: 0px;
  font-size: 1.35rem;
}

.collaboration-card {
  margin-top: 13px;
}

.collaboration-dialog-btn {
  background-color: #ffa200 !important;
  color: #fff !important;
  letter-spacing: 0;
  font-weight: 700;
}

.small-fab-btn {
  background-color: #ffa200 !important;
  box-shadow: 0px 3px 5px -1px rgb(255 244 225),
    0px 6px 10px 0px rgb(255 244 225), 0px 1px 18px 0px rgb(255 244 225) !important;
}

.collaboration-table-wrapper {
  padding-top: 15px;
}

.close-btn {
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  color: #fff !important;
}

.save-btn {
  background-color: #ffa200 !important;
  border-color: #ffa200 !important;
  color: #fff !important;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.p-14 {
  padding: 14px;
}

.m-0 {
  margin: 0px;
}

.m-right {
  margin-right: 10px;
}

.detail-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  margin-left: 20px;
}

.detail-header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.p-20 {
  padding: 20px;
}

.chip_buttons {
  display: flex;
  justify-content: center;
}

.chip_buttons span {
  margin-right: 10px;
}

.header {
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  color: #000000;
  padding-bottom: 20px;
}

.area {
  height: calc(100% - 140px);
  overflow-y: auto;
  width: 100%;
  padding: 10px;
}

.write_message {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  min-height: 100px;
  padding: 10px;
  color: #999;
  background: #fff;
}

.reply {
  height: 100%;
  border: 1px solid #dddddd;
  border-radius: 10px;
  background: #fff4e1;
}

.chat_pop {
  position: relative;
  background-color: white;
  border-radius: 10px 10px 10px 10px !important;
  padding: 30px 10px;
  min-width: 450px;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 2px 6px 4px rgb(0 0 0 / 14%),
    0px 1px 6px 0px rgb(0 0 0 / 12%);
  margin-top: 16px;
}

// ::-webkit-scrollbar-thumb {
//   background: rgb(0, 0, 0);
// }
// ::-webkit-scrollbar {
//   width: 5px;
//   height: 8px;
//   background-color: #aaa;
// }
.collaboration-card .c-width {
  width: 100%;
}

.collaboration-entry-detail {
  display: flex;
}

.thread-wrapper {
  height: calc(100vh - 449px);
  overflow-y: auto;
}

.collaboration-width {
  // width: calc(100vw - 415px);
  // padding: 0 15px;
  // background: #fff;
}

.collaboration-card .c-width {
  width: 100%;
}

.collaboration-entry-detail {
  display: flex;
}

.thread-wrapper {
  height: calc(100vh - 442px);
  overflow-y: auto;
}

.mt-10 {
  margin-top: 10px !important;
}

.navigate-button {
  color: #fff;
  // cursor: pointer;
  background-color: #f39a00;
  border-color: #f39a00;
  margin-right: 1rem !important;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.navigate-button[disabled] {
  opacity: 0.6;
}

.pagination_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter-icon {
  margin-right: 15px;
  margin-left: 15px;
}


.c-v-card {
  margin-top: 7px;
}

.columnclass {
  flex: 0 0 75%;
  max-width: 75%;
}

.c-transition {
  transition: all .2s ease;
}

.c-remove-transition {

  transition: all .5s ease;
}

@media screen and (max-width: 991px) {
  .setclose-btn {
    height: 36px !important;
    min-width: 30px !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    ;
  }

  .custom-field {
    margin-bottom: 10px;
  }
}

@media screen and (max-width:1199px) {
  .filter-icon {
    margin-right: 7px;
    margin-left: 7px;
  }

  .custom-field {
    max-width: 150px;
  }

  .main-table-title {
    padding-bottom: 0px !important;
    font-size: 18px !important;
  }

  .c-btn-space {
    padding: 0 8px !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    min-width: 36px !important;
  }
}

@media screen and (max-width:991px) {
  .custom-field {
    max-width: 125px;
  }

  .main-table-title {
    padding-bottom: 0px !important;
    font-size: 16px !important;
  }

  .collaboration-dialog-btn {
    font-size: 12px !important;
    padding: 0 8px !important;
  }
}

.select-file-type {
  width: 100%;
  border-bottom: 1px solid #949494;
}

.select-file-type button {
  height: 45px !important;
  width: 100% !important;
}

.branch-text {
  font-size: 18px;
  padding-top: 0px !important;
  text-transform: capitalize;
  font-weight: 500;
  color: #8b8b8b;
  margin-bottom: 0;
}

.dropdown-h .theme--light.v-select .v-select__selections {
  color: rgba(0, 0, 0, 0.87);
  min-height: unset;
  max-height: 33px !important;
  overflow-y: auto;
  margin-top: 10px;
}

.w-50 {
  width: 50%;
}

.v-application .indigo-1--text.text--darken-5.select_all {
  color: #1976d2;
  caret-color: #1976d2;
}

.v-list-item.v-list-item--link.theme--light.select_bg {
  background: #e4effa;
}

.theme--light.v-list-item .v-list-item__content.select_all .v-list-item__title {
  color: rgb(25 118 210);
}

.v-application {
  .v-menu__content {
    margin-top: 35px;
    max-height: 50%;
    margin-right: 30px !important;
    width: 13%;
  }

  .v-menu__content.menuable__content__active {
    width: 13%;
    margin-top: 35px;
    max-height: 50%;
    margin-right: 30px !important;
    cursor: pointer;
  }
}

.active-filter-btn {
  color: rgba(0, 0, 0, 0.87) !important;
}

.overlay-card {
  height: 100%;
  width: 100%;
  background-color: #0000007a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.5s;
}

.overlay-btn {
  position: relative;
  z-index: 2;
  transition: 0.5 s;
}

.arrow-visible {
  visibility: hidden;
  font-size: 18px;
}

th:hover {
  align-items: center;
  background-color: #eeeeee;

  i {
    visibility: visible !important;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
<style>
.v-input--selection-controls .v-input__slot>.v-label {
  margin: 0 !important;
}
</style>
